import HttpService from './HttpService'

class TransactionApi extends HttpService {
  getTransactions(sortBy, sortOrder, limit, offset, filter = {}) {
    return this.get('/transactions' + this.params({
      'query[sort][by]': sortBy,
      'query[sort][order]': sortOrder,
      'query[limit]': limit,
      'query[offset]': offset,
      ...filter
    }))
  }
}

export default new TransactionApi()
