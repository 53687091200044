import * as types from 'redux/types/service'

const initialState = {
  selectedProcess: null
}

export default (state = initialState, action) => {
  switch(action.type) {
  case types.CHANGE_SELECTED_PROCESS:
    return {
      ...state,
      selectedProcess: action.payload.selectedProcess
    }
  default:
    return state
  }
}
