import types from 'redux/types/transaction'

const initialState = {
  transactions: { data: [], total: 0, pageSize: 20, loading: false },
  errorMessage: null,
  errorResponse: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TRANSACTIONS: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: true
        }
      }
    }

    case types.GET_TRANSACTIONS_DONE: {
      return {
        ...state,
        transactions: {
          data: action.payload.transactions.data,
          total: action.payload.transactions.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }
    }

    case types.TRANSACTIONS_ERROR: {
      return {
        ...state,
        errorMessage: action.payload.message,
        errorResponse: action.payload.response
      }
    }

    default:
      return state
  }
}
