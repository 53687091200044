import * as types from 'redux/types/user'
import UserApi from 'services/UserApi'
import TransactionApi from 'services/TransactionApi'

export const auth = () => ({
  types: [types.AUTH, types.AUTH_DONE, types.USER_ERROR],
  payload: {
    user: UserApi.auth()
  }
})

export const getUser = userId => ({
  types: [types.GET_USER, types.GET_USER_DONE, types.USER_ERROR],
  payload: {
    user: UserApi.getUser(userId)
  }
})

export const getServicesUniqueGrouped = userId => ({
  types: [
    types.GET_SERVICES_UNIQUE_GROUPED,
    types.GET_SERVICES_UNIQUE_GROUPED_DONE,
    types.USER_ERROR
  ],
  payload: {
    services: UserApi.getServicesUniqueGrouped(userId)
  }
})

export const getServicesProcess = userId => ({
  types: [
    types.GET_SERVICES_PROCESS,
    types.GET_SERVICES_PROCESS_DONE,
    types.USER_ERROR
  ],
  payload: {
    processes: UserApi.getServicesProcess(userId)
  }
})

export const getCities = zip => ({
  types: [types.GET_CITIES, types.GET_CITIES_DONE, types.USER_ERROR],
  payload: {
    cities: UserApi.getCities(zip)
  }
})

export const getProvince = zip => ({
  types: [types.GET_PROVINCE, types.GET_PROVINCE_DONE, types.USER_ERROR],
  payload: {
    province: UserApi.getProvince(zip)
  }
})

export const addCity = zip => ({
  types: [types.ADD_CITY, types.ADD_CITY_DONE, types.USER_ERROR],
  payload: {
    cities: UserApi.addCity(zip)
  }
})

export const login = credentials => ({
  types: [types.LOGIN, types.LOGIN_DONE, types.USER_ERROR],
  payload: {
    response: UserApi.login(credentials)
  }
})

export const register = userData => ({
  types: [types.REGISTER, types.REGISTER_DONE, types.USER_ERROR],
  payload: {
    user: UserApi.register(userData)
  }
})

export const registerSimple = userData => ({
  types: [types.REGISTER_SIMPLE, types.REGISTER_SIMPLE_DONE, types.USER_ERROR],
  payload: {
    user: UserApi.registerSimple(userData)
  }
})

export const registerInsicPrecheck = userData => ({
  types: [types.REGISTER_INSIC_PRECHECK, types.REGISTER_INSIC_PRECHECK_DONE, types.USER_ERROR],
  payload: {
    response: UserApi.registerInsicPrecheck(userData)
  }
})

export const registerIncremental = userData => ({
  types: [types.REGISTER_INCREMENTAL, types.REGISTER_INCREMENTAL_DONE, types.USER_ERROR],
  payload: {
    user: UserApi.registerIncremental(userData)
  }
})

export const registerCompany = companyData => ({
  types: [types.REGISTER_COMPANY, types.REGISTER_COMPANY_DONE, types.USER_ERROR],
  payload: {
    user: UserApi.registerCompany(companyData)
  }
})

export const resetPassword = ({ email }) => ({
  types: [types.RESET_PASSWORD, types.RESET_PASSWORD_DONE, types.USER_ERROR],
  payload: {
    response: UserApi.resetPassword(email)
  }
})

export const check = (userId, serviceId, data) => ({
  types: [types.CHECK, types.CHECK_DONE, types.USER_ERROR],
  payload: {
    response: UserApi.check(userId, serviceId, data),
    serviceId
  }
})

export const userUpdate = (userId, data, withoutAutostart) => ({
  types: [types.UPDATE_USER, types.UPDATE_USER_DONE, types.USER_ERROR],
  payload: {
    user: UserApi.userUpdate(userId, data, withoutAutostart)
  }
})

export const getMessages = (userId, service, limit) => ({
  types: [types.GET_MESSAGES, types.GET_MESSAGES_DONE, types.USER_ERROR],
  payload: {
    messages: UserApi.getMessages(userId, service, limit),
    service
  }
})

export const getUserMessages = (userId, sortBy, sortOrder, limit, offset) => ({
  types: [types.GET_USER_MESSAGES, types.GET_USER_MESSAGES_DONE, types.USER_ERROR],
  payload: {
    messages: UserApi.getUserMessages(userId, sortBy, sortOrder, limit, offset),
    pageSize: limit
  }
})


export const clearPassport = userId => ({
  types: [types.CLEAR_PASSPORT, types.CLEAR_PASSPORT_DONE, types.USER_ERROR],
  payload: {
    user: UserApi.clearPassport(userId)
  }
})

export const clearImage = userId => ({
  types: [types.CLEAR_IMAGE, types.CLEAR_IMAGE_DONE, types.USER_ERROR],
  payload: {
    user: UserApi.clearImage(userId)
  }
})

export const checkEmail = ({ email }) => ({
  types: [types.CHECK_EMAIL, types.CHECK_EMAIL_DONE, types.USER_ERROR],
  payload: {
    response: UserApi.checkEmail(email)
  }
})

export const getLoqate = (text, id) => ({
  types: [types.GET_LOQATE, types.GET_LOQATE_DONE, types.USER_ERROR],
  payload: {
    response: UserApi.getLoqate(text, id)
  }
})

export const resetLoqate = () => ({
  type: types.RESET_LOQATE
})

export const oauth2 = ({ _provider, _token: { accessToken } }) => ({
  types: [types.OAUTH2, types.OAUTH2_DONE, types.USER_ERROR],
  payload: {
    response: UserApi.oauth2(_provider, { accessToken })
  }
})

export const skip = (userId, serviceId) => ({
  types: [types.SKIP, types.SKIP_DONE, types.USER_ERROR],
  payload: {
    response: UserApi.skip(userId, serviceId),
    serviceId
  }
})

export const confirm = (userId, serviceId, code) => ({
  types: [types.CONFIRM, types.CONFIRM_DONE, types.USER_ERROR],
  payload: {
    response: UserApi.confirm(userId, serviceId, code),
    serviceId
  }
})

export const logout = () => ({
  type: types.LOGOUT
})

export const clearOauth2Data = () => ({
  type: types.CLEAR_OAUTH2_DATA
})

export const getUsers = (sortBy, sortOrder, limit, offset, filter) => ({
  types: [types.GET_USERS, types.GET_USERS_DONE, types.USER_ERROR],
  payload: {
    users: UserApi.getUsers(sortBy, sortOrder, limit, offset, filter),
    pageSize: limit
  }
})

export const getUserServices = userId => ({
  types: [types.GET_USER_SERVICES, types.GET_USER_SERVICES_DONE, types.USER_ERROR],
  payload: {
    services: UserApi.getUserServices(userId),
    userId
  }
})

export const setUserStatus = (userId, status) => ({
  types: [types.SET_USER_STATUS, types.SET_USER_STATUS_DONE, types.USER_ERROR],
  payload: {
    response: UserApi.setUserStatus(userId, status),
    userId
  }
})

export const blockUser = userId => ({
  types: [types.BLOCK_USER, types.BLOCK_USER_DONE, types.USER_ERROR],
  payload: {
    userData: UserApi.blockUser(userId),
    userId
  }
})

export const unblockUser = userId => ({
  types: [types.UNBLOCK_USER, types.UNBLOCK_USER_DONE, types.USER_ERROR],
  payload: {
    userData: UserApi.unblockUser(userId),
    userId
  }
})

export const deleteAccount = userId => ({
  types: [types.DELETE_ACCOUNT, types.DELETE_ACCOUNT_DONE, types.USER_ERROR],
  payload: {
    userData: UserApi.deleteAccount(userId),
    userId
  }
})

export const deleteProfile = userId => ({
  types: [types.DELETE_PROFILE, types.DELETE_PROFILE_DONE, types.USER_ERROR],
  payload: {
    userData: UserApi.deleteProfile(userId),
    userId
  }
})

export const fullyDelete = userId => ({
  types: [types.DELETE_USER, types.DELETE_USER_DONE, types.USER_ERROR],
  payload: {
    userData: UserApi.fullyDelete(userId),
    userId
  }
})

export const getEditableUser = userId => ({
  types: [types.GET_EDITABLE_USER, types.GET_EDITABLE_USER_DONE, types.USER_ERROR],
  payload: {
    user: UserApi.getEditableUser(userId)
  }
})

export const setEditableSection = sectionName => ({
  type: types.SET_EDITABLE_SECTION,
  payload: { sectionName }
})

export const getUserTransactions = (userId, sortBy, sortOrder, limit, offset) => ({
  types: [
    types.GET_USER_TRANSACTIONS,
    types.GET_USER_TRANSACTIONS_DONE,
    types.USER_ERROR
  ],
  payload: {
    transactions: TransactionApi.getTransactions(
      sortBy, sortOrder, limit, offset, { 'query[filter][userId]': userId }
    ),
    pageSize: limit
  }
})

export const updateEditedUser = user => ({
  type: types.UPDATE_EDITED_USER,
  payload: { user }
})

export const clearEditableUser = () => ({
  type: types.CLEAR_EDITABLE_USER
})

export const setUserFilter = filter => ({
  type: types.SET_USER_FILTER,
  payload: { filter }
})

export const setServiceStatus = (userId, serviceName, status) => ({
  types: [types.SET_SERVICE_STATUS, types.SET_SERVICE_STATUS_DONE, types.USER_ERROR],
  payload: {
    response: UserApi.setServiceStatus(userId, serviceName, status),
    userId,
    serviceName,
    status
  }
})

export const resetAttemptsCount = (userId, serviceName) => ({
  type: types.RESET_ATTEMPTS_COUNT,
  payload: {
    response: UserApi.resetAttemptsCount(userId, serviceName)
  }
})

export const createAnonymous = partnerId => ({
  types: [types.CREATE_ANONYMOUS, types.CREATE_ANONYMOUS_DONE, types.USER_ERROR],
  payload: {
    user: UserApi.createAnonymous(partnerId)
  }
})

export const getUserDoubles = (partnerId, sortBy, sortOrder, limit, offset) => ({
  types: [types.GET_USER_DOUBLES, types.GET_USER_DOUBLES_DONE, types.USER_ERROR],
  payload: {
    doubles: UserApi.getUserDoubles(partnerId, sortBy, sortOrder, limit, offset),
    pageSize: limit
  }
})

export const toggleDoubleRowSelected = (groupId, userId) => ({
  type: types.TOGGLE_DOUBLE_ROW_SELECTED,
  payload: {
    groupId,
    userId
  }
})

export const setDoubleRowMaster = (groupId, userId) => ({
  type: types.SET_DOUBLE_ROW_MASTER,
  payload: {
    groupId,
    userId
  }
})

export const setDoublesMergeError = (groupId, error) => ({
  type: types.SET_DOUBLES_MERGE_ERROR,
  payload: {
    groupId,
    error
  }
})

export const clearUserError = () => ({
  type: types.CLEAR_USER_ERROR
})

export const addInvalidEmail = email => ({
  type: types.ADD_INVALID_EMAIL,
  payload: {
    email
  }
})

export const clearInvalidEmails = () => ({
  type: types.CLEAR_INVALID_EMAILS
})

export const getServices = () => ({
  types: [types.GET_SERVICES, types.GET_SERVICES_DONE, types.USER_ERROR],
  payload: {
    services: UserApi.getServices()
  }
})

export const getUserTotalIncome = (data) => ({
  types: [types.GET_USER_TOTAL, types.GET_USER_TOTAL_DONE, types.USER_ERROR],
  payload: {
    totalIncomes: UserApi.getUserTotalIncome(data)
  }
})

export const getDecryptedValue = (data) => ({
  types: [types.GET_DECRYPTED, types.GET_DECRYPTED_DONE, types.USER_ERROR],
  payload: {
    decrypted: UserApi.getDecryptedValue(data)
  }
})
