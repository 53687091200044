import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import PropTypes from 'prop-types'

import Input from 'components/Input'
import { required, numberOrSpace, expirationDate } from 'services/validation'
import t from 'services/t'

export const CreditCardForm = props => {
  const { isCreditCardFieldsVisible, setPayloadData, cardExpiration, cardNumber, serviceStatus } =
    props
  const { isCardNumberVisible, isCardExpirationVisible } = isCreditCardFieldsVisible

  useEffect(() => {
    if (serviceStatus === 1 || serviceStatus === 5) {
      setPayloadData({})
    } else if (cardNumber || cardExpiration) {
      setPayloadData({ cardNumber, cardExpiration })
    }
  }, [cardNumber, cardExpiration, serviceStatus])

  const normalizeCardNumber = value => {
    if (!value) return value

    const valueOnlyNumbers = value.replace(/[^\d]/g, '')

    const matches = valueOnlyNumbers.match(/\d{4,16}/g)
    const match = matches ? matches[0] : ''

    let groups = []
    for (let i = 0; i < match.length; i += 4) {
      groups.push(match.substring(i, i + 4))
    }

    if (groups.length) {
      return groups.join(' ')
    } else {
      return value
    }
  }

  const normalizeExpDate = (value, prevValue) => {
    if (!value) return value

    const valueOnlyNumbers = value.replace(/[^\d]/g, '')
    const prevValueOnlyNumbers = prevValue && prevValue.replace(/[^\d]/g, '')

    if (valueOnlyNumbers === prevValueOnlyNumbers) return value

    const month = valueOnlyNumbers.slice(0, 2)
    const year = valueOnlyNumbers.slice(2, 4)

    if (valueOnlyNumbers.length < 2) return `${month}`
    if (valueOnlyNumbers.length === 2) return `${month}/`
    if (valueOnlyNumbers.length < 4) return `${month}/${year}`
    if (valueOnlyNumbers.length === 4) return `${month}/${year}`
  }

  return (
    <div className='avs-user-form'>
      <form id='credit-card-form' noValidate>
        {isCardNumberVisible && (
          <div className='avs-form-group'>
            <Field
              name='cardNumber'
              type='text'
              label={t('label.userDashboard.input.cardNumber')}
              component={Input}
              validate={[required, numberOrSpace]}
              placeholder='xxxx xxxx xxxx xxxx'
              normalize={normalizeCardNumber}
            />
          </div>
        )}
        {isCardExpirationVisible && (
          <div className='avs-form-group'>
            <Field
              name='cardExpiration'
              type='text'
              label={t('label.userDashboard.input.cardExpiration')}
              component={Input}
              placeholder='MM/YY'
              validate={[required, expirationDate]}
              normalize={normalizeExpDate}
            />
          </div>
        )}
      </form>
    </div>
  )
}

CreditCardForm.propTypes = {
  cardNumber: PropTypes.string,
  cardExpiration: PropTypes.string,
  isCreditCardFieldsVisible: PropTypes.object,
  setPayloadData: PropTypes.func.isRequired,
  serviceStatus: PropTypes.number.isRequired
}

const selector = formValueSelector('credit-card-form')

export default connect(state => ({
  cardNumber: selector(state, 'cardNumber'),
  cardExpiration: selector(state, 'cardExpiration')
}))(
  reduxForm({
    form: 'credit-card-form'
  })(CreditCardForm)
)
