import 'react-app-polyfill/ie9'
import React from 'react'
import { render, unmountComponentAtNode } from 'react-dom'
import { Provider } from 'react-redux'

import App from 'App'
import store from 'redux/store'
import { getMap } from 'redux/actions/template'
import { loadCss, loadPartnerStyle, loadStepperStyle } from 'loadLibs'
import history from 'services/history'
import auth from 'services/auth'
import * as userActions from 'redux/actions/user'

window.AVS_LOGOUT = (shouldPageReloaded = true) => {
  auth.logout()
  if (shouldPageReloaded) window.location.reload()
}

window.AVS_UNMOUNT = () => {
  unmountComponentAtNode(document.getElementById('___avs-wrapper'))
  const style = document.getElementById('avs-style')
  if (style) style.remove()
}

window.AVS_MOUNT = () => {
  if (!global._babelPolyfill) {
    require('babel-polyfill')
  }

  loadCss()

  const { __AVS_CONFIG__: { startRoute } } = window

  if (auth.token) {
    store.dispatch(userActions.auth())
  } else if (!['/login', '/registration'].includes(history.location.pathname)) {
    history.push(startRoute === '/registration' ? '/registration' : '/login')
  }

  store.dispatch(getMap()).then(action => {
    if (!action.error) {
      loadPartnerStyle(action.payload.map['style.css'])
      loadStepperStyle(action.payload.map['label.user.dashboard.mainStepper.css'])
      render(
        <Provider store={store}>
          <App />
        </Provider>,
        document.getElementById('___avs-wrapper')
      )
    } else {
      console.warn('Cannot load AVS widget')
    }
  })
}

window.AVS_MOUNT()
