import DOMPurify from 'dompurify'

/**
 * Transform object with css class names to string
 *
 * Example: `{ 'some-class': true, 'another-class': false }`
 * @param {Object} o
 */
export const classNames = o =>
  Object.keys(o)
    .filter(k => o[k])
    .join(' ')

/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * N milliseconds. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * @param {Function} fn
 * @param {Number} wait
 * @param {Boolean} immediate
 */
export const debounce = (fn, wait, immediate) => {
  let timeout
  return (...args) => {
    const later = () => {
      timeout = null
      if (!immediate) fn.apply(this, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) fn.apply(this, args)
  }
}

/**
 * Create an new deferred promise that can be resolved/rejected from outside
 * @returns {Object}
 */
export const defer = () => {
  let resolve, reject
  const promise = new Promise((res, rej) => {
    resolve = res
    reject = rej
  })
  return {
    resolve,
    reject,
    promise
  }
}

/**
 * Get deep path of object
 *
 * Example: `get(obj, 'foo.bar')`
 * @param {Object} obj
 * @param {string} path
 * @param {any} defaultValue
 */
export const get = (obj = {}, path = '', defaultValue = undefined) => {
  const paths = path.split('.')
  let current = obj

  if (obj === undefined || obj === null) {
    return defaultValue
  }

  for (let i = 0; i < paths.length; i++) {
    if (current[paths[i]] === undefined || current[paths[i]] === null) {
      return defaultValue
    } else {
      current = current[paths[i]]
    }
  }

  return current
}

/**
 * Set deep path value
 *
 * Example: `set({}, 'profile.zip', 12345) // { profile: { zip: 12345 } }`
 * @param {Object} obj
 * @param {string} path
 * @param {any} value
 */
export const set = (obj, path, value) => {
  const paths = path.split('.')
  let current = obj

  for (let i = 0; i < paths.length; i++) {
    current[paths[i]] = current[paths[i]] || {}
    if (i === paths.length - 1) {
      current[paths[i]] = value
    }
    current = current[paths[i]]
  }

  return obj
}

/**
 * Parse token
 * @param {string} token
 */
export const parseJwt = token => {
  try {
    if (!token) return null
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(base64))
  } catch (e) {
    // todo: redirect to /login and clear token
    console.error(e)
  }
}

/**
 * Format date to string
 * @param {Date} date
 */
export const formatDate = date => {
  const dd = ('0' + date.getDate()).slice(-2)
  const mm = ('0' + (date.getMonth() + 1)).slice(-2)
  const yyyy = date.getFullYear()

  return `${dd}.${mm}.${yyyy}`
}

export const parseDate = dateString => {
  const validString = /^([0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4})$/
  if (!validString.test(dateString)) return new Date(NaN)
  const [dd, mm, yyyy] = dateString.split('.')
  return new Date(`${yyyy}-${mm}-${dd}`)
}

/**
 * Prepare user data before send to api
 * @param {Object} userData
 */
export const prepareUserData = userData => {
  const { profile = {} } = userData
  const { passportFront, passportBack, image } = profile
  const [dd, mm, yyyy] = (profile.birthday || '').split('.')
  return {
    ...userData,
    email2: undefined,
    role: undefined,
    status: undefined,
    isBlocked: undefined,
    createdAt: undefined,
    updatedAt: undefined,
    credentialsChangedAt: undefined,
    confirmPassword: undefined,
    profile: {
      ...profile,
      id: undefined,
      userId: undefined,
      birthday: profile.birthday && `${yyyy}-${mm}-${dd}`,
      address: undefined,
      province: undefined,
      passportFront: passportFront
        ? { type: passportFront.type, size: passportFront.size, keyName: 'passportFront' }
        : undefined,
      passportFrontId: undefined,
      passportBack: passportBack
        ? { type: passportBack.type, size: passportBack.size, keyName: 'passportBack' }
        : undefined,
      passportBackId: undefined,
      image: image ? { type: image.type, size: image.size, keyName: 'image' } : undefined,
      imageId: undefined
    }
  }
}

const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min

export const getRandomString = length => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  for (let i = 0; i < length; i += 1) {
    result += chars.charAt(getRandomInt(0, chars.length))
  }
  return result
}

export const formatDateString = value => {
  const [dd, mm, yyyy] = value.split('.')
  return `${yyyy}-${mm}-${dd}`
}

export const preprocessFormData = (values, columns) => {
  let preprocessedData = {}
  columns.forEach(column => {
    if (column.passedToServer !== false) {
      let value = values[column.name]
      if (column.type === 'number') {
        value = Number(value)
      } else if (column.type === 'boolean') {
        value = Boolean(value)
      } else if (column.type === 'date' && /\d{2}\.\d{2}\.\d{4}/.test(value)) {
        value = formatDateString(value)
      }
      if (column.required || value) {
        preprocessedData[column.name] = value
      }
    }
  })
  return preprocessedData
}

export const preprocessDates = (data, columns) => {
  const datetimeColumns = []
  columns.forEach(column => {
    if (column.type === 'datetime') {
      datetimeColumns.push(column.name)
    }
  })

  return data.map(row => {
    let result = {}
    for (let field in row) {
      if (datetimeColumns.includes(field)) {
        result[field] = formatUtcDate(row[field])
      } else {
        result[field] = row[field]
      }
    }
    return result
  })
}

export const formatUtcDate = (date, withTime = true) => {
  const d = new Date(date)
  const year = d.getFullYear()
  const month = ('0' + (d.getMonth() + 1)).slice(-2)
  const day = ('0' + d.getDate()).slice(-2)
  const hour = ('0' + d.getHours()).slice(-2)
  const min = ('0' + d.getMinutes()).slice(-2)
  const sec = ('0' + d.getSeconds()).slice(-2)

  return withTime ? `${year}-${month}-${day} ${hour}:${min}:${sec}` : `${year}-${month}-${day}`
}

export const substractDays = daysCount => {
  const result = new Date()
  result.setDate(result.getDate() - daysCount)
  return result
}

/**
 * save csv file
 * @param {string} filename
 * @param {string} csv
 */
export const saveCsv = (csv, filename = 'query.csv') => {
  if (!csv) return

  const link = document.createElement('a')
  link.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
  link.target = '_blank'
  link.download = filename

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * pwdContainMsgOff - unhiding the avs-password-contain-message block when password field onFocus
 */
export const pwdContainMsgOff = () => {
  document.querySelector('.avs-password-contain-message').style.display = 'block'
}
/**
 * pwdContainMsgOn - hiding the avs-password-contain-message block when password field onBlur
 */
export const pwdContainMsgOn = () => {
  document.querySelector('.avs-password-contain-message').style.display = 'none'
}

/**
 * checkPwdContain - changing message styles based on newValue
 * @param {*} newValue - entered password value
 */
export const checkPwdContain = (event, newValue, previousValue, name) => {
  const containMsg = document.getElementById('avs-password-message')
  const letter = document.getElementById('avs-password-letter')
  const capital = document.getElementById('avs-password-capital')
  const number = document.getElementById('avs-password-number')
  const special = document.getElementById('avs-password-special')
  const length = document.getElementById('avs-password-length')
  const upperCaseLetters = /[A-Z]/g
  const lowerCaseLetters = /[a-z]/g
  const numbers = /[0-9]/g
  const specials = /[!@#$%^&*_=+<>§(\-)?]/
  if (newValue && newValue.match(lowerCaseLetters)) {
    letter.classList.remove('avs-pwd-invalid')
    letter.classList.add('avs-pwd-valid')
  } else {
    letter.classList.remove('avs-pwd-valid')
    letter.classList.add('avs-pwd-invalid')
  }
  if (newValue && newValue.match(upperCaseLetters)) {
    capital.classList.remove('avs-pwd-invalid')
    capital.classList.add('avs-pwd-valid')
  } else {
    capital.classList.remove('avs-pwd-valid')
    capital.classList.add('avs-pwd-invalid')
  }
  if (newValue && newValue.match(numbers)) {
    number.classList.remove('avs-pwd-invalid')
    number.classList.add('avs-pwd-valid')
  } else {
    number.classList.remove('avs-pwd-valid')
    number.classList.add('avs-pwd-invalid')
  }
  if (newValue && newValue.match(specials)) {
    special.classList.remove('avs-pwd-invalid')
    special.classList.add('avs-pwd-valid')
  } else {
    special.classList.remove('avs-pwd-valid')
    special.classList.add('avs-pwd-invalid')
  }
  if (newValue && newValue.length >= 8) {
    length.classList.remove('avs-pwd-invalid')
    length.classList.add('avs-pwd-valid')
  } else {
    length.classList.remove('avs-pwd-valid')
    length.classList.add('avs-pwd-invalid')
  }
  if (!document.querySelector('.avs-pwd-invalid')) {
    containMsg.classList.remove('avs-pwd-message-block')
    containMsg.classList.add('avs-pwd-message-none')
  } else {
    containMsg.classList.remove('avs-pwd-message-none')
    containMsg.classList.add('avs-pwd-message-block')
  }
}

export const tryParseJSON = jsonString => {
  try {
    const parsedValue = JSON.parse(jsonString)
    if (parsedValue || typeof parsedValue === 'object' || typeof parsedValue === 'boolean') {
      return parsedValue
    }
  } catch (error) {
    return false
  }
}

export const isUserVerified = user => {
  return user.status === 2
}

export const getDeviceType = () => {
  const { userAgent } = navigator

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return 'tablet'
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

/**
 * XSS sanitizer for HTML and SVG
 * @param {string} html
 * @returns string
 */
export const sanitize = html => {
  return DOMPurify.sanitize(html)
}
