import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import userState from './user'
import templateState from './template'
import partnerState from './partner'
import transactionState from './transaction'
import serviceState from './service'

export default combineReducers({
  form,
  userState,
  templateState,
  partnerState,
  transactionState,
  serviceState
})
