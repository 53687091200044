import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import QRCode from 'react-qr-code'

import * as partnerActions from 'redux/actions/partner'
import Select from 'components/Select'
import { required } from 'services/validation'
import t from 'services/t'
import { getDeviceType } from 'helpers'

export const DiiaServiceForm = props => {
  const {
    change,
    payloadData,
    setPayloadData,
    documentType,
    sharingLink,
    diiaIdLink,
    allowedDocuments,
    getAllowedDocumentsParam
  } = props
  const device = getDeviceType()
  const { partnerId } = window.__AVS_CONFIG__
  const [documentsOpts, setDocumentsOpts] = useState([])

  const getDocumentsOpts = documents => {
    let documentsOpts = []

    documents &&
      documents.forEach(doc => {
        documentsOpts.push({
          label: t(`label.engine.service.diia-service.document.${doc}`),
          value: doc
        })
      })
    return documentsOpts
  }

  useEffect(() => {
    getAllowedDocumentsParam(partnerId)
  }, [])

  useEffect(() => {
    if (documentType) {
      setPayloadData({ documentType })
    }
  }, [documentType])

  useEffect(() => {
    if (payloadData === null) {
      change('documentType', null)
    }
  }, [payloadData])

  useEffect(() => {
    const documents = getDocumentsOpts(allowedDocuments)
    setDocumentsOpts(documents)
  }, [allowedDocuments])

  return (
    <div className='avs-user-form'>
      <form id='diia-service-form' noValidate>
        {!sharingLink && !diiaIdLink && allowedDocuments && (
          <div className='avs-form-group'>
            <Field
              name='documentType'
              type='select'
              label={t('label.userDashboard.select.documentType')}
              component={Select}
              options={documentsOpts}
              validate={[required]}
            />
          </div>
        )}
        {diiaIdLink && (
          <div>
            <div className='avs-message-explanation-diia'>
              {t('label.userDashboard.service.diia-service.diiaId.explanation')}
            </div>
            {device === 'mobile' && (
              <a className='avs-link' href={diiaIdLink}>
                {t('label.engine.service.diia-service.link')}
              </a>
            )}
            {device === 'desktop' && <QRCode value={diiaIdLink} />}
          </div>
        )}
        {sharingLink && (
          <div>
            <div className='avs-message-explanation-diia'>
              {t('label.userDashboard.service.diia-service.sharing.explanation')}
            </div>
            {device === 'mobile' && (
              <a className='avs-link' href={sharingLink}>
                {t('label.engine.service.diia-service.link')}
              </a>
            )}
            {device === 'desktop' && <QRCode value={sharingLink} />}
          </div>
        )}
      </form>
    </div>
  )
}

DiiaServiceForm.propTypes = {
  change: PropTypes.func.isRequired,
  documentType: PropTypes.string,
  setPayloadData: PropTypes.func.isRequired,
  payloadData: PropTypes.object,
  sharingLink: PropTypes.string,
  diiaIdLink: PropTypes.string,
  allowedDocuments: PropTypes.array,
  getAllowedDocumentsParam: PropTypes.func.isRequired
}

const selector = formValueSelector('diia-service-form')

export default connect(
  state => ({
    documentType: selector(state, 'documentType'),
    allowedDocuments: state.partnerState.allowedDocuments
  }),
  dispatch => bindActionCreators(partnerActions, dispatch)
)(
  reduxForm({
    form: 'diia-service-form'
  })(DiiaServiceForm)
)
