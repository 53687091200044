import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

import t from 'services/t'
import { required, email } from 'services/validation'

import Input from 'components/Input'
import SocialButton from 'components/SocialButton'

const { facebookClientId, googleClientId, registrationType } = window.__AVS_CONFIG__

const InsicEmailForm = props => {
  const { handleSubmit, oauth2, invalid, submitting } = props
  const [loginError, setLoginError] = useState(null)
  const scope =
    registrationType === 'insic'
      ? [
          'https://www.googleapis.com/auth/userinfo.email',
          'https://www.googleapis.com/auth/userinfo.profile',
          'https://www.googleapis.com/auth/user.birthday.read'
        ]
      : ['profile', 'email']

  const onLoginFailure = error => {
    console.error(error)
    setLoginError(error)
  }

  return (
    <form noValidate onSubmit={handleSubmit}>
      {loginError && (
        <div className='avs-form-group'>
          <p className='avs-form-error-message'>{t('error.login.failed')}</p>
        </div>
      )}

      <div className='avs-form-group'>
        {facebookClientId && (
          <SocialButton
            provider='facebook'
            className='avs-btn avs-btn-primary avs-large avs-social-button'
            scope={['email', 'user_birthday', 'user_photos', 'user_hometown', 'user_location']}
            appId={facebookClientId}
            onLoginSuccess={oauth2}
            onLoginFailure={onLoginFailure}
          >
            {t('label.registrationForm.facebook.join')}
          </SocialButton>
        )}
      </div>

      <div className='avs-form-group'>
        {googleClientId && (
          <SocialButton
            provider='google'
            className='avs-btn avs-btn-primary avs-large avs-social-button'
            scope={scope}
            appId={googleClientId}
            onLoginSuccess={oauth2}
            onLoginFailure={onLoginFailure}
          >
            {t('label.registrationForm.google.join')}
          </SocialButton>
        )}
      </div>

      <Field
        type='email'
        name='email'
        label={t('label.user.attribute.email')}
        validate={[email, required]}
        component={Input}
      />

      <div className='avs-form-group'>
        <button className='avs-btn avs-btn-primary avs-large' disabled={invalid || submitting}>
          {t('label.registrationForm.loginOrRegister')}
        </button>
      </div>

      <div className='avs-container'>
        <div className='avs-form-group avs-text-center'>
          <p className='avs-text'>
            <Link className='avs-link' to='/forgot-password'>
              {t('label.loginForm.forgotPassword')}
            </Link>
          </p>
        </div>
      </div>
    </form>
  )
}

InsicEmailForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  oauth2: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool
}

export default reduxForm({
  form: 'registration',
  destroyOnUnmount: false
})(InsicEmailForm)
