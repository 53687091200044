import React from 'react'
import PropTypes from 'prop-types'

import t from 'services/t'

const PasswordContainMessage = () => {
  return (
    <div className='avs-password-contain-message'>
      <p id='avs-password-message' className='avs-pwd-message-block'>
        {t('label.registerForm.contain.message')}
      </p>
      <p id='avs-password-letter' className='avs-pwd-invalid'>
        {t('label.registerForm.password.contain.lower')}
      </p>
      <p id='avs-password-capital' className='avs-pwd-invalid'>
        {t('label.registerForm.password.contain.upper')}
      </p>
      <p id='avs-password-number' className='avs-pwd-invalid'>
        {t('label.registerForm.password.contain.number')}
      </p>
      <p id='avs-password-special' className='avs-pwd-invalid'>
        {t('label.registerForm.password.contain.special')}
      </p>
      <p id='avs-password-length' className='avs-pwd-invalid'>
        {t('label.registerForm.password.contain.length')}
      </p>
    </div>
  )
}

PasswordContainMessage.propTypes = {
  initialValues: PropTypes.object
}

export default PasswordContainMessage
