import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import InsicEmailForm from 'components/registration/insic/InsicEmailForm'
import GpmodLoginForm from 'components/registration/gpmod/GpmodLoginForm'
import GpmodRegisterForm from 'components/registration/gpmod/GpmodRegisterForm'

import history from 'services/history'
import { debounce } from 'helpers'
import * as userActions from 'redux/actions/user'

const GpmodForm = props => {
  const {
    onSubmit,
    onLogin,
    loqateItems,
    getLoqate,
    resetLoqate,
    cities,
    emailValue,
    oauth2,
    checkEmail,
    initialValues,
    oauth2UserData,
    errors,
    clearOauth2Data,
    addressValue,
    token,
    isEmailExists
  } = props
  const STEP_EMAIL = 'STEP_EMAIL'
  const STEP_LOGIN = 'STEP_LOGIN'
  const STEP_REGISTER = 'STEP_REGISTER'
  const [step, setStep] = useState(STEP_EMAIL)
  const onGetLoqate = useCallback(debounce(getLoqate, 500), [])

  useEffect(() => {
    if (isEmailExists === true) setStep(STEP_LOGIN)
    if (isEmailExists === false) setStep(STEP_REGISTER)
    if (oauth2UserData) setStep(STEP_REGISTER)
  }, [isEmailExists, oauth2UserData])

  useEffect(() => {
    if (addressValue) onGetLoqate(addressValue)
  }, [addressValue])

  useEffect(() => {
    if (token) history.push('/dashboard')
  }, [token])

  const goBack = () => setStep(STEP_EMAIL)

  const mergedInitialValues = {
    ...initialValues,
    ...oauth2UserData,
    email: oauth2UserData ? oauth2UserData.email : emailValue,
    profile: {
      ...(initialValues && initialValues.profile),
      ...(oauth2UserData && oauth2UserData.profile)
    }
  }

  return (
    <div className='avs-material avs-form-with-spinner'>
      <div className='avs-empty-header'></div>

      <div className='avs-form-group'>
        {errors.map((e, i) => (
          <p key={i} className='avs-form-error-message'>
            {e.message}
          </p>
        ))}
      </div>

      {step === STEP_EMAIL && <InsicEmailForm oauth2={oauth2} onSubmit={checkEmail} />}

      {step === STEP_LOGIN && <GpmodLoginForm onSubmit={onLogin} goBack={goBack} />}

      {step === STEP_REGISTER && (
        <GpmodRegisterForm
          initialValues={mergedInitialValues}
          loqateItems={loqateItems}
          cities={cities}
          getLoqate={getLoqate}
          resetLoqate={resetLoqate}
          clearOauth2Data={clearOauth2Data}
          goBack={goBack}
          onSubmit={onSubmit}
        />
      )}
    </div>
  )
}

GpmodForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  checkEmail: PropTypes.func.isRequired,
  getLoqate: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  resetLoqate: PropTypes.func.isRequired,
  oauth2: PropTypes.func.isRequired,
  clearOauth2Data: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  oauth2Error: PropTypes.string,
  loqateItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      description: PropTypes.string
    })
  ),
  oauth2UserData: PropTypes.object,
  isEmailExists: PropTypes.bool,
  addressValue: PropTypes.string,
  emailValue: PropTypes.string,
  token: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.object),
  cities: PropTypes.arrayOf(PropTypes.object)
}

const selector = formValueSelector('registration')

export default connect(
  state => ({
    addressValue: selector(state, 'profile.address'),
    emailValue: selector(state, 'email'),
    token: state.userState.token,
    loqateItems: state.userState.loqateItems,
    oauth2UserData: state.userState.oauth2UserData,
    isEmailExists: state.userState.isEmailExists
  }),
  dispatch => bindActionCreators(userActions, dispatch)
)(GpmodForm)
