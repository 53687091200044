import 'whatwg-fetch'

import HttpService from './HttpService'

/**
 * @typedef {object} Partner
 * @property {string} id
 * @property {string} link
 * @property {string} name
 * @property {?string} updatedAt 1969-12-31T00:00:00.000Z
 * @property {?string} createdAt 1969-12-31T00:00:00.000Z
 * @property {number} useGenericPool 0, 1
 */

/**
 * @typedef {object} Template
 * @property {?string} partnerId
 * @property {string} lang de, en
 * @property {string} key
 * @property {?string} updatedAt 1969-12-31T00:00:00.000Z
 * @property {?string} createdAt 1969-12-31T00:00:00.000Z
 * @property {string} value
 */

/**
 * @typedef {object} MessageType
 * @property {string} id
 * @property {?string} partnerId
 * @property {number} enabled 0, 1
 * @property {string} templateKey
 * @property {?string} updatedAt 1969-12-31T00:00:00.000Z
 * @property {?string} createdAt 1969-12-31T00:00:00.000Z
 * @property {number} isTechnical 0, 1
 * @property {number} type 1 - info, 2 - warning, 3 - error, 4 - alert
 * @property {string} value
 */

 /**
 * @typedef {object} SafeRecord
 * @property {?string} id
 * @property {string} partnerId
 * @property {string} value
 * @property {?string} updatedAt 1969-12-31T00:00:00.000Z
 * @property {?string} createdAt 1969-12-31T00:00:00.000Z
 */

 /**
  * @typedef {object} SafeDayTotal
  * @property {?number} betsAmount
  * @property {?number} betsCount
  * @property {?number} gamesPerDay
  * @property {?number} entryFeesGames
  * @property {?number} prizesWon
  * @property {?number} paysAmount
  * @property {?number} eodCommission
  * @property {?number} payCommission
  */

class PartnerApi extends HttpService {

  /**
   * fetching partners list
   * @async
   * @param {string} sortBy
   * @param {string} sortOrder
   * @param {number} limit
   * @param {number} offset
   * @returns {Promise<{ data: Partner[], total: number }>}
   */
  async getPartners(sortBy, sortOrder, limit, offset, filter) {
    return await this.get('/partner' + this.params({
      'query[sort][by]': sortBy,
      'query[sort][order]': sortOrder,
      'query[limit]': limit,
      'query[offset]': offset,
      ...filter
    }))
  }

  /**
   * clear partner's users
   * @async
   * @param {string} partnerId
   * @returns {Promise<{ removed: number }>}
   */
  async clearUsers(partnerId) {
    return await this.post('/clearUsers', { partnerId })
  }

  /**
   * fetching partner data by id
   * @async
   * @param {string} partnerId
   * @returns {Promise<Partner>}
   */
  async getPartner(partnerId) {
    return await this.get(`/partner/${partnerId}`)
  }

  /**
   * create new partner
   * @async
   * @param {object} data
   * @returns {Promise<Partner>}
   */
  async partnerCreate(data) {
    return await this.post('/partner', data)
  }

  /**
   * updates partner data
   * @async
   * @param {string} partnerId
   * @param {Partner} data
   * @returns {Promise<Partner>}
   */
  async partnerUpdate(partnerId, data) {
    return await this.put(`/partner/${partnerId}`, data)
  }

  /**
   * fetch service parameters schema with all available fields
   * @async
   * @returns {Object.<string, Object.<string, string>>} - object containing elements with keys - service identifiers
   * and values - objects with service params names keys and type description values ('number', 'string' etc)
   */
  async getServiceParamsSchema() {
    return await this.get('/service/params/schema')
  }

  /**
   * fetch partner's service params values
   * @async
   * @param {string} partnerId
   * @returns {Object.<string, Object.<string, (string|number|boolean)>>} - object containing elements with keys -
   * service identifiers and values - objects with service params names keys and params values
   */
  async getServiceParams(partnerId) {
    return await this.get(`/partner/${partnerId}/service/params`)
  }

  /**
   * fetch permitted provinces param
   * @async
   * @param {string} partnerId
   * @returns {Array} - array of provinces available for registration
   */
  async getPermittedProvincesParam(partnerId) {
    return await this.get(`/partner/${partnerId}/service/param/provinces`)
  }

  /**
   * updates partner's service params
   * @async
   * @param {string} partnerId
   * @param {Object.<string, Object.<string, (string|number|boolean)>>} - object containing elements with keys -
   * service identifiers and values - objects with service params names keys and params values
   * @returns {Object.<string, Object.<string, (string|number|boolean)>>} - partners's service params values object
   */
  async serviceParamsUpdate(partnerId, data) {
    return await this.post(`/partner/${partnerId}/service/params`, data)
  }

  /**
   * fetching templates list
   * @async
   * @param {number} limit
   * @param {number} offset
   * @param {string} filter - key or value substring filter
   * @returns {{ data: Template[], total: number }}
   */
  async getTemplates(limit, offset, filter) {
    return await this.get(`/template-search?query=${filter}&limit=${limit}&offset=${offset}`)
  }

  /**
   * fetching templates options: for different langs and partners
   * @async
   * @param {string} key - template key
   * @returns {Promise<{ data: Template[], total: number }>}
   */
  async getTemplateOptions(key) {
    return await this.get(`/template?query[filter][key]=${key}`)
  }

  /**
   * updates template option value
   * @async
   * @param {Template} data
   * @returns {Promise<Template>s}
   */
  async templateUpdate(data) {
    return await this.post('/template', data)
  }

  /**
   * fetching engine configuration
   * @async
   * @param {string} partnerId
   * @returns {Promise<Object[]>} - array of objects - engine-config nodes
   */
  async getEngineConfig(partnerId) {
    return await this.get(`/partner/${partnerId}/engine/config`)
  }

  /**
   * fetching retention templates
   * @async
   * @returns {{key: string}[]}
   */
  async getRetentionTemplates() {
    return await this.get('/template-retention')
  }

  /**
   * updates partner's engine configuration
   * @async
   * @param {string} partnerId
   * @param {Object[]} nodes - engine config nodes array
   * @returns {Promise<{ id: string, partnerId: string, createdAt: string, updatedAt: string, value: Object[] }>}
   */
  async setEngineConfig(partnerId, nodes) {
    return await this.post(`/partner/${partnerId}/engine/config`, nodes)
  }

  /**
   * fetching user message types
   * @async
   * @param {string} sortBy
   * @param {string} sortOrder - 'asc', 'desc'
   * @param {number} limit
   * @param {number} offset
   * @returns {{ data: MessageType[], total: number }}
   */
  async getMessageTypes(sortBy, sortOrder, limit, offset) {
    return await this.get('/user-message-type' + this.params({
      'query[filter][partnerId][is]': null,
      'query[sort][by]': sortBy,
      'query[sort][order]': sortOrder,
      'query[limit]': limit,
      'query[offset]': offset
    }))
  }

  /**
   * fetching user message type by key
   * @async
   * @param {string} templateKey
   * @returns {{ data: MessageType[], total: number }}
   */
  async getUserMessageTypeOptions(templateKey) {
    return await this.get('/user-message-type' + this.params({
      'query[filter][templateKey]': templateKey,
      'fields': 'templateKey,partnerId,enabled,isTechnical,type'
    }))
  }

  /**
   * updates user message type
   * @async
   * @param {MesssageType} messageTypeOption
   * @returns {MessageType}
   */
  async messageTypeUpdate(messageTypeOption) {
    return await this.post('/user-message-type', messageTypeOption)
  }

  /**
   * fetching SAFE table data
   * @async
   * @param {string} tableName - bet, pay, eod, token
   * @param {string} partnerId
   * @param {string} sortBy
   * @param {string} sortOrder - asc, desc
   * @param {number} limit
   * @param {number} offset
   * @param {?Object} filter - object with keys like 'query[filter][reportDate][>=]'
   * @returns {{ data: SafeRecord[], total: number }}
   */
  async getSafeData(tableName, partnerId, sortBy, sortOrder, limit, offset, filter) {
    return await this.get('/safe/' + tableName + this.params({
      'query[sort][by]': sortBy,
      'query[sort][order]': sortOrder,
      'query[limit]': limit,
      'query[offset]': offset,
      'query[filter][partnerId]': partnerId,
      ...filter
    }))
  }

  /**
   * fetching day summary based on eod, bet and pay tables value
   * @async
   * @param {string} date - date in 'YYYY-MM-DD' format
   * @param {string} partnerId
   * @returns {SafeDayTotal}
   */
  async getSafeTotal(date, partnerId) {
    return await this.get(`/safe/eod/count?date=${date}&partnerId=${partnerId}`)
  }

  /**
   * validates SAFE table record
   * @async
   * @param {string} tableName - bet, pay, eod, token
   * @param {SafeRecord} data
   * @returns {SafeRecord}
   * @throws {{ message: string, path: string }[]} validation errors
   */
  async validateSafeRecord(tableName, data) {
    return await this.post(`/safe/${tableName}/validate`, data)
  }

  /**
   * updates SAFE table record
   * @async
   * @param {string} tableName - bet, pay, eod, token
   * @param {SafeRecord} data
   * @returns {SafeRecord}
   */
  async updateSafeRecord(data, tableName) {
    return await this.put(`/safe/${tableName}/${data.id}`, data)
  }

  /**
   * creates SAFE table record
   * @async
   * @param {string} tableName - bet, pay, eod, token
   * @param {SafeRecord} data
   * @returns {SafeRecord}
   */
  async createSafeRecord(data, tableName) {
    return await this.post('/safe/' + tableName, data)
  }

  /**
   * deletes SAFE table record
   * @async
   * @param {string} tableName - bet, pay, eod, token
   * @param {string} recordId
   * @returns {{ success: boolean }}
   */
  async deleteSafeRecord(recordId, tableName) {
    return await this.delete(`/safe/${tableName}/${recordId}`)
  }

  /**
   * fetches the partners free limit data
   * @async
   * @param {string} partnerId
   * @returns {Promise<{
   *   enabled: boolean,
   *   freeServiceRuns: ?umber,
   *   overLimitServiceRuns: ?number,
   *   overLimitRegistrations: ?number,
   *   blockOverLimitTransactions: ?bolean,
   *   maxRunsCount: ?number,
   *   nextPeriod: ?string - 1969-12-31T00:00:00.000Z
   * }>}
   */
  async getFreeLimit(partnerId) {
    return await this.get(`/free-limit/${partnerId}`)
  }

  async getRegistrationsCount(partnerId, period) {
    return await this.get(`/registration-statistics/${partnerId}?period=${period}`)
  }

  async getStatistics(partnerId, params) {
    return await this.get(`/statistics/${partnerId}/${this.params(params)}`)
  }

  /**
   * resets the partner's free limit data
   * @async
   * @param {string} partnerId
   * @returns {Promise<{ success: boolean }>}
    */
   async resetFreeLimit(partnerId) {
     return await this.post(`/free-limit/${partnerId}/reset`)
   }

  /**
   * fetching OASIS credentials
   * @async
   * @param {string} partnerId
   * @param {string} sortBy
   * @param {string} sortOrder - asc, desc
   * @param {number} limit
   * @param {number} offset
   * @returns {{ data: SafeRecord[], total: number }}
   */
  async getOasisCredentials(partnerId, sortBy, sortOrder, limit, offset, filter) {
    return await this.get('/oasis-credentials/' + this.params({
      'query[sort][by]': sortBy,
      'query[sort][order]': sortOrder,
      'query[limit]': limit,
      'query[offset]': offset,
      'query[filter][partnerId]': partnerId,
      ...filter
    }))
  }

  /**
   * deletes OASIS credentials table record
   * @async
   * @param {string} recordId
   * @returns {{ success: boolean }}
   */
  async deleteOasisCredentialsRecord(recordId) {
    return await this.delete(`/oasis-credentials/${recordId}`)
  }

  /**
   * validates OASIS credentials table record
   * @async
   * @param {Object} data
   * @returns {Object}
   * @throws {{ message: string, path: string }[]} validation errors
   */
  async validateOasisCredentialsRecord(data) {
    return await this.post('/oasis-credentials/validate', data)
  }

  /**
   * updates OASIS credentials table record
   * @async
   * @param {Object} data
   * @returns {Object}
   */
  async updateOasisCredentialsRecord(data) {
    return await this.put(`/oasis-credentials/${data.id}`, data)
  }

  /**
   * creates OASIS credentials table record
   * @async
   * @param {Object} data
   * @returns {Object}
   */
  async createOasisCredentialsRecord(data) {
    return await this.post('/oasis-credentials', data)
  }

  /**
   * get master credentials for fail over setup
   * @async
   * @param {string} partnerId
   * @returns {Promise<{ host: string, token: string }>}
   */
  async getMasterCredentials(partnerId) {
    return await this.get(`/master-credentials/${partnerId}`)
  }
}

export default new PartnerApi()
