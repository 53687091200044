import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Select from 'components/Select'

import t from 'services/t'

const ServiceSetStatusForm = props => {
  const [value, setValue] = useState('')

  const onChange = value => setValue(value)

  const onSubmit = e => {
    e.preventDefault()
    props.onSubmit(value)
  }

  const submitDisabled = () => !value

  const statusList = [
    { label: t('label.service.status.1'), value: 1 },
    { label: t('label.service.status.2'), value: 2 },
    { label: t('label.service.status.3'), value: 3 },
    { label: t('label.service.status.4'), value: 4 },
    { label: t('label.service.status.5'), value: 5 }
  ]

  const input = {
    value,
    onChange
  }

  return (
    <form noValidate onSubmit={onSubmit}>
      <div className='avs-form-group avs-button-dropdown'>
        <div className='dropdown'>
          <Select
            label='Service status'
            options={statusList}
            useNative={false}
            input={input}
            meta={{ touched: false, error: null }}
          />
        </div>
        <div className='avs-form-group-inner'>
          <button className='avs-btn avs-btn-default' type='submit' disabled={submitDisabled()}>
            Set
          </button>
        </div>
      </div>
    </form>
  )
}

ServiceSetStatusForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}

export default ServiceSetStatusForm
