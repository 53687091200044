export const AUTH = 'AUTH'
export const AUTH_DONE = 'AUTH_DONE'
export const GET_USER = 'GET_USER'
export const GET_USER_DONE = 'GET_USER_DONE'
export const GET_SERVICES_UNIQUE_GROUPED = 'GET_SERVICES_UNIQUE_GROUPED'
export const GET_SERVICES_UNIQUE_GROUPED_DONE = 'GET_SERVICES_UNIQUE_GROUPED_DONE'
export const GET_SERVICES_PROCESS = 'GET_SERVICES_PROCESS'
export const GET_SERVICES_PROCESS_DONE = 'GET_SERVICES_PROCESS_DONE'
export const LOGIN = 'LOGIN'
export const LOGIN_DONE = 'LOGIN_DONE'
export const GET_CITIES = 'GET_CITIES'
export const GET_CITIES_DONE = 'GET_CITIES_DONE'
export const GET_PROVINCE = 'GET_PROVINCE'
export const GET_PROVINCE_DONE = 'GET_PROVINCE_DONE'
export const GET_USER_TOTAL = 'GET_USER_TOTAL'
export const GET_USER_TOTAL_DONE = 'GET_USER_TOTAL_DONE'
export const GET_DECRYPTED = 'GET_DECRYPTED'
export const GET_DECRYPTED_DONE = 'GET_DECRYPTED_DONE'
export const ADD_CITY = 'ADD_CITY'
export const ADD_CITY_DONE = 'ADD_CITY_DONE'
export const REGISTER = 'REGISTER'
export const REGISTER_DONE = 'REGISTER_DONE'
export const REGISTER_SIMPLE = 'REGISTER_SIMPLE'
export const REGISTER_SIMPLE_DONE = 'REGISTER_SIMPLE_DONE'
export const REGISTER_INSIC_PRECHECK = 'REGISTER_INSIC_PRECHECK'
export const REGISTER_INSIC_PRECHECK_DONE = 'REGISTER_INSIC_PRECHECK_DONE'
export const REGISTER_INCREMENTAL = 'REGISTER_INCREMENTAL'
export const REGISTER_INCREMENTAL_DONE = 'REGISTER_INCREMENTAL_DONE'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_DONE = 'RESET_PASSWORD_DONE'
export const CHECK = 'CHECK'
export const CHECK_DONE = 'CHECK_DONE'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_DONE = 'UPDATE_USER_DONE'
export const GET_MESSAGES = 'GET_MESSAGES'
export const GET_MESSAGES_DONE = 'GET_MESSAGES_DONE'
export const CLEAR_PASSPORT = 'CLEAR_PASSPORT'
export const CLEAR_PASSPORT_DONE = 'CLEAR_PASSPORT_DONE'
export const CLEAR_IMAGE = 'CLEAR_IMAGE'
export const CLEAR_IMAGE_DONE = 'CLEAR_IMAGE_DONE'
export const CHECK_EMAIL = 'CHECK_EMAIL'
export const CHECK_EMAIL_DONE = 'CHECK_EMAIL_DONE'
export const GET_LOQATE = 'GET_LOQATE'
export const GET_LOQATE_DONE = 'GET_LOQATE_DONE'
export const RESET_LOQATE = 'RESET_LOQATE'
export const OAUTH2 = 'OAUTH2'
export const OAUTH2_DONE = 'OAUTH2_DONE'
export const SKIP = 'SKIP'
export const SKIP_DONE = 'SKIP_DONE'
export const CONFIRM = 'CONFIRM'
export const CONFIRM_DONE = 'CONFIRM_DONE'
export const LOGOUT = 'LOGOUT'
export const CLEAR_OAUTH2_DATA = 'CLEAR_OAUTH2_DATA'
export const GET_USER_MESSAGES = 'GET_USER_MESSAGES'
export const GET_USER_MESSAGES_DONE = 'GET_USER_MESSAGES_DONE'
export const GET_USERS = 'GET_USERS'
export const GET_USERS_DONE = 'GET_USERS_DONE'
export const GET_USER_SERVICES = 'GET_USER_SERVICES'
export const GET_USER_SERVICES_DONE = 'GET_USER_SERVICES_DONE'
export const SET_USER_STATUS = 'SET_USER_STATUS'
export const SET_USER_STATUS_DONE = 'SET_USER_STATUS_DONE'
export const BLOCK_USER = 'BLOCK_USER'
export const BLOCK_USER_DONE = 'BLOCK_USER_DONE'
export const UNBLOCK_USER = 'UNBLOCK_USER'
export const UNBLOCK_USER_DONE = 'UNBLOCK_USER_DONE'
export const DELETE_PROFILE = 'DELETE_PROFILE'
export const DELETE_PROFILE_DONE = 'DELETE_PROFILE_DONE'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const DELETE_ACCOUNT_DONE = 'DELETE_ACCOUNT_DONE'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_DONE = 'DELETE_USER_DONE'
export const GET_EDITABLE_USER = 'GET_EDITABLE_USER'
export const GET_EDITABLE_USER_DONE = 'GET_EDITABLE_USER_DONE'
export const SET_EDITABLE_SECTION = 'SET_EDITABLE_SECTION'
export const GET_USER_TRANSACTIONS = 'GET_USER_TRANSACTIONS'
export const GET_USER_TRANSACTIONS_DONE = 'GET_USER_TRANSACTIONS_DONE'
export const UPDATE_EDITED_USER = 'UPDATE_EDITED_USER'
export const CLEAR_EDITABLE_USER = 'CLEAR_EDITABLE_USER'
export const USER_ERROR = 'USER_ERROR'
export const CLEAR_USER_ERROR = 'CLEAR_USER_ERROR'
export const SET_USER_FILTER = 'SET_USER_FILTER'
export const SET_SERVICE_STATUS = 'SET_SERVICE_STATUS'
export const SET_SERVICE_STATUS_DONE = 'SET_SERVICE_STATUS_DONE'
export const RESET_ATTEMPTS_COUNT = 'RESET_ATTEMPTS_COUNT'
export const CREATE_ANONYMOUS = 'CREATE_ANONYMOUS'
export const CREATE_ANONYMOUS_DONE = 'CREATE_ANONYMOUS_DONE'
export const GET_USER_DOUBLES_DONE = 'GET_USER_DOUBLES_DONE'
export const GET_USER_DOUBLES = 'GET_USER_DOUBLES'
export const TOGGLE_DOUBLE_ROW_SELECTED = 'TOGGLE_DOUBLE_ROW_SELECTED'
export const SET_DOUBLE_ROW_MASTER = 'SET_DOUBLE_ROW_MASTER'
export const SET_DOUBLES_MERGE_ERROR = 'SET_DOUBLES_MERGE_ERROR'
export const ADD_INVALID_EMAIL = 'ADD_INVALID_EMAIL'
export const CLEAR_INVALID_EMAILS = 'CLEAR_INVALID_EMAILS'
export const GET_SERVICES = 'GET_SERVICES'
export const GET_SERVICES_DONE = 'GET_SERVICES_DONE'
export const REGISTER_COMPANY = 'REGISTER_COMPANY'
export const REGISTER_COMPANY_DONE = 'REGISTER_COMPANY_DONE'
