import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as UserActions from '../redux/actions/user'
import * as PropTypes from 'prop-types'
import { getMap } from '../redux/actions/template'
import { Button } from '@material-ui/core'
import history from 'services/history'
import Service from '../components/Service'
import PrimaryStepper from './PrimaryStepper'
import 'styles/dashboard.css'
import t from '../services/t'
import PageTitle from './PageTitle'
import {
  getActiveService,
  getActiveStep,
  getNumberOfSteps,
  isProcessInactive,
  isProcessInvisible
} from './processHelper'
import SecondaryStepper from './SecondaryStepper'
import { isUserVerified } from '../helpers'

let interval = null

export const VerificationDetail = props => {
  const {
    auth,
    user,
    parsedToken,
    check,
    skip,
    confirm,
    userUpdate,
    getMessages,
    servicesMessages,
    clearPassport,
    clearImage,
    setServiceStatus,
    resetAttemptsCount,
    currentUser,
    getServicesProcess,
    processes
  } = props

  const [service, setService] = useState(null)
  const [serviceRedirectUrl, setServiceRedirectUrl] = useState({})

  const updateServiceRedirectUrl = service => {
    setServiceRedirectUrl({
      ...serviceRedirectUrl,
      [service.name]: service.data.redirectUrl
    })
  }

  useEffect(() => {
    if (service) {
      if ([1, 4].includes(service.status) && serviceRedirectUrl[service.name]) {
        localStorage.removeItem('redirects')
      }
      if (service.data && service.data.redirectUrl && service.inputMode.redirect && service.name !== 'diia-service') {
        try {
          const redirects = JSON.parse(window.localStorage.getItem('redirects') || '[]')
          if (!redirects.includes(service.data.redirectUrl)) {
            redirects.push(service.data.redirectUrl)
            window.localStorage.setItem('redirects', JSON.stringify(redirects))
            window.open(service.data.redirectUrl, '_self')
          } else {
            if (!serviceRedirectUrl[service.name]) {
              updateServiceRedirectUrl(service)
            }
          }
        } catch (e) {
          window.localStorage.removeItem('redirects')
        }
      }
    }
  }, [service, serviceRedirectUrl])

  const navigateBack = () => {
    history.push('/verificationOverview')
  }

  const navigateSuccess = () => {
    history.push('/verificationSuccess')
  }

  const pull = () => {
    auth()
    if (parsedToken) {
      getServicesProcess(parsedToken.userId)
    }
  }

  useEffect(() => {
    pull()
    interval = setInterval(pull, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (processes && user) {
      const processLabelId = window.localStorage.getItem('processLabelId')
      const process = processes[`${processLabelId}`]
      if (isUserVerified(user)) {
        navigateSuccess()
      } else if (process && (isProcessInactive(process) || isProcessInvisible(process))) {
        navigateBack()
      } else {
        const activeService = getActiveService(process)
        setService(activeService || null)
      }
    }
  })

  if (!user || !processes) return null

  let backButton = <Button onClick={navigateBack}>{t('label.backButton')}</Button>

  return (
    <div className='avs-page'>
      <PrimaryStepper activeStep={2} />
      {service && (
        <div className='avs-page-user avs-user-dashboard avs-material avs-show-valid'>
          <PageTitle>{t(`label.engine.service.${service.name}`)}</PageTitle>
          <Service
            key={service.id}
            service={service}
            user={user}
            check={check}
            skip={skip}
            confirm={confirm}
            pull={pull}
            userUpdate={userUpdate}
            messages={servicesMessages[service.name]}
            getMessages={getMessages}
            clearPassport={clearPassport}
            clearImage={clearImage}
            setServiceStatus={setServiceStatus}
            resetAttemptsCount={resetAttemptsCount}
            currentUser={currentUser}
          />
        </div>
      )}
      {service && (
        <SecondaryStepper
          activeStep={getActiveStep(service)}
          numberOfSteps={getNumberOfSteps(service)}
          backButton={backButton}
        />
      )}
    </div>
  )
}

VerificationDetail.propTypes = {
  auth: PropTypes.func.isRequired,
  user: PropTypes.shape({
    status: PropTypes.number,
    email: PropTypes.string
  }),
  parsedToken: PropTypes.shape({
    userId: PropTypes.string.isRequired
  }),
  check: PropTypes.func.isRequired,
  skip: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  userUpdate: PropTypes.func.isRequired,
  getMessages: PropTypes.func.isRequired,
  servicesMessages: PropTypes.object,
  clearPassport: PropTypes.func.isRequired,
  clearImage: PropTypes.func.isRequired,
  setServiceStatus: PropTypes.func.isRequired,
  resetAttemptsCount: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  getServicesProcess: PropTypes.func.isRequired,
  processes: PropTypes.object
}

export default withRouter(
  connect(
    state => ({
      user: state.userState.user,
      parsedToken: state.userState.parsedToken,
      servicesMessages: state.userState.servicesMessages,
      currentUser: state.userState.user,
      processes: state.userState.processes
    }),
    dispatch => bindActionCreators({ ...UserActions, getMap }, dispatch)
  )(VerificationDetail)
)
