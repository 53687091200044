import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'

import t from 'services/t'
import Input from 'components/Input'
import { email, required } from 'services/validation'

export const ResetPasswordForm = ({ handleSubmit, invalid, submitting, errorResponse, resetPasswordSuccess }) => (
  <form noValidate onSubmit={handleSubmit} className='avs-clean-form reset-password'>
    <div className='avs-form-group'>
      <legend className='avs-form-title'>{t('label.forgotPasswordPage.header')}</legend>
    </div>

    <div className='avs-form-group'>
      {errorResponse && errorResponse.map((e, i) =>
        <p key={i} className='avs-form-error-message'>{e.message}</p>
      )}

      {resetPasswordSuccess && <p className='avs-form-success-message'>
        {t('label.passwordResetForm.success')}
      </p>}
    </div>

    <Field
      name='email'
      type='email'
      label={t('label.user.attribute.email')}
      validate={[email, required]}
      component={Input}
    />

    <div className='avs-form-group'>
      <button type='submit' className='avs-btn avs-btn-primary avs-large' disabled={invalid || submitting}>
        {t('label.forgotPasswordPage.button.reset')}
      </button>
    </div>

    <div className='avs-container'>
      <div className='avs-form-group avs-text-center'>
        <p className='avs-text'>
          {t('label.registerForm.haveAccount')}
          {' '}
          <Link className='avs-link' to='/login'>
            {t('label.registerForm.login')}
          </Link>
        </p>
      </div>
    </div>
  </form>
)

ResetPasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  errorResponse: PropTypes.arrayOf(PropTypes.object),
  resetPasswordSuccess: PropTypes.bool
}

export default reduxForm({
  form: 'resetPassword'
})(ResetPasswordForm)
