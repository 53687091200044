import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import { required } from 'services/validation'
import { classNames } from 'helpers'

import t from 'services/t'

const CodeInput = ({ input, meta: { touched, error } }) => (
  <div>
    <fieldset>
      <input
        {...input}
        className={classNames({
          'avs-custom-input': true,
          'avs-invalid': touched && error,
          'avs-not-empty': input.value
        })}
      />
      <hr />
      <label>{t('label.userDashboard.input.code')}</label>
    </fieldset>
    <div className='avs-errors'>
      {touched && error && <p className='avs-form-error-message'>{error}</p>}
    </div>
  </div>
)

CodeInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  })
}

const ConfirmForm = ({ handleSubmit, invalid, submitting, service }) => (
  <form className='avs-clean-form' noValidate onSubmit={handleSubmit}>
    <div className='avs-form-group avs-form-group-inline avs-confirm-group'>
      <Field
        name='code'
        component={CodeInput}
        validate={[required]}
      />

      <button className='avs-btn avs-btn-default' disabled={invalid || submitting}>
        {t(`service.check.${service.name}.action.confirm`)}
      </button>
    </div>
  </form>
)

ConfirmForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  service: PropTypes.shape({
    name: PropTypes.string.isRequired
  })
}

export default reduxForm({
  form: 'confirm'
})(ConfirmForm)
