import 'whatwg-fetch'
import * as types from 'redux/types/user'
import store from 'redux/store'
import history from 'services/history'
import auth from 'services/auth'

const { __AVS_CONFIG__: {
  apiUrl, altApiUrl, partnerId, altPartnerId, startRoute
} } = window

const logout = () => ({
  type: types.LOGOUT
})

export default class HttpService {
  _host = `${apiUrl}${apiUrl[-1] === '/' ? '' : '/'}${partnerId}`

  static getExternalUrl(url) {
    let externalUrl = apiUrl + '/' + partnerId + url

    if (auth.token) {
      externalUrl += `?token=${auth.token}`
    }

    return externalUrl
  }

  static getUrl(url) {
    if (auth.token) {
      url += `?token=${auth.token}`
    }
    return url
  }

  async request(method, url, data) {
    try {
      const headers = {
        token: auth.token
      }

      let body

      if (data instanceof FormData) {
        body = data
      } else {
        headers['Content-Type'] = 'application/json'
        body = JSON.stringify(data)
      }

      const response = await fetch(this._host + url, { method, headers, body })

      if (response.status === 401 &&
        !['/login', '/registration'].includes(history.location.pathname)) {
        store.dispatch(logout())
        history.push(startRoute === '/registration' ? '/registration' : '/login')
      }

      if (response.status === 403) {
        alert(`${method} ${url}: No required permissions`)
      }

      if (response.status < 200 || response.status >= 300) {
        const error = new Error(response.statusText)
        try {
          error.response = await response.json()
        } catch (e) {}
        throw error
      }

      return await response.json()
    } catch (error) {
      if (altApiUrl && altPartnerId && error.message === 'Failed to fetch') {
        const newHost = `${altApiUrl}${altApiUrl[-1] === '/' ? '' : '/'}${altPartnerId}`
        if (this._host !== newHost) {
          this._host = newHost
          return await this.request(method, url, data)
        } else {
          throw error
        }
      } else {
        throw error
      }
    }
  }

  /**
   * GET request
   * @param {string} url
   */
  async get(url) {
    return await this.request('GET', url)
  }

  /**
   * POST request
   * @param {string} url
   * @param {object} body
   */
  async post(url, body) {
    return await this.request('POST', url, body)
  }

  /**
   * PUT request
   * @param {string} url
   * @param {object} body
   */
  async put(url, body) {
    return await this.request('PUT', url, body)
  }

  /**
   * DELETE request
   * @param {string} url
   */
  async delete(url) {
    return await this.request('DELETE', url)
  }

  /**
   * Convert object to url params string
   * @param {object} q url params
   */
  params(q) {
    return '?' + Object.keys(q).map(
      k => `${encodeURIComponent(k)}=${encodeURIComponent(q[k])}`
    ).join('&')
  }
}
