import React, { Component } from 'react'
import PropTypes from 'prop-types'
import t from 'services/t'

class VideoContainer extends Component {
  videoStream = null
  videoRef = React.createRef()
  canvasRef = React.createRef()
  tick = this.tick.bind(this)
  state = {
    snapshotUrls: [],
    timeRemaining: null
  }

  componentDidMount() {
    const { setPayloadData, setIsPayloadValid, isOpened } = this.props
    setPayloadData({ snapshots: [] })
    setIsPayloadValid(false)
    if (isOpened) {
      this.captureVideo()
    }
  }

  componentWillReceiveProps({ isOpened }) {
    if (isOpened && !this.props.isOpened) {
      this.captureVideo()
    }
    if (!isOpened && this.props.isOpened) {
      this.stopCaptureVideo()
    }
  }

  componentWillUnmount() {
    if (this.props.isOpened) {
      this.stopCaptureVideo()
    }
  }

  captureVideo = () => {
    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false
    }).then(stream => {
      this.videoRef.current.srcObject = stream
      this.videoStream = stream
    }).catch(err =>
      alert(t('service.check.identt.video.error.camera-not-accessible'))
    )
  }

  stopCaptureVideo = () => {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop())
    }
  }

  makeSnaphot = () => {
    const canvas = this.canvasRef.current
    const video = this.videoRef.current
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    canvas.getContext('2d').drawImage(video, 0, 0)
    const url = canvas.toDataURL('image/png')
    this.setState(({ snapshotUrls }) => ({ snapshotUrls: [...snapshotUrls, url] }))
  }

  tick() {
    const newTimeRemaining = this.state.timeRemaining - 1
    this.setState({ timeRemaining: newTimeRemaining })
    this.makeSnaphot()
    if (!newTimeRemaining) {
      clearInterval(this.intervalId)
      setTimeout(() => {
        this.setState({ timeRemaining: null })
        this.props.setPayloadData({
          snapshots: this.state.snapshotUrls.map(
            url => url.replace('data:image/png;base64,','')
          )
        })
        this.props.setIsPayloadValid(true)
      }, 500)
    }
  }

  startRecord = () => {
    this.setState({ snapshotUrls: [], timeRemaining: 2 })
    this.props.setIsPayloadValid(false)
    this.intervalId = setInterval(this.tick, 1000)
    this.makeSnaphot()
  }

  recordButtonLabel = () => {
    const { timeRemaining } = this.state
    return timeRemaining !== null ? `00:0${timeRemaining}` : t('service.check.identt.video.action.record')
  }

  onClickLink = url => () => {
    // let imageWindow = window.open('about:blank')
    // let image = new Image()
    // image.src = url
    // imageWindow.document.write(image.outerHTML)
  }

  render() {
    const { isRecordingAvailable } = this.props
    const links = this.state.snapshotUrls.map((url, index) => <div>
      <button
        key={index}
        onClick={this.onClickLink(url)}
        className='avs-link'
      >
        snapshot {index + 1}
      </button>
    </div>)

    return (<div className='avs-media-container'>
      <video ref={this.videoRef}  autoPlay/>
      <canvas ref={this.canvasRef} className='avs-hidden'/>
      {isRecordingAvailable && <>
        <div className='avs-form-group avs-button-container'>
          <button
            className='avs-btn avs-btn-default avs-btn-check avs-large'
            onClick={this.startRecord}
            disabled={this.state.timeRemaining !== null || !this.videoStream}
          >
            {this.recordButtonLabel()}
          </button>
        </div>
        {links}
      </>}
      </div>
    )
  }
}

VideoContainer.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  setPayloadData: PropTypes.func,
  setIsPayloadValid: PropTypes.func.isRequired,
  isRecordingAvailable: PropTypes.bool.isRequired,
  payloadData: PropTypes.shape({
    snapshots: PropTypes.arrayOf(PropTypes.string)
  })
}

export default VideoContainer
