import * as types from 'redux/types/user'

import auth from 'services/auth'

const {
  __AVS_CONFIG__: { token }
} = window

if (token) {
  auth.token = token
}

const initialState = {
  token: auth.token,
  parsedToken: auth.parsedToken,
  user: null,
  oauth2UserData: null,
  isEmailExists: null,
  servicesMessages: {},
  province: null,
  cities: [],
  loqateItems: [],
  asyncErrors: [],
  errorMessage: null,
  total: null,
  decrypted: null,
  errorResponse: [],
  userMessages: { data: [], total: 0, pageSize: 20, loading: false },
  users: { data: [], total: 0, pageSize: 20, loading: false },
  usersServices: {},
  shouldUpdateUsers: false,
  editableUser: null,
  editableSection: null,
  transactions: { data: [], total: 0, pageSize: 20, loading: false },
  submitting: false,
  filter: {},
  userDoubles: { data: [], total: 0, pageSize: 20, loading: false },
  activation_barcode: null,
  enroll_portal_url: null,
  confirmation: false,
  invalidEmails: [],
  serviceList: [],
  authRequiredFeilds: undefined
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_DONE:
    case types.GET_USER_DONE:
    case types.UPDATE_USER_DONE:
    case types.CLEAR_PASSPORT_DONE:
    case types.CLEAR_IMAGE_DONE:
    case types.REGISTER_DONE:
    case types.REGISTER_SIMPLE_DONE:
    case types.REGISTER_INCREMENTAL_DONE:
    case types.REGISTER_COMPANY_DONE:
    case types.GET_USER_TOTAL_DONE:
    case types.GET_DECRYPTED_DONE:
      return {
        ...state,
        user: action.payload.user
      }

    case types.REGISTER_INSIC_PRECHECK_DONE: {
      const { user, serviceResponse, token } = action.payload.response

      if (token) {
        auth.token = token
        return {
          ...state,
          token,
          parsedToken: auth.parsedToken,
          user,
          serviceResponse
        }
      } else {
        return {
          ...state,
          user,
          serviceResponse
        }
      }
    }

    case types.GET_SERVICES_PROCESS_DONE:
      return {
        ...state,
        processes: action.payload.processes
      }

    case types.LOGIN_DONE: {
      const { token, activation_barcode, enroll_portal_url, confirmation, requiredFields, message } =
        action.payload.response

      if (token) {
        auth.token = token
        return {
          ...state,
          token,
          parsedToken: auth.parsedToken,
          activation_barcode: null,
          enroll_portal_url: null,
          authMessage: null,
          confirmation: false,
          smsAuth: null,
          authRequiredFeilds: undefined
        }
      } else {
        return {
          ...state,
          activation_barcode,
          enroll_portal_url,
          confirmation,
          authRequiredFeilds: requiredFields,
          authMessage: message
        }
      }
    }

    case types.GET_CITIES_DONE:
      return {
        ...state,
        cities: action.payload.cities.map(city => ({
          text: city,
          source: 'insic'
        }))
      }

    case types.GET_PROVINCE_DONE:
      return {
        ...state,
        province: action.payload.province
      }

    case types.ADD_CITY_DONE:
      return {
        ...state,
        cities: [...state.cities.map(c => c.text), ...action.payload.cities]
          .filter((e, i, a) => {
            return a.indexOf(e) === i
          })
          .map(city => ({
            text: city,
            source: 'insic'
          }))
      }

    case types.RESET_PASSWORD_DONE:
      return {
        ...state,
        resetPasswordSuccess: action.payload.response.success
      }

    case types.GET_MESSAGES_DONE:
      return {
        ...state,
        servicesMessages: {
          ...state.servicesMessages,
          [action.payload.service]: action.payload.messages.data
        }
      }

    case types.CHECK_EMAIL:
      return {
        ...state,
        isEmailExists: null,
        errorMessage: null,
        errorResponse: []
      }

    case types.CHECK_EMAIL_DONE:
      return {
        ...state,
        isEmailExists: action.payload.response.exists
      }

    case types.RESET_LOQATE:
      return {
        ...state,
        loqateItems: []
      }

    case types.GET_LOQATE_DONE:
      return {
        ...state,
        loqateItems: action.payload.response.Items.map(Item => ({
          id: Item.Id,
          type: Item.Type,
          text: Item.Text,
          hightlight: Item.Highlight,
          description: Item.Description,
          source: 'loqate'
        }))
      }

    case types.OAUTH2_DONE: {
      const { token, userData } = action.payload.response

      if (token) {
        auth.token = token
        return {
          ...state,
          token,
          parsedToken: auth.parsedToken
        }
      } else if (userData) {
        return {
          ...state,
          oauth2UserData: userData
        }
      }

      return state
    }

    case types.CLEAR_OAUTH2_DATA:
      return {
        ...state,
        oauth2UserData: null
      }

    case types.LOGOUT: {
      auth.logout()
      return {
        ...state,
        token: null,
        parsedToken: null,
        user: null
      }
    }

    case types.AUTH:
    case types.OAUTH2:
    case types.GET_USER:
    case types.LOGIN:
    case types.REGISTER:
    case types.REGISTER_SIMPLE:
    case types.REGISTER_INSIC_PRECHECK:
    case types.REGISTER_INCREMENTAL:
    case types.REGISTER_COMPANY:
    case types.RESET_PASSWORD:
    case types.CHECK:
    case types.UPDATE_USER:
    case types.CLEAR_USER_ERROR:
    case types.CLEAR_PASSPORT:
    case types.CLEAR_IMAGE:
    case types.SKIP:
    case types.CONFIRM:
    case types.GET_CITIES:
    case types.ADD_CITY:
      return {
        ...state,
        error: null,
        errorMessage: null,
        errorResponse: []
      }

    case types.USER_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        errorResponse: action.payload.response,
        confirmation: false
      }

    case types.GET_USER_MESSAGES_DONE:
      return {
        ...state,
        userMessages: {
          data: action.payload.messages.data,
          total: action.payload.messages.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }

    case types.GET_USER_MESSAGES:
      return {
        ...state,
        userMessages: {
          ...state.userMessages,
          loading: true
        }
      }

    case types.GET_USERS_DONE:
      return {
        ...state,
        users: {
          data: action.payload.users.data,
          total: action.payload.users.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }

    case types.GET_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
          shouldUpdateUsers: false
        }
      }

    case types.GET_USER_SERVICES_DONE: {
      const uniqueServices = action.payload.services.reduce((result, service) => {
        if (!result.find(el => el.name === service.name)) {
          result.push(service)
        }
        return result
      }, [])
      return {
        ...state,
        usersServices: {
          ...state.usersServices,
          [action.payload.userId]: uniqueServices
        }
      }
    }

    case types.SET_USER_STATUS_DONE: {
      const newUsersData = state.users.data.reduce((result, userRow) => {
        if (userRow.id === action.payload.userId) {
          result.push({
            ...userRow,
            status: action.payload.response.status
          })
        } else {
          result.push(userRow)
        }
        return result
      }, [])
      return {
        ...state,
        users: {
          ...state.users,
          data: newUsersData
        }
      }
    }

    case types.UNBLOCK_USER_DONE:
    case types.BLOCK_USER_DONE: {
      const newUsersData = state.users.data.reduce((result, userRow) => {
        if (userRow.id === action.payload.userId) {
          result.push({
            ...userRow,
            isBlocked: action.payload.userData.isBlocked ? 1 : 0
          })
        } else {
          result.push(userRow)
        }
        return result
      }, [])
      return {
        ...state,
        users: {
          ...state.users,
          data: newUsersData
        }
      }
    }

    case types.DELETE_PROFILE_DONE: {
      const newUsersData = state.users.data.reduce((result, userRow) => {
        if (userRow.id === action.payload.userId) {
          result.push({
            ...userRow,
            profile: action.payload.userData.profile
          })
        } else {
          result.push(userRow)
        }
        return result
      }, [])
      return {
        ...state,
        users: {
          ...state.users,
          data: newUsersData
        }
      }
    }

    case types.DELETE_ACCOUNT_DONE: {
      const newUsersData = state.users.data.reduce((result, userRow) => {
        if (userRow.id === action.payload.userId) {
          result.push({
            partner: userRow.partner,
            profile: userRow.profile,
            ...action.payload.userData
          })
        } else {
          result.push(userRow)
        }
        return result
      }, [])
      return {
        ...state,
        users: {
          ...state.users,
          data: newUsersData
        }
      }
    }

    case types.DELETE_USER_DONE: {
      return {
        ...state,
        shouldUpdateUsers: true
      }
    }

    case types.GET_EDITABLE_USER_DONE: {
      return {
        ...state,
        editableUser: action.payload.user,
        editableSection: null
      }
    }

    case types.SET_EDITABLE_SECTION: {
      return {
        ...state,
        editableSection: action.payload.sectionName
      }
    }

    case types.GET_USER_TRANSACTIONS: {
      return {
        ...state,
        transactions: {
          ...state.transactions,
          loading: true
        }
      }
    }

    case types.GET_USER_TRANSACTIONS_DONE: {
      return {
        ...state,
        transactions: {
          data: action.payload.transactions.data,
          total: action.payload.transactions.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }
    }

    case types.UPDATE_EDITED_USER: {
      return {
        ...state,
        editableSection: null,
        editableUser: action.payload.user
      }
    }

    case types.CLEAR_EDITABLE_USER: {
      return {
        ...state,
        editableUser: null
      }
    }

    case types.SET_USER_FILTER: {
      return {
        ...state,
        filter: action.payload.filter
      }
    }

    case types.GET_USER_DOUBLES_DONE:
      return {
        ...state,
        userDoubles: {
          data: action.payload.doubles.data.map(group => ({
            ...group,
            users: group.users.map(user => ({
              ...user,
              selected: false,
              master: false
            }))
          })),
          total: action.payload.doubles.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }

    case types.GET_USER_DOUBLES:
      return {
        ...state,
        userDoubles: {
          ...state.userDoubles,
          loading: true
        }
      }

    case types.TOGGLE_DOUBLE_ROW_SELECTED:
      return {
        ...state,
        userDoubles: {
          ...state.userDoubles,
          data: state.userDoubles.data.map(group =>
            group.id === action.payload.groupId
              ? {
                  ...group,
                  users: group.users.map(user =>
                    user.id === action.payload.userId
                      ? {
                          ...user,
                          selected: !user.selected
                        }
                      : user
                  )
                }
              : group
          )
        }
      }

    case types.SET_DOUBLE_ROW_MASTER:
      return {
        ...state,
        userDoubles: {
          ...state.userDoubles,
          data: state.userDoubles.data.map(group =>
            group.id === action.payload.groupId
              ? {
                  ...group,
                  users: group.users.map(user => ({
                    ...user,
                    master: user.id === action.payload.userId
                  }))
                }
              : group
          )
        }
      }

    case types.SET_DOUBLES_MERGE_ERROR:
      return {
        ...state,
        userDoubles: {
          ...state.userDoubles,
          data: state.userDoubles.data.map(group =>
            group.id === action.payload.groupId
              ? {
                  ...group,
                  error: action.payload.error
                }
              : group
          )
        }
      }

    case types.ADD_INVALID_EMAIL:
      return {
        ...state,
        invalidEmails: [...state.invalidEmails, action.payload.email]
      }

    case types.CLEAR_INVALID_EMAILS:
      return {
        ...state,
        invalidEmails: []
      }

    case types.SET_SERVICE_STATUS_DONE:
      const userServices = state.usersServices[action.payload.userId] || []
      const newUserServices = userServices.reduce((result, service) => {
        if (service.name === action.payload.serviceName) {
          result.push({
            ...service,
            status: action.payload.status
          })
        } else {
          result.push(service)
        }
        return result
      }, [])
      return {
        ...state,
        usersServices: {
          ...state.usersServices,
          [action.payload.userId]: newUserServices
        }
      }

    case types.GET_SERVICES_DONE:
      return {
        ...state,
        serviceList: action.payload.services.sort()
      }

    default:
      return state
  }
}
