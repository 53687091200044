import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'

import t from 'services/t'
import Input from 'components/Input'
import Select from 'components/Select'
import Checkbox from 'components/Checkbox'
import {
  asyncUserValidate,
  email,
  password,
  required,
  maxLength255,
  date,
  validateAgreement
} from 'services/validation'

const isFieldVisible = field => {
  const { registrationFields = [] } = window.__AVS_CONFIG__
  return registrationFields.includes(field)
}

const isFieldOptional = field => {
  const { optionalFields = [] } = window.__AVS_CONFIG__
  return optionalFields.includes(field)
}

export const ConstructorForm = ({
  handleSubmit,
  invalid,
  submitting,
  cities,
  isBicVisible,
  errors
}) => (
  <form noValidate onSubmit={handleSubmit}>
    <div className='avs-form-group'>
      <legend className='avs-form-title'>{t('label.registrationForm.header')}</legend>
    </div>

    <div className='avs-form-group'>
      {errors.map((e, i) => (
        <p key={i} className='avs-form-error-message'>
          {e.message}
        </p>
      ))}
    </div>

    <Field
      type='email'
      name='email'
      label={t('label.user.attribute.email') + '*'}
      validate={[email, required]}
      component={Input}
    />

    <Field
      type='password'
      name='password'
      label={t('label.loginForm.input.password') + '*'}
      showPassword={true}
      validate={[password, required]}
      component={Input}
    />

    <Field
      type='checkbox'
      name='profile.agreement1'
      label={t('label.userProfile.attribute.agreement1') + '*'}
      validate={[validateAgreement]}
      component={Checkbox}
    />

    {t('label.userProfile.attribute.agreement2') && (
      <Field
        type='checkbox'
        name='profile.agreement2'
        label={t('label.userProfile.attribute.agreement2')}
        component={Checkbox}
      />
    )}

    {t('label.userProfile.attribute.agreement3') && (
      <Field
        type='checkbox'
        name='profile.agreement3'
        label={t('label.userProfile.attribute.agreement3')}
        component={Checkbox}
      />
    )}

    {t('label.userProfile.attribute.agreement4') && (
      <Field
        type='checkbox'
        name='profile.agreement4'
        label={t('label.userProfile.attribute.agreement4')}
        component={Checkbox}
      />
    )}

    {isFieldVisible('profile.greeting') && (
      <Field
        type='select'
        name='profile.greeting'
        label={
          isFieldOptional('profile.greeting')
            ? t('label.userProfile.attribute.greeting')
            : t('label.userProfile.attribute.greeting') + '*'
        }
        validate={!isFieldOptional('profile.greeting') && [required]}
        options={
          t('label.greeting.diverse')
            ? [t('label.greeting.male'), t('label.greeting.female'), t('label.greeting.diverse')]
            : [t('label.greeting.male'), t('label.greeting.female')]
        }
        component={Select}
      />
    )}

    {isFieldVisible('profile.firstName') && (
      <Field
        type='text'
        name='profile.firstName'
        label={t('label.userProfile.attribute.firstName') + '*'}
        validate={[maxLength255, required]}
        component={Input}
      />
    )}

    {isFieldVisible('profile.lastName') && (
      <Field
        type='text'
        name='profile.lastName'
        label={t('label.userProfile.attribute.lastName') + '*'}
        validate={[maxLength255, required]}
        component={Input}
      />
    )}

    {isFieldVisible('profile.birthday') && (
      <Field
        type='date'
        name='profile.birthday'
        label={
          isFieldOptional('profile.birthday')
            ? t('label.userProfile.attribute.birthday')
            : t('label.userProfile.attribute.birthday') + '*'
        }
        validate={isFieldOptional('profile.birthday') ? [date] : [date, required]}
        component={Input}
      />
    )}

    {isFieldVisible('profile.zip') && (
      <Field
        type='text'
        name='profile.zip'
        label={
          isFieldOptional('profile.zip')
            ? t('label.userProfile.attribute.zip')
            : t('label.userProfile.attribute.zip') + '*'
        }
        validate={isFieldOptional('profile.zip') ? [maxLength255] : [maxLength255, required]}
        component={Input}
      />
    )}

    {isFieldVisible('profile.city') && (
      <Field
        type='text'
        name='profile.city'
        label={
          isFieldOptional('profile.city')
            ? t('label.userProfile.attribute.city')
            : t('label.userProfile.attribute.city') + '*'
        }
        autocomplete={cities}
        validate={isFieldOptional('profile.city') ? [maxLength255] : [maxLength255, required]}
        component={Input}
      />
    )}

    {isFieldVisible('profile.street') && (
      <Field
        type='text'
        name='profile.street'
        label={
          isFieldOptional('profile.street')
            ? t('label.userProfile.attribute.street')
            : t('label.userProfile.attribute.street') + '*'
        }
        validate={isFieldOptional('profile.street') ? [maxLength255] : [maxLength255, required]}
        component={Input}
      />
    )}

    {isFieldVisible('profile.houseNumber') && (
      <Field
        type='text'
        name='profile.houseNumber'
        label={
          isFieldOptional('profile.houseNumber')
            ? t('label.userProfile.attribute.houseNumber')
            : t('label.userProfile.attribute.houseNumber') + '*'
        }
        validate={
          isFieldOptional('profile.houseNumber') ? [maxLength255] : [maxLength255, required]
        }
        component={Input}
      />
    )}

    {isFieldVisible('profile.phone') && (
      <Field
        type='text'
        name='profile.phone'
        label={
          isFieldOptional('profile.phone')
            ? t('label.userProfile.attribute.phone')
            : t('label.userProfile.attribute.phone') + '*'
        }
        validate={isFieldOptional('profile.phone') ? [maxLength255] : [maxLength255, required]}
        component={Input}
      />
    )}

    {isFieldVisible('profile.iban') && (
      <Field
        type='text'
        name='profile.iban'
        label={
          isFieldOptional('profile.iban')
            ? t('label.userProfile.attribute.iban')
            : t('label.userProfile.attribute.iban') + '*'
        }
        validate={isFieldOptional('profile.iban') ? [maxLength255] : [maxLength255, required]}
        component={Input}
      />
    )}

    {isBicVisible && (
      <Field
        type='text'
        name='profile.bic'
        label={
          isFieldOptional('profile.bic')
            ? t('label.userProfile.attribute.bic')
            : t('label.userProfile.attribute.bic') + '*'
        }
        validate={isFieldOptional('profile.bic') ? [maxLength255] : [maxLength255, required]}
        component={Input}
      />
    )}

    <div className='avs-form-group'>
      <button
        className='avs-btn avs-btn-primary avs-large'
        type='submit'
        disabled={invalid || submitting}
      >
        {t('label.registrationForm.button.register')}
      </button>
    </div>

    <div className='avs-container'>
      <div className='avs-form-group avs-text-center'>
        <p className='avs-text'>
          {t('label.registerForm.haveAccount')}{' '}
          <Link className='avs-link' to='/login'>
            {t('label.registerForm.login')}
          </Link>
        </p>
      </div>
    </div>
  </form>
)

ConstructorForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  isBicVisible: PropTypes.bool,
  cities: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.arrayOf(PropTypes.object)
}

export default reduxForm({
  form: 'registration',
  asyncValidate: asyncUserValidate,
  asyncBlurFields: [
    'email',
    'password',
    'profile.greeting',
    'profile.firstName',
    'profile.lastName',
    'profile.birthday',
    'profile.zip',
    'profile.city',
    'profile.street',
    'profile.houseNumber',
    'profile.phone',
    'profile.bic',
    'profile.iban'
  ],
  asyncChangeFields: [
    'profile.agreement1',
    'profile.agreement2',
    'profile.agreement3',
    'profile.agreement4'
  ]
})(ConstructorForm)
