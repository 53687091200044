import React, { useEffect } from 'react'
import PrimaryStepper from './PrimaryStepper'
import * as UserActions from 'redux/actions/user'
import * as ServiceActions from 'redux/actions/service'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getMap } from '../redux/actions/template'
import PropTypes from 'prop-types'
import { Box, Grid } from '@material-ui/core'
import history from 'services/history'
import VerificationOverviewTile from './VerificationOverviewTile'
import { isUserVerified } from '../helpers'
import {
  getStartedService,
  isProcessInactive,
  shouldHideProcess
} from './processHelper'
import t from '../services/t'
import AvsButton from '../components/AvsButton'

let interval

const VerificationOverview = props => {
  const { auth, user, parsedToken, changeSelectedProcess, getServicesProcess, processes } = props

  const pull = () => {
    auth()
    if (parsedToken) {
      getServicesProcess(parsedToken.userId)
    }
  }

  useEffect(() => {
    pull()
    interval = setInterval(pull, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  const processListWithLabelId = (processes) => {
    return Object.keys(processes).map(processLabelId => ({
      ...processes[processLabelId],
      processLabelId
    }))
  }

  useEffect(() => {
    if (processes) {
      let redirectToStartedProcess = window.localStorage.getItem('redirectToStartedProcess') === 'true'
      if (redirectToStartedProcess) {
        const firstProcessWithStartedService = processListWithLabelId(processes).find(it => getStartedService(it))

        if (firstProcessWithStartedService) {
          window.localStorage.setItem('redirectToStartedProcess', 'false')
          onSelectProcess(firstProcessWithStartedService)
        }
      }
    }
  })

  const onSelectProcess = (process, processLabelId) => {
    const labelId = processLabelId ? processLabelId : process.processLabelId
    if (!isProcessInactive(process)) {
      window.localStorage.setItem('processLabelId', labelId)
      changeSelectedProcess(process)
      history.push('/verificationDetail')
    }
  }

  const findFirstPrimaryOrActiveProcess = processes => {
    const processesList = processListWithLabelId(processes)
    let process = processesList.find(it => it.state === 1)
    if (!process) {
      process = processesList.find(it => it.state === 2)
    }
    return process
  }

  const onClickNext = processes => {
    let process = findFirstPrimaryOrActiveProcess(processes)
    if (process) {
      onSelectProcess(process)
    }
  }

  const onClickHelp = () => {
    window.open(t('label.user.dashboard.process.helpLink'), '_blank').focus()
  }

  if (!user || !processes) return null

  if (isUserVerified(user)) return <Redirect to={'/verificationSuccess'} />

  return (
    <div className='avs-page'>
      <PrimaryStepper activeStep={1} />
      <Grid container spacing={2}>
        {processListWithLabelId(processes).map(process => {
          if (shouldHideProcess(process)) {
            return ''
          }

          return (
            <Grid item xs={12} sm={6} key={process.processLabelId} onClick={() => onSelectProcess(process)}>
              <VerificationOverviewTile processLabelId={process.processLabelId} process={process} />
            </Grid>
          )
        })}
      </Grid>
      <Box mt={4} width='100%' display='flex' justifyContent='center'>
        <Box width='40%'>
          {findFirstPrimaryOrActiveProcess(processes) ? (
            <AvsButton label={t('label.user.dashboard.process.next')} onClick={() => onClickNext(processes)} />
          ) : (
            <AvsButton label={t('label.user.dashboard.process.help')} onClick={onClickHelp} />
          )}
        </Box>
      </Box>
    </div>
  )
}

VerificationOverview.propTypes = {
  auth: PropTypes.func.isRequired,
  getServicesUniqueGrouped: PropTypes.func.isRequired,
  user: PropTypes.shape({
    status: PropTypes.number,
    email: PropTypes.string
  }),
  parsedToken: PropTypes.shape({
    userId: PropTypes.string.isRequired
  }),
  changeSelectedProcess: PropTypes.func.isRequired,
  getServicesProcess: PropTypes.func.isRequired,
  processes: PropTypes.object
}

export default withRouter(
  connect(
    state => ({
      user: state.userState.user,
      parsedToken: state.userState.parsedToken,
      processes: state.userState.processes,
      currentUser: state.userState.user
    }),
    dispatch => bindActionCreators({ ...UserActions, ...ServiceActions, getMap }, dispatch)
  )(VerificationOverview)
)
