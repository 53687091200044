import { parseJwt } from 'helpers'

const { localStorage, __AVS_CONFIG__: { partnerId } } = window

class Auth {
  get token() {
    return localStorage.getItem(`avs-token-${partnerId}`) ||
      localStorage.getItem('avs-token')
  }

  set token(token) {
    localStorage.setItem(`avs-token-${partnerId}`, token)
    localStorage.setItem('avs-token', token)
  }

  get parsedToken() {
    return parseJwt(this.token)
  }

  logout() {
    localStorage.removeItem(`avs-token-${partnerId}`)
    localStorage.removeItem('avs-token')
  }
}

export default new Auth()
