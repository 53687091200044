import React from 'react'
import PropTypes from 'prop-types'

const AvsButton = ({ label, onClick, type, disabled }) => (
  <button onClick={onClick} type={type} className='avs-btn avs-btn-default avs-large' disabled={disabled}>
    {label}
  </button>
)

AvsButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool
}

AvsButton.defaultProps = {
  type: 'button',
  disabled: false
}

export default AvsButton
