import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import t from 'services/t'
import { required, email, password } from 'services/validation'

import Input from 'components/Input'

const GpmodLoginForm = ({ handleSubmit, goBack, invalid, submitting }) => (
  <form noValidate onSubmit={handleSubmit}>
    <div className='avs-form-group'>
      <fieldset>
        <p>{t('label.registrationForm.explanation.emailFound')}</p>
      </fieldset>
    </div>

    <Field
      type='email'
      name='email'
      label={t('label.user.attribute.email')}
      validate={[email, required]}
      component={Input}
    />

    <Field
      type='password'
      name='password'
      label={t('label.user.attribute.password')}
      validate={[password, required]}
      autofocus={true}
      component={Input}
    />

    <div className='avs-form-group'>
      <button className='avs-btn avs-btn-primary avs-large' disabled={invalid || submitting}>
        {t('label.loginForm.button.login')}
      </button>
    </div>

    <div className='avs-container'>
      <div className='avs-form-group avs-text-center'>
        <p className='avs-text'>
          <button type='button' className='avs-link' onClick={goBack}>
            {t('label.backButton')}
          </button>
        </p>
      </div>
    </div>
  </form>
)

GpmodLoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool
}

export default reduxForm({
  form: 'registration',
  destroyOnUnmount: false
})(GpmodLoginForm)
