import React, { Component } from 'react'
import * as faceapi from 'face-api.js'
import PropTypes from 'prop-types'
import { classNames, get } from 'helpers'
import t from 'services/t'

class FaceRecognitionModal extends Component {
  _isMounted = false
  countdownInterval = null
  videoStream = null
  videoRef = React.createRef()
  passportRef = React.createRef()
  camOverlayRef = React.createRef()
  history = []
  msgLog = []
  passportResult = null
  img = new Image()
  isActive = false
  isInitializationStarted = false
  maxValue = {}
  results = {
    happy: [],
    sad: [],
    neutral: [],
    age: []
  }

  state = {
    isAgeCheckAvailable: false,
    passportCheck: false,
    ageCheck: false,
    happy: false,
    sad: false,
    neutral: false,
    isInitialized: false,
    message: '',
    priorMessage: '',
    error: ''
  }

  componentDidMount() {
    this._isMounted = true
    if (get(this.props, 'params.faceDetectionThreshold')) {
      this.initialize()
    }
    document.body.style.overflow = 'hidden'
  }

  componentWillUnmount() {
    this.stopCaptureVideo()
    this._isMounted = false
    document.body.style.overflow = 'auto'
  }

  UNSAFE_componentWillReceiveProps({ serviceState }) {
    if (serviceState !== 'active') {
      this.stopCaptureVideo()
    }
    if (get(this.props, 'params.faceDetectionThreshold') && !this.isInitializationStarted) {
      this.initialize()
    }
  }

  log(msg) {
    const {
      params: { env }
    } = this.props
    if (env === 'dev') {
      // eslint-disable-next-line no-console
      console.info(msg)
    }
    this.msgLog.push(msg)
  }

  initialize = async () => {
    const { resourceUrl } = window.__AVS_CONFIG__
    const {
      params: {
        faceDetectionThreshold,
        inputSize,
        minAgeThreshold,
        canvasWidth,
        canvasHeigth,
        fps,
        timesLeft = 120
      },
      passportFrontUrl,
      passportBackUrl
    } = this.props
    this.log(`init ${JSON.stringify(this.props.params)}`)
    this.log(JSON.stringify(navigator))
    this.isInitializationStarted = true

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      this._isMounted && this.setState({ error: t('error.face-recognition.does-not-support') })
      this.log('device does not support')
      return setTimeout(this.closeModal, 3000)
    }
    if (minAgeThreshold) {
      this._isMounted && this.setState({ isAgeCheckAvailable: true })
    }

    this.isActive = true
    const passport = this.passportRef.current
    passport.width = canvasWidth
    passport.heigth = canvasHeigth

    let modelsUrl = `${resourceUrl}/models`
    if (modelsUrl.indexOf('localhost') > -1) {
      modelsUrl = 'http://localhost:3003/models/'
    }
    try {
      await Promise.all([
        faceapi.nets.tinyFaceDetector.load(modelsUrl),
        faceapi.loadFaceLandmarkModel(modelsUrl),
        faceapi.loadFaceRecognitionModel(modelsUrl),
        faceapi.loadFaceExpressionModel(modelsUrl),
        faceapi.loadAgeGenderModel(modelsUrl)
      ])
      this.log(`models loaded. url: ${modelsUrl}`)
    } catch (error) {
      this._isMounted && this.setState({ error: t('error.face-recognition.loading.models') })
      console.error(error)
      this.log(`loading models error: ${error}`)
      return setTimeout(this.closeModal, 3000)
    }
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          width: { max: 720 },
          height: { max: 720 },
          frameRate: fps
        }
      })
      if (!this.videoRef.current) {
        this.log('!this.videoRef.current')
        stream.getTracks().forEach(track => track.stop())
        return this.closeModal()
      }
      this.log('media device stream started')
      this.videoRef.current.srcObject = stream
      this.videoStream = stream
      this._isMounted && this.setState({ timesLeft })
      this.countdownInterval = setInterval(() => {
        if (!this._isMounted) return clearInterval(this.countdownInterval)
        if (this.state.timesLeft <= 0) {
          clearInterval(this.countdownInterval)
          return this.closeModal()
        }
        this._isMounted && this.setState({ timesLeft: this.state.timesLeft - 1 })
      }, 1000)

      setTimeout(() => {
        const { happy, sad, neutral, passportCheck, ageCheck } = this.state
        const isAllNotDone = [happy, sad, neutral, passportCheck, ageCheck].every(e => !e)
        if (isAllNotDone) {
          this.setState({ message: t('label.face-recognition.instruction.contact-support') })
        }
      }, 20 * 1000)
    } catch (error) {
      this._isMounted &&
        this.setState({ error: t('service.check.identt.video.error.camera-not-accessible') })
      console.error(error)
      this.log(`getUserMedia error: ${error}`)
      return setTimeout(this.closeModal, 3000)
    }

    this.img.crossOrigin = 'anonymous'
    const ctx = passport.getContext('2d')
    const loadImage = (url, lastAttempt) => {
      return new Promise(resolve => {
        this.img.onload = async () => {
          passport.height = (passport.width / this.img.width) * this.img.height
          ctx.drawImage(this.img, 0, 0, passport.width, passport.height)

          const initialPassportWidth = passport.width
          const initialPassportHeight = passport.height
          const imgOptions = new faceapi.TinyFaceDetectorOptions({
            inputSize,
            scoreThreshold: faceDetectionThreshold / 100
          })
          let degrees = 0
          this.passportResult = await faceapi
            .detectSingleFace(passport, imgOptions)
            .withFaceLandmarks()
            .withFaceDescriptor()

          while (!this.passportResult && degrees < 360) {
            this.rotateImg(degrees, initialPassportWidth, initialPassportHeight)
            degrees += 90
            this.passportResult = await faceapi
              .detectSingleFace(passport, imgOptions)
              .withFaceLandmarks()
              .withFaceDescriptor()
          }
          if (!this.passportResult) {
            if (lastAttempt) {
              this._isMounted &&
                this.setState({ error: t('error.face-recognition.unrecognized.passport') })
              this.log(`cannot recognize passport. ${JSON.stringify(imgOptions)}`)
              setTimeout(this.closeModal, 3000)
            }
            return resolve(false)
          }
          this.log('passport recognition done')
          resolve(true)
          this.recognize()
        }

        this.img.onerror = () => {
          this._isMounted && this.setState({ error: 'Image not loaded. Try to re-upload passport' })
          return setTimeout(this.closeModal, 3000)
        }
        this.img.src = url
      })
    }

    this.log('passport front recognition...')
    const isPassportFrontRecognized = await loadImage(passportFrontUrl, !passportBackUrl)
    if (!isPassportFrontRecognized && passportBackUrl) {
      this.log('passport back recognition...')
      loadImage(passportBackUrl, true)
    }
  }

  stopCaptureVideo = () => {
    this.log('stop capture video')
    this.isActive = false
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop())
    }
  }

  recognize = () => {
    try {
      const {
        setPayloadData,
        hideModal,
        check,
        saveFaceRecResult,
        params: {
          faceDetectionThreshold,
          inputSize,
          maxPassportDifference,
          minExpressionHappyThreshold,
          minExpressionHappyRetries,
          minExpressionSadThreshold,
          minExpressionSadRetries,
          minExpressionNeutralThreshold,
          minExpressionNeutralRetries,
          minAgeRetries,
          minAgeThreshold
        }
      } = this.props
      const { happy, sad, neutral, passportCheck, ageCheck } = this.state

      const expressions = []
      const expressionThresholds = {}
      const expressionRetries = {}
      if (minExpressionHappyThreshold && minExpressionHappyRetries) {
        expressions.push('happy')
        expressionThresholds.happy = minExpressionHappyThreshold
        expressionRetries.happy = minExpressionHappyRetries
      }
      if (minExpressionSadThreshold && minExpressionSadRetries) {
        expressions.push('sad')
        expressionThresholds.sad = minExpressionSadThreshold
        expressionRetries.sad = minExpressionSadRetries
      }
      if (minExpressionNeutralThreshold && minExpressionNeutralRetries) {
        expressions.push('neutral')
        expressionThresholds.neutral = minExpressionNeutralThreshold
        expressionRetries.neutral = minExpressionNeutralRetries
      }
      if (happy && sad && neutral && passportCheck && (ageCheck || !minAgeThreshold)) {
        const { apiUrl } = window.__AVS_CONFIG__
        saveFaceRecResult()
        setTimeout(hideModal, 3000)
        setPayloadData({
          results: this.results,
          base64frame: saveFaceRecResult(),
          apiUrl,
          msgLog: this.msgLog,
          passportCheck
        })
        return check()
      }

      if (!this.isActive) return
      const cam = this.videoRef.current
      const camOverlay = this.camOverlayRef.current

      if (!this.state.isInitialized && cam.videoWidth) {
        this._isMounted && this.setState({ isInitialized: true })
      }

      if (cam.paused || cam.ended || !faceapi.nets.tinyFaceDetector.isLoaded) {
        return setTimeout(this.recognize, 500)
      }
      const camOptions = new faceapi.TinyFaceDetectorOptions({
        inputSize,
        scoreThreshold: faceDetectionThreshold / 100
      })
      faceapi
        .detectAllFaces(cam, camOptions)
        .withFaceLandmarks()
        .withFaceDescriptors()
        .withFaceExpressions()
        .withAgeAndGender()
        .then(faces => {
          const facesAboveScore = faces.filter(
            f => f.detection._score >= faceDetectionThreshold / 100
          )
          facesAboveScore.forEach(camResult => {
            faceapi.matchDimensions(camOverlay, cam, true)
            const displaySize = { width: cam.videoWidth, height: cam.videoHeight }
            const resizedResults = faceapi.resizeResults(camResult, displaySize)
            const { left, top, width, height } = resizedResults.detection.box

            const ctx = camOverlay.getContext('2d')
            ctx.beginPath()
            ctx.lineWidth = 3
            ctx.strokeStyle = 'rgba(90, 90, 90, 0.5)'
            ctx.rect(left, top, width, height)
            ctx.stroke()
          })

          if (facesAboveScore.length > 1) {
            this.log('several faces')
            this.setState({
              message: t('error.face-recognition.several.faces')
            })
          }
          const [camResult] = faces
          if (camResult) {
            this._isMounted && this.setState({ priorMessage: '' })

            const label = 'Passport Owner'
            const labeledDescriptors = [
              new faceapi.LabeledFaceDescriptors(label, [this.passportResult.descriptor])
            ]
            const faceMatcher = new faceapi.FaceMatcher(labeledDescriptors)
            const bestMatch = faceMatcher.findBestMatch(camResult.descriptor)
            const data = {
              camResult: {
                descriptor: camResult.descriptor,
                expressions: camResult.expressions,
                age: camResult.age
              },
              bestMatch,
              createdAt: Date.now()
            }

            if (this.passportResult) {
              let isDiffOk = maxPassportDifference
                ? bestMatch.distance <= maxPassportDifference / 100
                : true
              if (facesAboveScore.length > 1) isDiffOk = false
              if (isDiffOk) {
                this._isMounted && this.setState({ message: '' })
                if (!passportCheck) {
                  this._isMounted && this.setState({ passportCheck: true })
                }

                expressions.forEach(expression => {
                  let expressionValue
                  if (expression === 'sad') {
                    expressionValue = [
                      data.camResult.expressions.sad,
                      data.camResult.expressions.angry,
                      data.camResult.expressions.fearful,
                      data.camResult.expressions.disgusted
                    ].sort((a, b) => b - a)[0]
                  } else {
                    expressionValue = data.camResult.expressions[expression]
                  }
                  if ((this.maxValue[expression] || 0) < expressionValue) {
                    this.maxValue[expression] = expressionValue
                  }
                  if (
                    !this.state[expression] &&
                    expressionThresholds[expression] / 100 < expressionValue
                  ) {
                    this.results[expression].push(data)
                    if (this.results[expression].length >= expressionRetries[expression]) {
                      this._isMounted && this.setState({ [expression]: true })
                    }
                  }
                })

                if (!ageCheck && camResult.age && minAgeRetries && minAgeThreshold) {
                  if (camResult.age >= minAgeThreshold) {
                    this.results.age.push(data)
                    if (this.results.age.length >= minAgeRetries) {
                      this._isMounted && this.setState({ ageCheck: true })
                    }
                  }
                }
              } else {
                this.log(
                  `large distance (${data.bestMatch.distance}) > (${maxPassportDifference / 100})`
                )
                this._isMounted &&
                  this.setState({
                    message: t('label.face-recognition.instruction.large-distance')
                  })
              }
            }
          } else {
            const context = camOverlay.getContext('2d')
            context.clearRect(0, 0, camOverlay.width, camOverlay.height)
            this.history = []
            if (happy || sad || neutral || passportCheck || (ageCheck && minAgeThreshold)) {
              this._isMounted &&
                this.setState({ priorMessage: t('error.face-recognition.lost.face') })
            }
          }
          setTimeout(this.recognize)
        })
        .catch(err => {
          console.error(err)
          this.log(`faceapi.detectSingleFace() error: ${err}`)
          this.closeModal()
        })
    } catch (err) {
      console.error(err)
      this.log(`faceapi.detectSingleFace() error: ${err}`)
      this.closeModal()
    }
  }

  rotateImg(degrees, width, height) {
    this.log(`rotate image ${degrees}`)
    const passport = this.passportRef.current
    if (!passport && degrees === 0) return
    const ctx = passport.getContext('2d')
    passport.height = degrees === 180 ? height : width
    passport.width = degrees === 180 ? width : height
    ctx.save()
    ctx.clearRect(0, 0, width, height)
    if (degrees === 90) ctx.translate(height, 0)
    if (degrees === 180) ctx.translate(width, height)
    if (degrees === 270) ctx.translate(0, width)
    ctx.rotate((degrees * Math.PI) / 180)
    ctx.drawImage(this.img, 0, 0, width, height)
    ctx.restore()
  }

  closeModal = () => {
    const { hideModal, saveFaceRecResult, setPayloadData, check, setFaceRecognitionClosed } =
      this.props
    const { isInitialized, passportCheck } = this.state
    const { apiUrl } = window.__AVS_CONFIG__
    this.log(JSON.stringify(this.maxValue))
    if (isInitialized) {
      setPayloadData({
        results: this.results,
        base64frame: saveFaceRecResult(),
        apiUrl,
        msgLog: this.msgLog,
        passportCheck
      })
    } else {
      setPayloadData({ results: this.results, msgLog: this.msgLog, passportCheck })
    }
    this.msgLog = []
    setFaceRecognitionClosed()
    check(true)
    hideModal()
  }

  getInstruction = () => {
    const { happy, sad, neutral, passportCheck, ageCheck, isAgeCheckAvailable } = this.state
    if (!passportCheck) return t('label.face-recognition.instruction.initial')
    if (!happy) return t('label.face-recognition.instruction.happy')
    if (!sad) return t('label.face-recognition.instruction.sad')
    if (!neutral) return t('label.face-recognition.instruction.neutral')
    if (isAgeCheckAvailable && !ageCheck) return t('label.face-recognition.instruction.neutral')
    return t('label.face-recognition.instruction.success')
  }

  render() {
    const {
      happy,
      sad,
      neutral,
      isInitialized,
      message,
      ageCheck,
      passportCheck,
      priorMessage,
      error,
      isAgeCheckAvailable,
      timesLeft
    } = this.state
    const videoWidth = this.videoRef.current && this.videoRef.current.videoWidth
    const videoHeight = this.videoRef.current && this.videoRef.current.videoHeight
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    const verticalMargins = windowHeight > (windowWidth * videoHeight) / videoWidth

    const width = verticalMargins
      ? 0.96 * windowWidth
      : (windowHeight * videoWidth) / videoHeight || null
    const height = verticalMargins
      ? (0.96 * windowWidth * videoHeight) / videoWidth
      : 0.96 * windowHeight
    const containerStyle = isInitialized ? { width, height } : { width: '96%' }

    const successMark = <span className='avs-modal-success-mark'>OK</span>
    const timeLeftInMinutes = Math.floor(timesLeft / 60)
    const timeLeftInSeconds = ('0' + (timesLeft - timeLeftInMinutes * 60)).slice(-2)

    return (
      <div className='avs-modal'>
        <div className='avs-modal-content' style={containerStyle}>
          {!isInitialized && (
            <div className='avs-video-initialization'>
              {!!error && <p className='avs-error'>{error}</p>}
              {!error && t('label.face-recognition.initializing')}
            </div>
          )}
          <div className='avs-video-container'>
            <video
              style={containerStyle}
              muted
              playsInline
              autoPlay
              ref={this.videoRef}
              className={classNames({
                'avs-hidden': !isInitialized
              })}
            />
            <canvas
              ref={this.camOverlayRef}
              className='avs-video-overlay'
              style={{ width, height }}
            />
          </div>
          <canvas ref={this.passportRef} className='avs-hidden' />
          <span className='avs-modal-close' onClick={this.closeModal}>
            &times;
          </span>
          {isInitialized && (
            <>
              <div className='avs-modal-checks'>
                <div className='avs-modal-check'>
                  <span className='avs-modal-check-label'>
                    {t('label.face-recognition.check.passport')}:
                  </span>
                  {passportCheck && successMark}
                </div>
                <div className='avs-modal-check'>
                  <span className='avs-modal-check-label'>
                    {t('label.face-recognition.check.happy')}:
                  </span>
                  {happy && successMark}
                </div>
                <div className='avs-modal-check'>
                  <span className='avs-modal-check-label'>
                    {t('label.face-recognition.check.sad')}:
                  </span>
                  {sad && successMark}
                </div>
                <div className='avs-modal-check'>
                  <span className='avs-modal-check-label'>
                    {t('label.face-recognition.check.neutral')}:
                  </span>
                  {neutral && successMark}
                </div>
                {isAgeCheckAvailable ? (
                  <div className='avs-modal-check'>
                    <span className='avs-modal-check-label'>
                      {t('label.face-recognition.check.age')}:
                    </span>
                    {ageCheck && successMark}
                  </div>
                ) : null}
                <div className='avs-modal-check'>
                  <span className='avs-modal-check-label'>
                    {t('label.face-recognition.check.timesLeft')}:
                  </span>
                  {timeLeftInMinutes}:{timeLeftInSeconds}
                </div>
              </div>
              <div className='avs-modal-explanation'>
                {priorMessage || message || this.getInstruction()}
              </div>
            </>
          )}
        </div>
      </div>
    )
  }
}

FaceRecognitionModal.propTypes = {
  setPayloadData: PropTypes.func,
  saveFaceRecResult: PropTypes.func,
  hideModal: PropTypes.func,
  check: PropTypes.func,
  setFaceRecognitionClosed: PropTypes.func,
  passportFrontUrl: PropTypes.string,
  passportBackUrl: PropTypes.string,
  serviceState: PropTypes.string,
  user: PropTypes.object,
  params: PropTypes.shape({
    faceDetectionThreshold: PropTypes.number,
    inputSize: PropTypes.number,
    maxPassportDifference: PropTypes.number,
    minExpressionHappyThreshold: PropTypes.number,
    minExpressionHappyRetries: PropTypes.number,
    minExpressionSadThreshold: PropTypes.number,
    minExpressionSadRetries: PropTypes.number,
    minExpressionNeutralThreshold: PropTypes.number,
    minExpressionNeutralRetries: PropTypes.number,
    minAgeRetries: PropTypes.number,
    minAgeThreshold: PropTypes.number,
    fps: PropTypes.number,
    timesLeft: PropTypes.number,
    canvasWidth: PropTypes.number,
    canvasHeigth: PropTypes.number,
    env: PropTypes.string
  })
}

export default FaceRecognitionModal
