import * as types from 'redux/types/partner'
import { preprocessDates, formatDate } from 'helpers'
import { betColumns, payColumns, eodColumns, tokenColumns } from 'services/safe'

const initialState = {
  partners: {
    data: [],
    total: 0,
    pageSize: 20,
    loading: false
  },
  partner: {
    name: null,
    link: null,
    useGenericPool: null,
  },
  errorMessage: null,
  errorResponse: [],
  name: null,
  link: null,
  useGenericPool: null,
  submitting: false,
  serviceParamsSchema: {},
  serviceParams: {},
  permittedProvinces: [],
  editableService: null,
  templates: {
    data: [],
    total: 0,
    pageSize: 20,
    loading: false
  },
  template: {
    key: null,
    lang: null,
    partnerId: null,
    value: null
  },
  templateOptions: [],
  templateSubmitting: false,
  retentionTemplates: [],
  messageTypes: {
    data: [],
    total: 0,
    pageSize: 20,
    loading: false
  },
  messageTypeOptions: [],
  messageTypeOption: {
    id: null,
    templateKey: null,
    partnerId: null,
    enabled: null,
    isTechnical: null,
    type: null
  },
  messageTypeSubmitting: false,
  bets: {
    data: [],
    total: 0,
    pageSize: 20,
    loading: false
  },
  pays: {
    data: [],
    total: 0,
    pageSize: 20,
    loading: false
  },
  eods: {
    data: [],
    total: 0,
    pageSize: 20,
    loading: false
  },
  tokens: {
    data: [],
    total: 0,
    pageSize: 20,
    loading: false
  },
  warnings: {
    data: [],
    total: 0,
    pageSize: 20,
    loading: false
  },
  oasisCredentials: {
    data: [],
    total: 0,
    pageSize: 20,
    loading: false
  },
  freeLimit: {
    enabled: false,
    freeServiceRuns: 0,
    overLimitServiceRuns: 0,
    overLimitRegistrations: 0,
    freeRegistrations: 0,
    blockOverLimitTransactions: false,
    countRegistrations: false,
    maxCount: 0,
    nextPeriod: null
  },
  masterCredentials: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PARTNERS:
      return {
        ...state,
        partners:  {
          ...state.partners,
          loading: true
        }
      }

    case types.GET_PARTNERS_DONE:
      return {
        ...state,
        partners:  {
          data: action.payload.response.data,
          total: action.payload.response.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }

    case types.PARTNER_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        errorResponse: action.payload.response
      }

    case types.GET_PARTNER_DONE:
      return {
        ...state,
        partner: action.payload.partner
      }

    case types.CLEAR_PARTNER:
      return {
        ...state,
        partner: {
          name: null,
          link: null,
          useGenericPool: null
        }
      }

    case types.GET_SERVICE_PARAMS_DONE:
      return {
        ...state,
        serviceParams: action.payload.response
      }

    case types.GET_PROVINCE_PARAM_DONE:
      return {
        ...state,
        permittedProvinces: action.payload.response
      }

    case types.GET_SERVICE_PARAMS_SCHEMA_DONE:
      return {
        ...state,
        serviceParamsSchema: action.payload.response
      }

    case types.START_SERVICE_EDITTING:
      return {
        ...state,
        editableService: action.payload.serviceId
      }

    case types.CANCEL_SERVICE_EDITTING:
      return {
        ...state,
        editableService: null
      }

    case types.UPDATE_SERVICE_PARAMS_DONE:
      return {
        ...state,
        serviceParams: {
          ...state.serviceParams,
          ...action.payload.data
        },
        editableService: null
      }

    case types.GET_PARTNER_TEMPLATES:
      return {
        ...state,
        templates: {
          ...state.templates,
          loading: true
        }
      }

    case types.GET_PARTNER_TEMPLATES_DONE:
      return {
        ...state,
        templates: {
          data: action.payload.templates.data,
          total: action.payload.templates.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }

    case types.GET_RETENTION_TEMPLATES_DONE: {
        return {
          ...state,
          retentionTemplates: action.payload.templates.map(template => template.key)
        }
      }

    case types.GET_MESSAGE_TYPES: {
      return {
        ...state,
        messageTypes: {
          ...state.messageTypes,
          loading: true
        }
      }
    }

    case types.GET_MESSAGE_TYPES_DONE: {
      return {
        ...state,
        messageTypes: {
          data: action.payload.messageTypes.data,
          total: action.payload.messageTypes.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }
    }

    case types.GET_USER_MESSAGE_TYPE_OPTIONS_DONE: {
      const options = action.payload.messageTypeOptions.data
      return {
        ...state,
        messageTypeOptions: options,
        messageTypeOption: {
          ...options[0]
        }
      }
    }

    case types.MESSAGE_TYPE_PARTNER_ID_CHANGE: {
      let messageTypeOption = state.messageTypeOptions.find(option => (
        option.partnerId === action.payload.partnerId
      )) || {
        templateKey: action.payload.templateKey,
        partnerId: action.payload.partnerId,
        enabled: false,
        isTechnical: false,
        type: null
      }

      return {
        ...state,
        messageTypeOption
      }
    }

    case types.GET_BETS:
      return {
        ...state,
        bets:  {
          ...state.bets,
          loading: true
        }
      }

    case types.GET_BETS_DONE:
      return {
        ...state,
        bets:  {
          data: preprocessDates(action.payload.response.data, betColumns),
          total: action.payload.response.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }

    case types.GET_PAYS:
      return {
        ...state,
        pays:  {
          ...state.pays,
          loading: true
        }
      }

    case types.GET_PAYS_DONE:
      return {
        ...state,
        pays:  {
          data: preprocessDates(action.payload.response.data, payColumns),
          total: action.payload.response.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }

    case types.GET_EODS:
      return {
        ...state,
        eods:  {
          ...state.eods,
          loading: true
        }
      }

    case types.GET_EODS_DONE:
      return {
        ...state,
        eods:  {
          data: preprocessDates(action.payload.response.data, eodColumns),
          total: action.payload.response.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }

    case types.GET_TOKENS:
      return {
        ...state,
        tokens:  {
          ...state.tokens,
          loading: true
        }
      }

    case types.GET_TOKENS_DONE:
      return {
        ...state,
        tokens:  {
          data: preprocessDates(action.payload.response.data, tokenColumns),
          total: action.payload.response.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }

    case types.GET_WARNING:
      return {
        ...state,
        warnings:  {
          ...state.warnings,
          loading: true
        }
      }

    case types.GET_WARNING_DONE:
      return {
        ...state,
        warnings:  {
          data: preprocessDates(action.payload.response.data, tokenColumns),
          total: action.payload.response.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }

    case types.GET_OASIS_CREDENTIALS:
      return {
        ...state,
        oasisCredentials: {
          ...state.oasisCredentials,
          loading: true
        }
      }

    case types.GET_OASIS_CREDENTIALS_DONE:
      return {
        ...state,
        oasisCredentials:  {
          data: action.payload.response.data,
          total: action.payload.response.total,
          pageSize: action.payload.pageSize,
          loading: false
        }
      }

    case types.GET_FREE_LIMIT_DONE: {
      const { nextPeriod } = action.payload.response
      return {
        ...state,
        freeLimitInit: {
          ...action.payload.response,
          nextPeriod: nextPeriod && formatDate(new Date(nextPeriod))
        }
      }
    }

    case types.CLEAR_FREE_LIMIT:
      return {
        ...state,
        freeLimit: initialState.freeLimit
      }

    default:
      return state
  }
}
