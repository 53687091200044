import t from '../services/t'

export const isProcessPrimary = process => {
  return process.state === 1
}

export const isProcessActive = process => {
  return process.state === 2
}

export const isProcessInactive = process => {
  return process.state === 3
}

export const isProcessInvisible = process => {
  return process.state === 4
}

export const shouldHideProcess = process => {
  return process === undefined || isProcessInvisible(process)
}

/**
 * Returns the active service of a given process
 * @param {object} process
 */
export const getActiveService = process => {
  return process && process.services ? process.services.find(it => it.state === 'active' && !it.hidden) : undefined
}

export const getStartedService = (process) => {
  return process ? process.services.find(it => it.status === 2) : undefined
}

/**
 * Returns the number of steps for a given services
 * @param {object} service
 */
export const getNumberOfSteps = service => {
  if (!service.steps) {
    console.error(`Field steps is missing on service ${service.name}`)
    return 1
  }
  //steps are given like '1,3' meaning step 1 of 3
  const split = service.steps.split(',')
  return parseInt(split[1])
}

/**
 * Returns the number of the current active step of a given service.
 * Active step means that the steps before this one have been finished.
 * @param {object} service
 */
export const getActiveStep = service => {
  if (!service.steps) {
    console.error(`Field steps is missing on service ${service.name}`)
    return 0
  }
  //steps are given like '1,3' meaning step 1 of 3
  const split = service.steps.split(',')
  //-1 because of zero index based counting
  return parseInt(split[0]) - 1
}

/**
 * Returns the process-explanation-templateId for the given processLabelId
 * @param {string} processLabelId
 */
export const getProcessExplanationLabelId = processLabelId => {
  //processLabelId is given like 'label.process.1'
  const split = processLabelId.split('.')
  const processNumber = split[split.length - 1]

  return 'label.user.dashboard.process.explanation.' + processNumber
}

export const getProcessSuccessLabelId = processLabelId => {
  if (!processLabelId) {
    return ''
  }
  //processLabelId is given like 'label.process.1'
  const split = processLabelId.split('.')
  const processNumber = split[split.length - 1]

  return `label.user.dashboard.process.${processNumber}.resultText`
}

export const getProcessSuccessImageId = processLabelId => {
  if (!processLabelId) {
    return ''
  }
  //processLabelId is given like 'label.process.1'
  const split = processLabelId.split('.')
  const processNumber = split[split.length - 1]

  return `label.user.dashboard.process.${processNumber}.resultImage`
}

export const getProcessImageLabelId = processLabelId => {
  //processLabelId is given like 'label.process.1'
  const split = processLabelId.split('.')
  const processNumber = split[split.length - 1]

  return 'label.user.dashboard.processImage.' + processNumber
}

export const getProcessDurationLabelId = processLabelId => {
  //processLabelId is given like 'label.process.1'
  const split = processLabelId.split('.')
  const processNumber = split[split.length - 1]

  return 'label.user.dashboard.process.processDuration.' + processNumber
}

export const getMainStepperConfig = () => {
  const defaultConfig = {
    showStepper: 'true',
    showRegisterStep: false,
    alternativeLabel: true,
    orientation: 'horizontal'
  }
  let config = t('label.user.dashboard.MainStepper.config')
  if (config && config.length > 0) {
    return { ...defaultConfig, ...JSON.parse(config) }
  }
  return defaultConfig
}

/**
 * Returns the process-explanation-templateId for the given processLabelId
 * @param {string} processLabelId
 */
export const getServiceStatusLabelId = service => {
  return 'label.service.status.' + service.status
}
