import React from 'react'
import PropTypes from 'prop-types'

import 'styles/checkbox.css'
import { classNames, sanitize } from 'helpers'

const ENTER_KEY = 13
const SPACEBAR_KEY = 32

const Checkbox = ({ label, input, disabled, meta: { dirty, error } }) => {
  const toggle = () => {
    if (!disabled) input.onChange(!input.value)
  }

  const onKeyDown = e => {
    if ([ENTER_KEY, SPACEBAR_KEY].includes(e.keyCode)) {
      toggle()
      e.preventDefault()
    }
  }

  return (
    <div className='avs-form-group'>
      <div className='avs-checkbox' tabIndex='0' onKeyDown={onKeyDown}>
        <span
          onClick={toggle}
          className={classNames({
            'avs-custom-checkbox': true,
            'avs-invalid': dirty && error,
            'avs-checked': input.value,
            'avs-checkbox-disabled': disabled
          })}
        >
          <span className='avs-label' onClick={toggle} dangerouslySetInnerHTML={{ __html: sanitize(label) }}></span>
        </span>
        <div className='avs-errors'>{dirty && error && <p className='avs-form-error-message'>{error}</p>}</div>
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    dirty: PropTypes.bool
  })
}

export default Checkbox
