import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'

import t from 'services/t'
import Input from 'components/Input'
import Select from 'components/Select'
import Checkbox from 'components/Checkbox'
import {
  asyncUserValidate,
  email,
  password,
  required,
  maxLength255,
  date,
  validateAgreement
} from 'services/validation'

export const CompanyForm = props => {
  const { handleSubmit, invalid, submitting, setRegistrationMethod } = props
  const [type, setType] = useState('user')

  const toggleType = () => {
    const isUserType = type === 'user'
    setType(isUserType ? 'company' : 'user')
    setRegistrationMethod(isUserType ? 'registerCompany' : 'registerSimple')
  }

  return (
    <form noValidate onSubmit={handleSubmit}>
      {type === 'user' && <UserRegistrationForm {...props} />}
      {type === 'company' && <CompanyRegistrationForm {...props} />}

      <div className='avs-form-group'>
        <button
          className='avs-btn avs-btn-primary avs-large'
          type='submit'
          disabled={invalid || submitting}
        >
          {t('label.registrationForm.button.register')}
        </button>
      </div>

      <div className='avs-container'>
        <div className='avs-form-group avs-text-center'>
          <p>
            <span className='avs-link' onClick={toggleType}>
              Register a company
            </span>
          </p>
          <p className='avs-text'>
            {t('label.registerForm.haveAccount')}{' '}
            <Link className='avs-link' to='/login'>
              {t('label.registerForm.login')}
            </Link>
          </p>
        </div>
      </div>
    </form>
  )
}

export const UserRegistrationForm = ({ cities, addCity, errors }) => (
  <>
    <div className='avs-form-group'>
      <legend className='avs-form-title'>{t('label.registrationForm.header')}</legend>
    </div>

    <div className='avs-form-group'>
      {errors.map((e, i) => (
        <p key={i} className='avs-form-error-message'>
          {e.message}
        </p>
      ))}
    </div>

    <Field
      type='email'
      name='email'
      label={t('label.user.attribute.email') + '*'}
      validate={[email, required]}
      component={Input}
    />

    <Field
      type='password'
      name='password'
      label={t('label.loginForm.input.password') + '*'}
      showPassword={true}
      validate={[password, required]}
      component={Input}
    />

    <Field
      type='checkbox'
      name='profile.agreement1'
      label={t('label.userProfile.attribute.agreement1') + '*'}
      validate={[validateAgreement]}
      component={Checkbox}
    />

    {t('label.userProfile.attribute.agreement2') && (
      <Field
        type='checkbox'
        name='profile.agreement2'
        label={t('label.userProfile.attribute.agreement2')}
        component={Checkbox}
      />
    )}

    {t('label.userProfile.attribute.agreement3') && (
      <Field
        type='checkbox'
        name='profile.agreement3'
        label={t('label.userProfile.attribute.agreement3')}
        component={Checkbox}
      />
    )}

    {t('label.userProfile.attribute.agreement4') && (
      <Field
        type='checkbox'
        name='profile.agreement4'
        label={t('label.userProfile.attribute.agreement4')}
        component={Checkbox}
      />
    )}

    <Field
      type='select'
      name='profile.greeting'
      label={t('label.userProfile.attribute.greeting') + '*'}
      validate={[required]}
      options={[t('label.greeting.male'), t('label.greeting.female')]}
      component={Select}
    />

    <Field
      type='text'
      name='profile.firstName'
      label={t('label.userProfile.attribute.firstName') + '*'}
      validate={[maxLength255, required]}
      component={Input}
    />

    <Field
      type='text'
      name='profile.lastName'
      label={t('label.userProfile.attribute.lastName') + '*'}
      validate={[maxLength255, required]}
      component={Input}
    />

    <Field
      type='date'
      name='profile.birthday'
      label={t('label.userProfile.attribute.birthday') + '*'}
      validate={[date, required]}
      component={Input}
    />

    <Field
      type='text'
      name='profile.zip'
      label={t('label.userProfile.attribute.zip') + '*'}
      validate={[maxLength255, required]}
      component={Input}
    />

    <Field
      type='text'
      name='profile.city'
      label={t('label.userProfile.attribute.city') + '*'}
      autocomplete={cities}
      addCity={addCity}
      validate={[maxLength255, required]}
      component={Input}
    />

    <Field
      type='text'
      name='profile.street'
      label={t('label.userProfile.attribute.street') + '*'}
      validate={[maxLength255, required]}
      component={Input}
    />

    <Field
      type='text'
      name='profile.houseNumber'
      label={t('label.userProfile.attribute.houseNumber') + '*'}
      validate={[maxLength255, required]}
      component={Input}
    />
  </>
)

export const CompanyRegistrationForm = ({ cities, addCity, errors }) => (
  <>
    <div className='avs-form-group'>
      <legend className='avs-form-title'>Company registration</legend>
    </div>

    <div className='avs-form-group'>
      {errors.map((e, i) => (
        <p key={i} className='avs-form-error-message'>
          {e.message}
        </p>
      ))}
    </div>

    <Field
      type='email'
      name='email'
      label={t('label.user.attribute.email') + '*'}
      validate={[email, required]}
      component={Input}
    />

    <Field
      type='password'
      name='password'
      label={t('label.loginForm.input.password') + '*'}
      showPassword={true}
      validate={[password, required]}
      component={Input}
    />

    <Field
      type='checkbox'
      name='profile.agreement1'
      label={t('label.userProfile.attribute.agreement1') + '*'}
      validate={[required]}
      component={Checkbox}
    />

    {t('label.userProfile.attribute.agreement2') && (
      <Field
        type='checkbox'
        name='profile.agreement2'
        label={t('label.userProfile.attribute.agreement2')}
        component={Checkbox}
      />
    )}

    {t('label.userProfile.attribute.agreement3') && (
      <Field
        type='checkbox'
        name='profile.agreement3'
        label={t('label.userProfile.attribute.agreement3')}
        component={Checkbox}
      />
    )}

    {t('label.userProfile.attribute.agreement4') && (
      <Field
        type='checkbox'
        name='profile.agreement4'
        label={t('label.userProfile.attribute.agreement4')}
        component={Checkbox}
      />
    )}

    <Field
      type='text'
      name='profile.firstName'
      label={t('label.userProfile.attribute.firstName') + '*'}
      validate={[maxLength255, required]}
      component={Input}
    />

    <Field
      type='text'
      name='profile.zip'
      label={t('label.userProfile.attribute.zip') + '*'}
      validate={[maxLength255, required]}
      component={Input}
    />

    <Field
      type='text'
      name='profile.city'
      label={t('label.userProfile.attribute.city') + '*'}
      autocomplete={cities}
      addCity={addCity}
      validate={[maxLength255, required]}
      component={Input}
    />

    <Field
      type='text'
      name='profile.street'
      label={t('label.userProfile.attribute.street') + '*'}
      validate={[maxLength255, required]}
      component={Input}
    />

    <Field
      type='text'
      name='profile.houseNumber'
      label={t('label.userProfile.attribute.houseNumber') + '*'}
      validate={[maxLength255, required]}
      component={Input}
    />
  </>
)

CompanyForm.propTypes =
  UserRegistrationForm.propTypes =
  CompanyRegistrationForm.propTypes =
    {
      handleSubmit: PropTypes.func.isRequired,
      invalid: PropTypes.bool,
      submitting: PropTypes.bool,
      cities: PropTypes.arrayOf(PropTypes.object),
      addCity: PropTypes.func,
      errors: PropTypes.arrayOf(PropTypes.object).isRequired
    }

export default reduxForm({
  form: 'registration',
  asyncValidate: asyncUserValidate,
  enableReinitialize: true,
  asyncBlurFields: [
    'email',
    'password',
    'profile.greeting',
    'profile.firstName',
    'profile.lastName',
    'profile.birthday',
    'profile.zip',
    'profile.city',
    'profile.street',
    'profile.houseNumber'
  ],
  asyncChangeFields: [
    'profile.agreement1',
    'profile.agreement2',
    'profile.agreement3',
    'profile.agreement4'
  ]
})(CompanyForm)
