import 'whatwg-fetch'

import HttpService from './HttpService'

/**
 * @typedef {object} User
 * @property {string} id
 * @property {string} email
 * @property {string} partnerId
 * @property {number} status null - not checked, 1 - pre-checked, 2 - checked
 * @property {string} role user, partnerAdmin, admin
 * @property {number} isBlocked 0, 1
 * @property {string} lang en, de
 * @property {Profile} profile
 */

/**
 * @typedef {Object} Profile
 * @property {string} greeting Mr, Mrs, Herr, Frau
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} birthday 1969-12-31T00:00:00.000Z
 * @property {string} zip
 * @property {string} city
 * @property {string} street
 * @property {string} houseNumber
 * @property {string} phone
 * @property {string} iban
 * @property {string} bic
 * @property {number} agreement1 1, 0
 * @property {number} agreement2
 * @property {number} agreement3
 * @property {number} agreement4
 * @property {string} passportFrontId
 * @property {string} passportBackId
 * @property {string} imageId
 */

/**
 * @typedef {Object} ServiceResponse
 * @property {boolean} success
 * @property {string} [redirectUrl]
 * @property {string[]} [fields] required fields
 * @property {boolean} [verified]
 * @property {string} [serviceResponse]
 */

/**
 * @typedef {Object} Message
 * @param {?string} id
 * @param {number} isTechnical 0, 1
 * @param {string} service
 * @param {{ fields.string[] }}
 * @param {string} templateKey
 * @param {string} text
 * @param {number} type 1 - info, 2 - warning, 3 - error, 4 - alert
 * @param {?string} createdAt 1969-12-31T00:00:00.000Z
 * @param {?string} updatedAt 1969-12-31T00:00:00.000Z
 * @param {string} userId
 */

/**
 * @typedef {Object} UserServices
 * @param {?number} amountLimit
 * @param {{ attemptsCount: number, confirmAttemptsCount: number, requiredFields: string[] }} data
 * @param {string} id
 * @param {boolean} isAvailable
 * @param {number} maxAttempts
 * @param {string} name
 * @param {number} status 1-not started, 2-started, 3-succeeded, 4-failed, 5-down
 */

/**
 * @typedef {Object} Transaction
 * @param {string} id
 * @param {string} partnerId
 * @param {string} userId
 * @param {string} merchantTxId
 * @param {number} amount
 * @param {string} currency
 * @param {string} purpose
 * @param {string} bic
 * @param {string} iban
 * @param {string} info1Label
 * @param {string} info1Text
 * @param {string} info2Label
 * @param {string} info2Text
 * @param {string} hash
 * @param {number} rc
 * @param {string} rcMsg
 * @param {string} reference
 * @param {string} createdAt 1969-12-31T00:00:00.000Z
 * @param {string} updatedAt 1969-12-31T00:00:00.000Z
 */

const { lang = 'de' } = window.__AVS_CONFIG__

class UserApi extends HttpService {
  auth() {
    return this.get('/auth/me')
  }

  /**
   * get user
   * @async
   * @param {string} userId
   * @returns {User}
   */
  getUser(userId) {
    return this.get(`/user/${userId}`)
  }

  /**
   * get list of cities
   * @async
   * @param {string} zip
   * @returns {string[]} cities
   */
  getCities(zip) {
    return this.get(`/auto-complete/cities/${zip}`)
  }

  addCity(zip) {
    return this.get(`/auto-complete/cities/${zip}`)
  }

  /**
   * get province by zip
   * @async
   * @param {string} zip
   * @returns {string} province
   */
  getProvince(zip) {
    return this.get(`/auto-complete/province/${zip}`)
  }

  /**
   * validation
   * @async
   * @param {User} userData
   * @throws {{ message: string, path: string }[]} validation errors
   */
  validate(userData) {
    return this.post(`/user/validate?lang=${lang}`, userData)
  }

  /**
   * login
   * @async
   * @param {{ email: string, password: string }} credentials
   * @returns {{ token: string }}
   * @throws {{ message: string }[]}
   */
  login(credentials) {
    return this.post('/auth/login', credentials)
  }

  /**
   * register
   * @async
   * @param {User} userData
   * @return {{ token: string }}
   */
  register(userData) {
    return this.post('/user', userData)
  }

  /**
   * register insic-precheck
   * @async
   * @param {User} userData
   * @return {{ token: string }}
   */
  registerInsicPrecheck(userData) {
    return this.post('/user-create-insic-precheck', userData)
  }

  /**
   * register simple
   * @async
   * @param {User} userData
   * @return {{ token: string }}
   */
  registerSimple(userData) {
    return this.post('/user-create-simple', userData)
  }

  registerCompany(companyData) {
    delete companyData.profile.greeting
    delete companyData.profile.lastName
    delete companyData.profile.birthday
    delete companyData.profile.iban
    delete companyData.profile.bic
    return this.post('/create-company', companyData)
  }

  /**
   * register incremental
   * @async
   * @param {User} userData
   * @return {{ token: string }}
   */
  registerIncremental(userData) {
    return this.post('/user-create-incremental', userData)
  }

  resetPassword(email) {
    return this.post('/user/reset-password', { email })
  }

  /**
   * get all processes
   * @async
   * @param {string} userId
   * @returns {object}
   */
  getServicesProcess(userId) {
    return this.get(`/user/${userId}/engine/services-process`)
  }

  /**
   * service check
   * @async
   * @param {string} userId
   * @param {string} serviceId
   * @param {object} data
   * @returns {ServiceResponse}
   */
  check(userId, serviceId, data) {
    return this.post(`/user/${userId}/engine/${serviceId}/check`, { data })
  }

  /**
   * user update
   * @async
   * @param {string} userId
   * @param {object} data
   * @param {boolean} withoutAutostart
   * @returns {User}
   */
  userUpdate(userId, data, withoutAutostart = false) {
    return this.put(`/user/${userId}` + this.params({ withoutAutostart }), data)
  }

  /**
   * get user's messages list for a certain service
   * @async
   * @param {string} userId
   * @param {string} service
   * @param {number} limit
   * @returns {{ data: Message[], total: number}}
   */
  getMessages(userId, service, limit) {
    return this.get(
      `/user/${userId}/messages` +
        this.params({
          'query[filter][service]': service,
          'query[sort][by]': 'createdAt',
          'query[sort][order]': 'desc',
          'query[limit]': limit,
          fields: 'text,type'
        })
    )
  }

  /**
   * get user's messages list
   * @async
   * @param {string} userId
   * @param {string} sortBy
   * @param {string} sortOrder - asc, desc
   * @param {number} limit
   * @param {number} offset
   * @returns {{ data: Message[], total: number}}
   */
  getUserMessages(userId, sortBy, sortOrder, limit, offset) {
    return this.get(
      `/user/${userId}/messages` +
        this.params({
          'query[sort][by]': sortBy,
          'query[sort][order]': sortOrder,
          'query[limit]': limit,
          'query[offset]': offset
        })
    )
  }

  clearPassport(userId) {
    return this.post(`/user/${userId}/clear-passport`)
  }

  clearImage(userId) {
    return this.post(`/user/${userId}/clear-image`)
  }

  validateEmail(userData) {
    return this.post('/user/validate/email', userData)
  }

  checkEmail(email) {
    return this.post('/check-email', { email })
  }

  getLoqate(text, id = '') {
    return this.post('/auto-complete/address', { text, id })
  }

  retrieveLoqate(id) {
    return this.post('/auto-complete/retrieve-address', { id })
  }

  oauth2(provider, { accessToken }) {
    return this.post(`/oauth2/${provider}`, { accessToken })
  }

  skip(userId, serviceId) {
    return this.post(`/user/${userId}/engine/${serviceId}/skip`)
  }

  /**
   * confirm code
   * @async
   * @param {string} userId
   * @param {string} serviceId
   * @param {stirng} code
   * @returns {{ success: boolean }}
   */
  confirm(userId, serviceId, code) {
    return this.post(`/user/${userId}/engine/${serviceId}/confirm/${code}`)
  }

  /**
   * fetching users list
   * @async
   * @param {string} sortBy
   * @param {string} sortOrder - asc, desc
   * @param {number} limit
   * @param {number} offset
   * @param {object} filter
   * @returns {{ data: User[], total: number}}
   */
  getUsers(sortBy, sortOrder, limit, offset, filter) {
    return this.get(
      '/user' +
        this.params({
          ...filter,
          'withRelated[]': 'profile',
          'withRelated[1]': 'partner',
          'query[sort][by]': sortBy,
          'query[sort][order]': sortOrder,
          'query[limit]': limit,
          'query[offset]': offset
        })
    )
  }

  /**
   * fetching user's services
   * @async
   * @param {string} userId
   * @returns {UserServices[]}
   */
  getUserServices(userId) {
    return this.get(`/user/${userId}/engine/services`)
  }

  /**
   * set user status
   * @async
   * @param {string} userId
   * @param {number} status - 1-pre-checked, 2-checked
   * @returns {{ success: boolean, status: number }}
   */
  setUserStatus(userId, status) {
    return this.post(`/user/${userId}/status/${status}`)
  }

  /**
   * block user
   * @async
   * @param {string} userId
   * @returns {User}
   */
  blockUser(userId) {
    return this.post(`/user/${userId}/block`)
  }

  /**
   * unblock user
   * @async
   * @param {string} userId
   * @returns {User}
   */
  unblockUser(userId) {
    return this.post(`/user/${userId}/unblock`)
  }

  /**
   * clear user account
   * @async
   * @param {string} userId
   * @returns {User}
   */
  deleteAccount(userId) {
    return this.delete(`/user/${userId}/account`)
  }

  /**
   * clear user profile
   * @async
   * @param {string} userId
   * @returns {User}
   */
  deleteProfile(userId) {
    return this.delete(`/user/${userId}/profile`)
  }

  /**
   * completely removes user
   * @async
   * @param {string} userId
   * @returns {{ success: boolean}}
   */
  fullyDelete(userId) {
    return this.delete(`/user/${userId}`)
  }

  /**
   * fetching user data by id
   * @async
   * @param {string} userId
   * @returns {User}
   */
  getEditableUser(userId) {
    return this.get(`/user/${userId}?withRelated[]=profile`)
  }

  /**
   * fetching user data including profile and partner data
   * @async
   * @param {userId} string
   * @returns {Promise<User>}
   */
  getUserForPrint(userId) {
    return this.get(`/user/${userId}?withRelated[]=profile&withRelated[]=partner`)
  }

  /**
   * fetching user's Giropay transactions
   * @async
   * @param {string} userId
   * @param {string} sortBy
   * @param {string} sortOrder - asc, desc
   * @param {number} limit
   * @param {number} offset
   * @returns {{ data: Transaction[], total: number }}
   */
  getUserTransactions(userId, sortBy, sortOrder, limit, offset) {
    return this.get(
      '/transactions' +
        this.params({
          'query[filter][userId][like]': userId,
          'query[sort][by]': sortBy,
          'query[sort][order]': sortOrder,
          'query[limit]': limit,
          'query[offset]': offset
        })
    )
  }

  /**
   * validate current user password
   * @async
   * @param {User} userData
   * @returns {{ id: string}}
   * @throws {{ message: string, path: string }[]} validation errors
   */
  validateCurrentPassword(userData) {
    return this.post('/user/validate/current-password', userData)
  }

  /**
   * fetching user's messages without sorting
   * @async
   * @param {string} userId
   * @returns {Promise<{ data: Message[], total: number }>}
   */
  getAllUserMessages(userId) {
    return this.get(`/user/${userId}/messages`)
  }

  /**
   * set service status for user
   * @async
   * @param {string} userId
   * @param {string} serviceName
   * @param {number} status - 1-not started, 2-started, 3-succeeded, 4-failed, 5-down
   * @returns {{ success: boolean}}
   */
  setServiceStatus(userId, serviceName, status) {
    return this.post(`/user/${userId}/engine/${serviceName}/status/${status}`)
  }

  /**
   * reset service attempts count
   * @async
   * @param {string} userId
   * @param {string} serviceName
   * @returns {{ success: boolean }}
   */
  resetAttemptsCount(userId, serviceName) {
    return this.post(`/user/${userId}/engine/${serviceName}/reset-attempts-count`)
  }

  /**
   * Add anonymous user
   * @async
   * @param {string} partnerId
   * @returns {User}
   */
  createAnonymous(partnerId) {
    return this.post('/user-create-anonymous', { partnerId })
  }

  /**
   * Post user message
   * @async
   * @param {string} userId
   * @param {{ text: string, type: number, risk: ?string }} data
   * @returns {Message}
   */
  postMessage(userId, data) {
    return this.post(`/user/${userId}/messages`, data)
  }

  /**
   * fetching user doubles list
   * @async
   * @param {string} sortBy
   * @param {string} sortOrder - asc, desc
   * @param {number} limit
   * @param {number} offset
   * @returns {{ data: UserProfile[], total: number}}
   */
  getUserDoubles(partnerId, sortBy, sortOrder, limit, offset) {
    return this.get(
      `/partner/${partnerId}/user-doubles` +
        this.params({
          'query[sort][by]': sortBy,
          'query[sort][order]': sortOrder,
          'query[limit]': limit,
          'query[offset]': offset
        })
    )
  }

  /**
   * Merge user doubles
   * @async
   * @param {{ users: string[], master: string }} data
   * @returns {Promise<{ success: boolean, error: ?string }>}
   */
  mergeUsers(partnerId, data) {
    return this.post(`/partner/${partnerId}/merge-users`, data)
  }

  /**
   * Get list of all services
   * @async
   * @returns {Promise<string[]>}
   */
  getServices() {
    return this.get('/engine/services')
  }

  /**
   * Get user totalIncome hash
   * @async
   * @returns {Promise<string[]>}
   */
  getUserTotalIncome(data) {
    return this.post('/total-income', data)
  }

  /**
   * Get decrypted data
   * @async
   * @returns {Promise<string[]>}
   */
  getDecryptedValue(data) {
    return this.post('/decrypt', data)
  }
}

export default new UserApi()
