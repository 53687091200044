import { Step, StepLabel, Stepper, withStyles } from '@material-ui/core'
import React from 'react'
import * as PropTypes from 'prop-types'
import t from '../services/t'
import { StylesProvider } from '@material-ui/core/styles'
import { getMainStepperConfig } from './processHelper'

const styles = {
  root: {
    paddingLeft: 0,
    paddingRight: 0
  }
}

const PrimaryStepper = props => {
  const { classes } = props

  let config = getMainStepperConfig()
  let showRegisterStep = window.localStorage.getItem('showRegisterStep') === 'true' || config.showRegisterStep
  let activeStep = showRegisterStep ? props.activeStep : props.activeStep - 1
  return (
    <StylesProvider injectFirst>
      {config.showStepper && (
        <Stepper
          alternativeLabel={config.alternativeLabel}
          orientation={config.orientation}
          activeStep={activeStep}
          className={classes.root}
        >
          {showRegisterStep && (
            <Step>
              <StepLabel>{t('label.user.dashboard.MainStepper.registration')}</StepLabel>
            </Step>
          )}
          <Step>
            <StepLabel>{t('label.user.dashboard.MainStepper.identification')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('label.user.dashboard.MainStepper.validation')}</StepLabel>
          </Step>
        </Stepper>
      )}
    </StylesProvider>
  )
}

PrimaryStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  classes: PropTypes.object
}

export default withStyles(styles)(PrimaryStepper)
