import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

export const PageTitle = ({ children }) => {
  return (
    <Typography variant={'h5'} classes={{ root: 'step-title' }}>
      {children}
    </Typography>
  )
}

PageTitle.propTypes = {
  children: PropTypes.string
}

export default PageTitle
