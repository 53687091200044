export const betColumns = [
  { name: 'id', type: 'text' },
  { name: 'playerId', type: 'text', required: true },
  { name: 'amount', type: 'number', required: true },
  { name: 'betDate', type: 'date', required: true },
  { name: 'bookingId', type: 'text', required: true },
  { name: 'fileId', type: 'text', required: true },
  { name: 'certId', type: 'text', required: true },
  { name: 'category', type: 'text', required: true },
  { name: 'betId', type: 'text', required: true },
  { name: 'settlementDate', type: 'date', required: true },
  { name: 'channel', type: 'text', required: true },
  { name: 'currency', type: 'text', required: true },
  { name: 'version', type: 'text' , required: true },
  { name: 'terminalId', type: 'text' },
  { name: 'website', type: 'text' },
  { name: 'cancellation', type: 'boolean' },
  { name: 'cancellationDate', type: 'date' },
  { name: 'partnerId', type: 'text', required: true, editable: false },
  { name: 'createdAt', type: 'date', editable: false , passedToServer: false },
  { name: 'updatedAt', type: 'date', editable: false , passedToServer: false },
  { name: 'isExported', type: 'boolean' },
]

export const payColumns = [
  { name: 'id', type: 'text' },
  { name: 'bookingId', type: 'text', required: true },
  { name: 'version', type: 'text', required: true },
  { name: 'fileId', type: 'text', required: true },
  { name: 'certId', type: 'text', required: true },
  { name: 'category', type: 'text', required: true },
  { name: 'playerId', type: 'text', required: true },
  { name: 'betId', type: 'text', required: true },
  { name: 'amount', type: 'number', required: true },
  { name: 'commission', type: 'text', required: true },
  { name: 'settlementDate', type: 'date', required: true },
  { name: 'currency', type: 'text', required: true },
  { name: 'partnerId', type: 'text', required: true, editable: false },
  { name: 'createdAt', type: 'date', editable: false, passedToServer: false },
  { name: 'updatedAt', type: 'date', editable: false , passedToServer: false },
  { name: 'isExported', type: 'boolean' },
]

export const eodColumns = [
  { name: 'id', type: 'text' },
  { name: 'version', type: 'text', required: true },
  { name: 'fileId', type: 'text', required: true },
  { name: 'licenseId', type: 'text' },
  { name: 'certId', type: 'text', required: true },
  { name: 'reportDate', type: 'date', required: true },
  { name: 'currency', type: 'text', required: true },
  { name: 'category', type: 'text', required: true },
  { name: 'gamesPerDay', type: 'text', required: true },
  { name: 'entryFeesGames', type: 'text', required: true },
  { name: 'entryFeesJackpot', type: 'text', required: true },
  { name: 'prizesWon', type: 'number', required: true },
  { name: 'commission', type: 'text', required: true },
  { name: 'partnerId', type: 'text', required: true, editable: false },
  { name: 'createdAt', type: 'date', editable: false, passedToServer: false },
  { name: 'updatedAt', type: 'date', editable: false, passedToServer: false },
  { name: 'isExported', type: 'boolean' }
]

export const tokenColumns = [
  { name: 'id', type: 'text' },
  { name: 'tokenId', type: 'text', required: true },
  { name: 'transactionId', type: 'text', required: true },
  { name: 'transactionDate', type: 'date' },
  { name: 'serviceId', type: 'text', required: true },
  { name: 'mac', type: 'text', required: true },
  { name: 'count', type: 'number', required: true },
  { name: 'isClosed', type: 'boolean', required: true },
  { name: 'partnerId', type: 'text', required: true, editable: false },
  { name: 'expiredAt', type: 'date', required: true },
  { name: 'createdAt', type: 'date', editable: false, passedToServer: false },
  { name: 'issueDate', type: 'text' }
]

export const warningColumns = [
  { name: 'id', type: 'text' },
  { name: 'playerId', type: 'text', required: true },
  { name: 'rule', type: 'text', required: true },
  { name: 'query', type: 'text', required: true },
  { name: 'result', type: 'text', required: true },
  { name: 'partnerId', type: 'text', required: true, editable: false },
  { name: 'createdAt', type: 'date', editable: false, passedToServer: false },
  { name: 'updatedAt', type: 'date', editable: false, passedToServer: false },
]
