import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Field, reduxForm, formValueSelector, getFormAsyncErrors } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as partnerActions from 'redux/actions/partner'
import PasswordContainMessage from '../secondaryComponents/PasswordContainMessage'
import t from 'services/t'
import {
  asyncEmailAndUserValidate,
  required,
  email,
  passwordCheck,
  maxLength255,
  date,
  validateAgreement
} from 'services/validation'
import { classNames, pwdContainMsgOn, pwdContainMsgOff, checkPwdContain, sanitize } from 'helpers'
import UserApi from 'services/UserApi'

import Input from 'components/Input'
import Select from 'components/Select'
import Checkbox from 'components/Checkbox'
import { getNationalities } from 'nationalities-list'

const {
  __AVS_CONFIG__: { loginRedirectUrl, partnerId, additionalFields, lang, pobAutocorrect }
} = window
const nationalitiesList = getNationalities(lang)
const getGreetingsList = () => {
  return [
    t('label.greeting.male'),
    t('label.greeting.female'),
    t('label.greeting.diverse'),
    t('label.greeting.notSpecified')
  ].filter(e => e)
}

const shouldAsyncValidate = params => {
  if (!params.syncValidationPasses) {
    return false
  }
  switch (params.trigger) {
    case 'blur':
    case 'change':
    case 'submit':
      return true
    default:
      return false
  }
}

export const InsicPrecheckRegister = props => {
  const {
    handleSubmit,
    getPermittedProvincesParam,
    change,
    getLoqate,
    resetLoqate,
    touch,
    formAsyncErrors,
    province,
    cities,
    loqateItems,
    invalid,
    isBicVisible,
    submitting,
    agreement,
    permittedProvinces,
    clearAsyncError,
    clearInvalidEmails,
    invalidEmails,
    city,
    zip,
    placeOfBirth
  } = props
  const [addressState, setAddressState] = useState({
    isAddressHidden: true,
    isLocalitySelected: false
  })

  const [isProvinceAllowed, setIsProvinceAllowed] = useState(true)

  useEffect(() => {
    getPermittedProvincesParam(partnerId)
  }, [])

  const searchPlaceOfBirth = async () => {
    const { success, googleResult } = await UserApi.validatePlaceOfBirth({
      placeOfBirth,
      partnerId
    })
    if (success && placeOfBirth !== googleResult) {
      return change('profile.placeOfBirth', googleResult)
    }
    return
  }

  const isEmpty = array => (array.length && array[0].length) === 0

  useEffect(() => {
    if ((province || zip) && !isEmpty(permittedProvinces) && permittedProvinces.indexOf(province) === -1) {
      setIsProvinceAllowed(false)
    } else {
      setIsProvinceAllowed(true)
    }
  }, [province, permittedProvinces, zip])

  useEffect(() => {
    if (city) {
      setAddressState({
        isAddressHidden: false,
        isLocalitySelected: false
      })
    }
  }, [city])

  const onAddressClick = (item, input) => {
    if (item.type === 'Address') {
      UserApi.retrieveLoqate(item.id).then(data => {
        const [address] = data.Items
        change('profile.zip', address.PostalCode)
        change('profile.city', address.City)
        change('profile.street', address.Street)
        change('profile.houseNumber', address.BuildingNumber)
        touch('profile.zip', 'profile.city', 'profile.street', 'profile.houseNumber')

        input.onChange('')
        resetLoqate()
      })
    } else {
      setAddressState({ ...addressState, isLocalitySelected: true })
      getLoqate(item.text, item.id)
    }
  }

  const onZipBlur = () => {
    touch('profile.city')
  }

  const checkProvince = value => {
    return permittedProvinces && permittedProvinces.indexOf(value) === 0
      ? undefined
      : t('error.province.violates.permitted.value')
  }

  const invalidEmail = invalidEmails?.length > 0 && formAsyncErrors && formAsyncErrors.email ? true : false

  return (
    <form className='avs-registration-form' noValidate onSubmit={handleSubmit}>
      <div className='avs-registerForm-credentials'>
        <Field
          type='email'
          name='email'
          label={t('label.user.attribute.email')}
          validate={[email, required]}
          component={Input}
        />

        {invalidEmail && (
          <div className='avs-registerForm-email-note'>
            <div
              className='avs-registerForm-email-note-message'
              dangerouslySetInnerHTML={{
                __html: sanitize(t('label.registerForm.invalid-email.note'))
              }}
            ></div>
            <button
              className='avs-registerForm-email-note-btn'
              dangerouslySetInnerHTML={{
                __html: sanitize(t('label.registerForm.button.clearAsyncError'))
              }}
              type='submit'
              onClick={() => {
                clearInvalidEmails()
                clearAsyncError('email')
              }}
            ></button>
          </div>
        )}

        {additionalFields?.includes('phone') && (
          <div
            className={classNames({
              'avs-form-registration-phone': true
            })}
          >
            <Field
              type='text'
              name='profile.phone'
              label={t('label.userProfile.attribute.phone')}
              validate={[maxLength255, required]}
              component={Input}
            />
          </div>
        )}

        <Field
          type='password'
          name='password'
          label={t('label.user.attribute.password')}
          validate={[passwordCheck, required]}
          showPassword={true}
          component={Input}
          onFocus={pwdContainMsgOff}
          onBlur={pwdContainMsgOn}
          onChange={checkPwdContain}
        />

        <PasswordContainMessage />
      </div>

      <div
        className={classNames({
          'avs-form-registration-agreement1': true
        })}
      >
        <Field
          type='checkbox'
          name='profile.agreement1'
          label={t('label.userProfile.attribute.agreement1')}
          validate={[validateAgreement]}
          component={Checkbox}
        />
      </div>

      {t('label.userProfile.attribute.agreement4') && (
        <div
          className={classNames({
            'avs-form-registration-agreement4': true
          })}
        >
          <Field
            type='checkbox'
            name='profile.agreement4'
            label={t('label.userProfile.attribute.agreement4')}
            component={Checkbox}
          />
        </div>
      )}

      <div
        className={classNames({
          'avs-form-registration-greeting': true
        })}
      >
        <Field
          type='select'
          name='profile.greeting'
          label={t('label.userProfile.attribute.greeting')}
          validate={[required]}
          options={getGreetingsList()}
          component={Select}
        />
      </div>

      {additionalFields?.includes('username') && (
        <div
          className={classNames({
            'avs-form-registration-username': true
          })}
        >
          <Field
            type='text'
            name='profile.userName'
            label={t('label.userProfile.attribute.userName')}
            validate={[maxLength255, required]}
            component={Input}
            isErrorVisible={formAsyncErrors?.profile?.userName ? true : false}
          />
        </div>
      )}
      <div className='avs-form-registration-fields'>
        <>
          <div
            className={classNames({
              'avs-form-registration-firstname': true
            })}
          >
            <Field
              type='text'
              name='profile.firstName'
              label={t('label.userProfile.attribute.firstName')}
              validate={[maxLength255, required]}
              component={Input}
              isErrorVisible={formAsyncErrors?.profile?.firstName ? true : false}
            />
          </div>
          <div
            className={classNames({
              'avs-form-registration-lastname': true
            })}
          >
            <Field
              type='text'
              name='profile.lastName'
              label={t('label.userProfile.attribute.lastName')}
              validate={[maxLength255, required]}
              component={Input}
              isErrorVisible={formAsyncErrors?.profile?.lastName ? true : false}
            />
          </div>
          {t('label.userProfile.attribute.agreement3') && (
            <Field
              type='checkbox'
              name='profile.agreement3'
              label={t('label.userProfile.attribute.agreement3')}
              component={Checkbox}
            />
          )}
        </>

        {agreement && (
          <>
            <div
              className={classNames({
                'avs-form-registration-maidenname': true
              })}
            >
              <Field
                type='text'
                name='profile.maidenName'
                label={t('label.userProfile.attribute.maidenName')}
                validate={[maxLength255, required]}
                component={Input}
                isErrorVisible={formAsyncErrors?.profile?.maidenName ? true : false}
              />
            </div>
          </>
        )}

        <>
          <div
            className={classNames({
              'avs-form-registration-birthday': true
            })}
          >
            <Field
              type='date'
              name='profile.birthday'
              label={t('label.userProfile.attribute.birthday')}
              validate={[date, required]}
              component={Input}
              isErrorVisible={formAsyncErrors?.profile?.birthday ? true : false}
            />
          </div>
        </>

        <>
          <div
            className={classNames({
              'avs-form-registration-placeOfBirth': true
            })}
          >
            <Field
              type='text'
              name='profile.placeOfBirth'
              label={t('label.userProfile.attribute.placeOfBirth')}
              validate={[maxLength255, required]}
              component={Input}
              onBlur={pobAutocorrect ? searchPlaceOfBirth : null}
            />
          </div>
        </>

        <>
          {additionalFields?.includes('nationality') && (
            <div
              className={classNames({
                'avs-form-registration-nationality': true
              })}
            >
              <Field
                type='text'
                name='profile.nationality'
                label={t('label.userProfile.attribute.nationality')}
                validate={[maxLength255, required]}
                options={nationalitiesList}
                component={Select}
              />
            </div>
          )}

          {additionalFields?.includes('iban') && (
            <Field
              type='text'
              name='profile.iban'
              label={t('label.userProfile.attribute.iban')}
              validate={[maxLength255, required]}
              component={Input}
            />
          )}

          {isBicVisible && (
            <Field
              type='text'
              name='profile.bic'
              label={t('label.userProfile.attribute.bic')}
              validate={[maxLength255, required]}
              component={Input}
            />
          )}
          {t('label.userProfile.attribute.agreement2') && (
            <Field
              type='checkbox'
              name='profile.agreement2'
              label={t('label.userProfile.attribute.agreement2')}
              validate={[validateAgreement]}
              component={Checkbox}
            />
          )}

          {addressState.isAddressHidden && (
            <div className='avs-form-registration-address'>
              {
                <p
                  className='avs-form-registration-address-note'
                  dangerouslySetInnerHTML={{
                    __html: sanitize(t('label.registerForm.address.note'))
                  }}
                />
              }
              <Field
                type='text'
                name='profile.address'
                label={t('label.userProfile.attribute.address')}
                validate={checkProvince}
                autocomplete={loqateItems}
                autocompleteCallback={onAddressClick}
                isAutocompleteOpen={addressState.isLocalitySelected}
                component={Input}
                isErrorVisible={formAsyncErrors?.profile?.address ? true : false}
              />
            </div>
          )}

          <div className={classNames({ 'avs-hidden': addressState.isAddressHidden })}>
            <div
              className={classNames({
                'avs-form-registration-zip': true
              })}
            >
              <Field
                type='text'
                name='profile.zip'
                label={t('label.userProfile.attribute.zip')}
                validate={[maxLength255, required]}
                component={Input}
                onBlur={onZipBlur}
                isErrorVisible={formAsyncErrors?.profile?.zip ? true : false}
              />
            </div>

            <div
              className={classNames({
                'avs-form-registration-city': true
              })}
            >
              <Field
                type='text'
                name='profile.city'
                label={t('label.userProfile.attribute.city')}
                autocomplete={cities}
                validate={[maxLength255, required]}
                component={Input}
                isErrorVisible={formAsyncErrors?.profile?.city ? true : false}
              />
            </div>

            <div
              className={classNames({
                'avs-form-registration-street': true
              })}
            >
              <Field
                type='text'
                name='profile.street'
                label={t('label.userProfile.attribute.street')}
                validate={[maxLength255, required]}
                component={Input}
                isErrorVisible={formAsyncErrors?.profile?.street ? true : false}
              />
            </div>

            <div
              className={classNames({
                'avs-form-registration-houseNumber': true
              })}
            >
              <Field
                type='text'
                name='profile.houseNumber'
                label={t('label.userProfile.attribute.houseNumber')}
                validate={[maxLength255, required]}
                component={Input}
                isErrorVisible={formAsyncErrors?.profile?.houseNumber ? true : false}
              />
            </div>
          </div>
        </>
      </div>

      {!isProvinceAllowed && (
        <div className='avs-registerForm-service-response'>
          <ul className='avs-service-explanation-message'>
            <li
              className='avs-message-province-not-permitted'
              dangerouslySetInnerHTML={{
                __html: sanitize(t('label.registrationForm.permitted.province'))
              }}
            ></li>
          </ul>
        </div>
      )}

      <div className='avs-form-group'>
        <button
          className='avs-btn avs-btn-primary avs-large'
          disabled={invalid || submitting || !isProvinceAllowed || !province}
        >
          {t('label.registrationForm.button.register')}
        </button>
      </div>

      <div className='avs-container'>
        <div className='avs-form-group avs-text-center'>
          <p className='avs-text'>
            {t('label.registerForm.haveAccount')}{' '}
            {loginRedirectUrl && (
              <button
                className='avs-link'
                dangerouslySetInnerHTML={{
                  __html: sanitize(t('label.registerForm.login.link'))
                }}
              />
            )}
            {!loginRedirectUrl && (
              <Link className='avs-link' to='/login'>
                {t('label.registerForm.login')}
              </Link>
            )}
          </p>
        </div>
      </div>
    </form>
  )
}

InsicPrecheckRegister.propTypes = {
  user: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  getPermittedProvincesParam: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  change: PropTypes.func.isRequired,
  startAsyncValidation: PropTypes.func,
  getLoqate: PropTypes.func.isRequired,
  resetLoqate: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  clearAsyncError: PropTypes.func.isRequired,
  clearInvalidEmails: PropTypes.func.isRequired,
  invalidEmails: PropTypes.arrayOf(PropTypes.string),
  formAsyncErrors: PropTypes.object,
  fieldValues: PropTypes.object,
  province: PropTypes.string,
  cities: PropTypes.arrayOf(PropTypes.object),
  loqateItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string
    })
  ),
  invalid: PropTypes.bool,
  passwordValue: PropTypes.string,
  isBicVisible: PropTypes.bool,
  submitting: PropTypes.bool,
  agreement: PropTypes.bool,
  permittedProvinces: PropTypes.arrayOf(PropTypes.string),
  city: PropTypes.string,
  zip: PropTypes.string,
  placeOfBirth: PropTypes.string
}

const InsicPrecheckRegisterForm = reduxForm({
  form: 'registration',
  destroyOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  asyncValidate: asyncEmailAndUserValidate,
  shouldAsyncValidate: shouldAsyncValidate,
  asyncBlurFields: [
    'email',
    'password',
    'profile.userName',
    'profile.firstName',
    'profile.lastName',
    'profile.maidenName',
    'profile.birthday',
    'profile.zip',
    'profile.city',
    'profile.street',
    'profile.houseNumber',
    'profile.bic',
    'profile.iban',
    'profile.placeOfBirth'
  ],
  asyncChangeFields: [
    'profile.agreement1',
    'profile.agreement2',
    'profile.agreement3',
    'profile.agreement4',
    'profile.city'
  ]
})(InsicPrecheckRegister)

const selector = formValueSelector('registration')

export default connect(
  state => ({
    user: state.userState.user,
    permittedProvinces: state.partnerState.permittedProvinces,
    formAsyncErrors: getFormAsyncErrors('registration')(state),
    passwordValue: selector(state, 'password'),
    agreement: selector(state, 'profile.agreement3'),
    city: selector(state, 'profile.city'),
    placeOfBirth: selector(state, 'profile.placeOfBirth'),
    zip: selector(state, 'profile.zip')
  }),
  dispatch => bindActionCreators(partnerActions, dispatch)
)(InsicPrecheckRegisterForm)
