import types from 'redux/types/template'

const initialState = {
  map: null,
  errorMessage: null,
  errorResponse: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TEMPLATES_DONE:
      return {
        ...state,
        map: action.payload.map
      }

    case types.TEMPLATES_ERROR:
      return {
        ...state,
        errorMessage: action.payload.message,
        errorResponse: action.payload.response
      }

    default:
      return state
  }
}
