import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import pikaday from 'pikaday'

import { classNames, formatDate, parseDate } from 'helpers'
import t from 'services/t'

import 'styles/autocomplete.css'
import 'styles/file-input.css'
import 'styles/pikaday.css'

const { registrationType, lang } = window.__AVS_CONFIG__

const de = {
  weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
  ]
}

const sv = {
  weekdays: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
  weekdaysShort: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
  months: [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December'
  ]
}

const nl = {
  weekdays: ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'],
  weekdaysShort: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
  months: [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December'
  ]
}

export const Input = ({
  autofocus,
  input,
  yearRange,
  autocomplete,
  autocompleteCallback,
  preventPaste,
  type = 'text',
  label,
  placeholder,
  isAutocompleteOpen,
  showPassword,
  accept,
  isErrorVisible,
  meta: { active, touched, error, warning },
  className = ''
}) => {
  const ref = useRef(null)
  const containerRef = useRef(null)
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const focus = () => ref.current.focus()

  const initDatepicker = () => {
    const currentYear = new Date().getFullYear()
    const defaultDate = input.value ? parseDate(input.value) : new Date('1975-01-01')
    if (defaultDate.getTimezoneOffset() > 0) {
      // fix pikaday issue with -GMT
      defaultDate.setDate(defaultDate.getDate() + 1)
    }
    const options = {
      field: ref.current,
      container: containerRef.current,
      yearRange: yearRange || [currentYear - 118, currentYear - 18],
      defaultDate,
      setDefaultDate: !!input.value,
      firstDay: 1,
      parse(dateString) {
        return parseDate(dateString)
      },
      toString(date) {
        return formatDate(date)
      },
      onSelect(date) {
        if (date instanceof Date) {
          input.onChange(formatDate(date))
        } else {
          input.onChange(date)
        }
      }
    }
    if (input.name === 'createdAtFrom' || input.name === 'createdAtTo') {
      options.defaultDate = new Date('2022-01-01')
    }
    if (lang === 'de') {
      options.i18n = de
    } else if (lang === 'sv') {
      options.i18n = sv
    } else if (lang === 'nl') {
      options.i18n = nl
    }
    new pikaday(options)
  }

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  const onFileChange = e => {
    input.onChange(e.target.files[0])
  }

  const onBlur = e => {
    // add timeout to fire click on autocomplete
    if (autocomplete) {
      e.persist()
      setTimeout(() => input.onBlur(), 200)
    } else {
      input.onBlur(e)
    }
  }

  const onPaste = e => {
    if (preventPaste) e.preventDefault()
  }

  const onAutocompleteClick = item => {
    if (autocompleteCallback) {
      autocompleteCallback(item, input)
    } else {
      input.onChange(item.text)
    }
  }

  useEffect(() => {
    if (type === 'date') initDatepicker()
    if (autofocus) focus()
  }, [])

  if (type === 'file') {
    const filename = input.value && input.value.name

    delete input.value
    return (
      <>
        <div className='avs-form-group'>
          <div className='avs-file-input-container avs-button-container'>
            <div className='avs-btn avs-btn-default avs-browse-button'>
              <span>{t('label.fileInput.button.browse')}</span>
              <input onChange={onFileChange} type='file' accept={accept} />
            </div>
            <p className='avs-file-name'>{filename || label}</p>
          </div>
        </div>
        {error && (
          <div className='avs-errors file-error-message'>
            <p className='avs-form-error-message'>{error}</p>
          </div>
        )}
      </>
    )
  }

  const currentAutocompleteItem = autocomplete?.find(item => item.text === input.value)

  return (
    <div className='avs-form-group'>
      <fieldset>
        {autocomplete && (
          <div className='avs-input-description'>
            <span className='avs-input-description-transparent'>{currentAutocompleteItem?.text}</span>{' '}
            <span>{currentAutocompleteItem?.description}</span>
          </div>
        )}
        <input
          {...input}
          autoComplete={autocomplete || type === 'date' ? 'off' : null}
          onBlur={onBlur}
          onPaste={onPaste}
          ref={ref}
          type={isPasswordVisible || type === 'date' ? 'text' : type}
          placeholder={placeholder}
          className={`${classNames({
            'avs-custom-input': true,
            'avs-invalid': (touched || isErrorVisible) && error,
            'avs-not-empty': input.value || input.value === false || input.value === 0
          })} ${className}`}
        />
        {showPassword &&
          type === 'password' &&
          (registrationType === 'insicPrecheck' ? (
            <i className='avs-material-icons avs-input-icon' onClick={togglePasswordVisibility}>
              {isPasswordVisible ? (
                <i className='avs-input-icon-close'>off</i>
              ) : (
                <i className='avs-input-icon-open'>on</i>
              )}
            </i>
          ) : (
            <i className='avs-material-icons avs-input-icon' onClick={togglePasswordVisibility}>
              {isPasswordVisible ? 'visibility_off' : 'visibility'}
            </i>
          ))}
        <hr />
        <label>{label}</label>
        {autocomplete && (
          <div
            className={classNames({
              'avs-custom-autocomplete': true,
              'avs-open': isAutocompleteOpen || active
            })}
          >
            <ul className='avs-custom-autocomplete-list'>
              {autocomplete.map((item, i) => (
                <li key={i} onClick={() => onAutocompleteClick(item)}>
                  {item.text} <span className='avs-list-description'>{item.description}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className='pikaday-container' ref={containerRef}></div>
      </fieldset>
      <div className='avs-errors'>
        {(touched || isErrorVisible) && error && <p className='avs-form-error-message'>{error}</p>}
        {!error && warning && <p className='avs-form-warning-message'>{warning}</p>}
      </div>
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  autofocus: PropTypes.bool,
  preventPaste: PropTypes.bool,
  accept: PropTypes.string,
  addCity: PropTypes.func,
  autocomplete: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      source: PropTypes.string
    })
  ), // list of autocomplete
  isAutocompleteOpen: PropTypes.bool,
  autocompleteCallback: PropTypes.func,
  showPassword: PropTypes.bool, // 'show password' icon
  isErrorVisible: PropTypes.bool,
  className: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
    active: PropTypes.bool
  }),
  yearRange: PropTypes.arrayOf(PropTypes.number)
}

export default Input
