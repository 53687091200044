import React from 'react'
import PropTypes from 'prop-types'
import SocialLogin from 'react-social-login'

const SocialButton = ({ children, triggerLogin, ...props }) => (
  <button type='button' onClick={triggerLogin} {...props}>
    {children}
  </button>
)

SocialButton.propTypes = {
  children: PropTypes.node.isRequired,
  triggerLogin: PropTypes.func.isRequired
}

export default SocialLogin(SocialButton)
