import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'

import t from 'services/t'
import Checkbox from 'components/Checkbox'
import { validateAgreement } from 'services/validation'

export const AnonymousRegisterForm = props => {
  const { handleSubmit, invalid, submitting } = props

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className='avs-form-group'>
        <legend className='avs-form-title'>{t('label.registrationForm.header')}</legend>
      </div>

      <Field
        type='checkbox'
        name='profile.agreement1'
        label={t('label.userProfile.attribute.agreement1') + '*'}
        validate={[validateAgreement]}
        component={Checkbox}
      />

      {t('label.userProfile.attribute.agreement2') && (
        <Field
          type='checkbox'
          name='profile.agreement2'
          label={t('label.userProfile.attribute.agreement2')}
          component={Checkbox}
        />
      )}

      {t('label.userProfile.attribute.agreement3') && (
        <Field
          type='checkbox'
          name='profile.agreement3'
          label={t('label.userProfile.attribute.agreement3')}
          component={Checkbox}
        />
      )}

      {t('label.userProfile.attribute.agreement4') && (
        <Field
          type='checkbox'
          name='profile.agreement4'
          label={t('label.userProfile.attribute.agreement4')}
          component={Checkbox}
        />
      )}

      <div className='avs-form-group'>
        <button
          className='avs-btn avs-btn-primary avs-large'
          type='submit'
          disabled={invalid || submitting}
        >
          {t('label.registrationForm.button.register')}
        </button>
      </div>

      <div className='avs-container'>
        <div className='avs-form-group avs-text-center'>
          <p className='avs-text'>
            {t('label.registerForm.haveAccount')}{' '}
            <Link className='avs-link' to='/login'>
              {t('label.registerForm.login')}
            </Link>
          </p>
        </div>
      </div>
    </form>
  )
}

AnonymousRegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  isBicVisible: PropTypes.bool
}

export default reduxForm({
  form: 'registration',
  asyncChangeFields: [
    'profile.agreement1',
    'profile.agreement2',
    'profile.agreement3',
    'profile.agreement4'
  ]
})(AnonymousRegisterForm)
