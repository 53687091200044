import t from 'services/t'
import { get, set, prepareUserData, preprocessFormData, parseDate } from 'helpers'
import UserApi from 'services/UserApi'
import PartnerApi from 'services/PartnerApi'

const isEmpty = value => ['', null, undefined].includes(value)

const serviceValidate = (validate, values, props, field, omitEmptyFields = true, emailValidation = false) => {
  return validate(values).then(errors => {
    if (!errors || !errors.length) return undefined
    // get blured field error if value is not empty
    const fieldError = errors
      .filter(e => e.path === field && (!isEmpty(get(values, field)) || !omitEmptyFields))
      .map(e => set({}, e.path, e.message))[0]

    let isWarning = false
    if (emailValidation) {
      const emailError = get(fieldError, 'email')
      if (emailError && emailError !== t('error.email.violates.unique')) {
        isWarning = errors.filter(e => e.path === 'email')[0].isWarning
        if (isWarning) {
          props.addInvalidEmail({ email: values.email, warning: emailError })
        } else {
          props.addInvalidEmail({ email: values.email, error: emailError })
        }
      }
    }
    if (fieldError && !isWarning) throw fieldError

    // get any error for registered field with not empty value
    const anyError = errors
      .filter(e => props.registeredFields[e.path] && (!isEmpty(get(values, e.path)) || !omitEmptyFields))
      .map(e => set({}, e.path, e.message))[0]

    if (anyError && !isWarning) throw anyError

    if (emailValidation) {
      const invalidEmail = props.invalidEmails.find(i => i.email === values.email && i.error)
      if (invalidEmail) {
        const emailError = { email: invalidEmail.error }
        throw emailError
      }
    }
  })
}

export const asyncUserValidate = (values, _, props, field) => {
  return serviceValidate(UserApi.validate.bind(UserApi), prepareUserData(values), props, field)
}

export const asyncEmailAndUserValidate = (values, _, props, field) => {
  const validate = field === 'email' ? UserApi.validateEmail : UserApi.validate
  return serviceValidate(validate.bind(UserApi), prepareUserData(values), props, field, true, true)
}

export const asyncPasswordValidate = (values, _, props, field) => {
  return serviceValidate(UserApi.validateCurrentPassword.bind(UserApi), prepareUserData(values), props, field)
}

export const asyncSafeValidate = (values, _, props, field) => {
  return serviceValidate(
    PartnerApi.validateSafeRecord.bind(PartnerApi, props.tableName),
    preprocessFormData(values, props.columns),
    props,
    field,
    false
  )
}

export const asyncSafe2Validate = (values, _, props, field) => {
  return serviceValidate(
    PartnerApi.validateSafe2Record.bind(PartnerApi, props.tableName),
    preprocessFormData(values, props.columns),
    props,
    field,
    false
  )
}

export const asyncOasisCredentialsValidate = (values, _, props, field) => {
  return serviceValidate(
    PartnerApi.validateOasisCredentialsRecord.bind(PartnerApi),
    preprocessFormData(values, props.columns),
    props,
    field,
    false
  )
}

export const required = value => (isEmpty(value) ? t('error.violates.any.required') : undefined)

export const validateAgreement = (value, _, props, name) =>
  !value ? t(`error.${name}.violates.any.required`) : undefined

export const maxLength255 = value =>
  value && value.length > 255 ? t('error.violates.string.max', { length: 255 }) : undefined

export const maxLength30 = value =>
  value && value.length > 30 ? t('error.violates.string.max', { length: 30 }) : undefined

export const maxLength10 = value =>
  value && value.length > 10 ? t('error.violates.string.max', { length: 10 }) : undefined

export const number = value => (value && Number.isNaN(Number(value)) ? t('error.violates.number.base') : undefined)

export const numberOrSpace = value => (value && !/^[0-9 ]+$/.test(value) ? t('error.violates.number.base') : undefined)

export const expirationDate = value =>
  value && !/^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(value) ? t('error.violates.expiration.date') : undefined

export const email = value =>
  value && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)
    ? t('error.email.violates.string.regex.base')
    : undefined

export const password = value =>
  value && !/^(?=.*[A-Za-z])[A-Za-z\\d!@#$%^&*-_=+<>§()?]{6,255}$/.test(value)
    ? t('error.password.violates.string.pattern.base')
    : undefined

export const passwordMin8 = value =>
  value && !/^(?=.*[A-Za-z])[A-Za-z\\d!@#$%^&*-_=+<>§()?]{8,255}$/.test(value)
    ? t('error.password.violates.string.pattern.base.min8')
    : undefined

export const passwordCheck = value =>
  value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+<>§(\-)?])(?=.{8,})/.test(value)
    ? t('error.password.violates.string.pattern.precheck')
    : undefined

export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? t('error.phoneNumber.violates.string.base') : undefined

export const date = value =>
  value && Number.isNaN(+new Date(value)) && Number.isNaN(+parseDate(value))
    ? t('error.date.violates.invalid')
    : undefined

export const isEmail2Equal = (email2value, _, props) =>
  !isEmpty(email2value) && email2value === props.emailValue ? undefined : t('error.email2.equal')

export const isPasswordConfirmEqual = (passwordConfirmValue, _, props) =>
  !isEmpty(passwordConfirmValue) && passwordConfirmValue === props.passwordValue
    ? undefined
    : t('error.confirmPassword.violates.equals')

export const datetime = value =>
  value && !/^\d{4}-\d{2}-\d{2} \d{2}.\d{2}.\d{2}$/i.test(value) ? t('error.datetime.violates.invalid') : undefined

export const processStepsValidation = value =>
  value && !/^\s*\d+\s*,\s*\d+\s*$/.test(value) ? 'Current step coma all steps (1,2)' : undefined

export const json = value => {
  try {
    if (!value) return undefined
    JSON.parse(value)
  } catch (e) {
    return e.toString()
  }
}
