import React from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'

import t from 'services/t'
import Input from 'components/Input'
import { email, password, number, required } from 'services/validation'

export const LoginForm = ({
  handleSubmit, invalid, submitting, errors = [], activation_barcode,
  submitSucceeded, submitFailed, confirmation, authRequiredFeilds, authMessage
}) => (
  <form noValidate onSubmit={handleSubmit}>
    <div className='avs-form-group'>
      <legend className='avs-form-title'>{t('label.loginForm.header')}</legend>
    </div>

    {(submitSucceeded || submitFailed) && <div className='avs-form-group'>
      {errors.map((e, i) =>
        <p key={i} className='avs-form-error-message'>{e.message || e.data.message}</p>
      )}
    </div>}

    {confirmation && <div className='avs-form-group'>
      <p>{t('message.info.duo.confirmation')}</p>
    </div>}

    {authMessage && <div className='avs-form-group'>
      <p>{authMessage}</p>
    </div>}

    {(!activation_barcode && !authRequiredFeilds) && <div>
      <Field
        name='email'
        type='email'
        label={t('label.user.attribute.email')}
        validate={[email, required]}
        component={Input}
      />

      <Field
        name='password'
        type='password'
        label={t('label.loginForm.input.password')}
        validate={[password, required]}
        component={Input}
      />
    </div>}

    {activation_barcode && <div className='avs-duo-activation'>
      <img src={activation_barcode} alt='activation code' />

      <Field
        name='activation_code'
        type='password'
        label={t('label.userDashboard.input.code')}
        validate={[number, required]}
        component={Input}
      />
    </div>}

    {authRequiredFeilds && authRequiredFeilds.includes('code') && <div>
      <Field
        name='code'
        label={t('label.userDashboard.input.code')}
        validate={[number, required]}
        component={Input}
      />
    </div>}

    <div className='avs-form-group'>
      <button type='submit' className='avs-btn avs-btn-primary avs-large' disabled={invalid || submitting}>
        {t('label.loginForm.button.login')}
      </button>
    </div>

    <div className='avs-container'>
      <div className='avs-form-group avs-text-center'>
        <p className='avs-text'>
          <Link className='avs-link' to='/forgot-password'>
            {t('label.loginForm.forgotPassword')}
          </Link>
        </p>
      </div>
    </div>

    <div className='avs-container'>
      <div className='avs-form-group avs-text-center'>
        <p className='avs-text'>
          {t('label.loginForm.dont.have.account')}
          {' '}
          <Link className='avs-link' to='/registration'>
            {t('label.loginForm.register')}
          </Link>
        </p>
      </div>
    </div>
  </form>
)

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string
  })),
  activation_barcode: PropTypes.string,
  submitSucceeded:  PropTypes.bool,
  submitFailed: PropTypes.bool,
  confirmation: PropTypes.bool,
  authRequiredFeilds: PropTypes.array,
  authMessage: PropTypes.string
}

export default reduxForm({
  form: 'login'
})(LoginForm)
