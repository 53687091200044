import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Router } from 'react-router-dom'

import history from 'services/history'
import Login from 'containers/Login'
import Registration from 'containers/Registration'
import auth from 'services/auth'

import 'styles/reset.css'
import 'styles/base.css'
import 'styles/grid.css'
import 'styles/forms.css'
import 'styles/layout.css'
import 'styles/header.css'
import 'styles/buttons.css'
import 'styles/material.css'
import 'styles/utilities.css'
import 'styles/typography.css'
import 'styles/service-params-form.css'
import 'styles/user-form.css'
import 'styles/tabs.css'
import 'styles/safe.css'
import 'styles/engine-config.css'
import 'styles/modal.css'
import VerificationOverview from './frontend3/VerificationOverview'
import VerificationDetail from './frontend3/VerificationDetail'
import VerificationSuccess from './frontend3/VerificationSuccess'
import ForgotPassword from './containers/ForgotPassword'

const {
  __AVS_CONFIG__: { startRoute }
} = window
const redirectPath = startRoute === '/registration' ? '/registration' : '/login'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.token) return <Component {...props} />
      return <Redirect to={redirectPath} />
    }}
  />
)

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired
}

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.token) return <Redirect to='/verificationOverview' />
      return <Component {...props} />
    }}
  />
)

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired
}

const App = () => (
  <Router history={history}>
    <div className='avs-material'>
      <Route
        exact
        path='/'
        render={() => {

          //widget is getting started, hide register step by default
          window.localStorage.setItem('showRegisterStep', 'false')
          window.localStorage.setItem('redirectToStartedProcess', 'true')
          return <Redirect to={redirectPath} />
        }}
      />

      <PublicRoute exact path='/login' component={Login} />
      <PublicRoute exact path='/registration' component={Registration} />
      <PublicRoute exact path='/forgot-password' component={ForgotPassword} />
      {/*many pushes refer to the dashboard page, we keep it*/}
      <PrivateRoute exact path='/dashboard' component={VerificationOverview} />
      <PrivateRoute exact path='/verificationOverview' component={VerificationOverview} />
      <PrivateRoute exact path='/verificationDetail' component={VerificationDetail} />
      <PrivateRoute exact path='/verificationSuccess' component={VerificationSuccess} />
    </div>
  </Router>
)

export default App
