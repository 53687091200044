import * as types from 'redux/types/partner'
import PartnerApi from 'services/PartnerApi'

export const getPartners = (sortBy, sortOrder, limit, offset, filter) => ({
  types: [types.GET_PARTNERS, types.GET_PARTNERS_DONE, types.PARTNER_ERROR],
  payload: {
    response: PartnerApi.getPartners(sortBy, sortOrder, limit, offset, filter),
    pageSize: limit
  }
})

export const clearUsers = partnerId => ({
  types: [types.CLEAR_USERS, types.CLEAR_USERS_DONE, types.PARTNER_ERROR],
  payload: {
    response: PartnerApi.clearUsers(partnerId)
  }
})

export const getPartner = partnerId => ({
  types: [types.GET_PARTNER, types.GET_PARTNER_DONE, types.PARTNER_ERROR],
  payload: {
    partner: PartnerApi.getPartner(partnerId)
  }
})

export const clearPartner = () => ({
  type: types.CLEAR_PARTNER
})

export const getServiceParams = partnerId => ({
  types: [types.GET_SERVICE_PARAMS, types.GET_SERVICE_PARAMS_DONE, types.PARTNER_ERROR],
  payload: {
    response: PartnerApi.getServiceParams(partnerId)
  }
})

export const getPermittedProvincesParam = partnerId => ({
  types: [types.GET_PROVINCE_PARAM, types.GET_PROVINCE_PARAM_DONE, types.PARTNER_ERROR],
  payload: {
    response: PartnerApi.getPermittedProvincesParam(partnerId)
  }
})

export const getServiceParamsSchema = () => ({
  types: [
    types.GET_SERVICE_PARAMS_SCHEMA,
    types.GET_SERVICE_PARAMS_SCHEMA_DONE,
    types.PARTNER_ERROR
  ],
  payload: {
    response: PartnerApi.getServiceParamsSchema()
  }
})

export const startServiceEditting = serviceId => ({
  type: types.START_SERVICE_EDITTING,
  payload: { serviceId }
})

export const cancelServiceEditting = () => ({
  type: types.CANCEL_SERVICE_EDITTING
})

export const serviceParamsUpdate = (partnerId, data) => ({
  types: [
    types.UPDATE_SERVICE_PARAMS,
    types.UPDATE_SERVICE_PARAMS_DONE,
    types.PARTNER_ERROR
  ],
  payload: {
    response: PartnerApi.serviceParamsUpdate(partnerId, data),
    data
  }
})

export const getTemplates = (limit, offset, filter) => ({
  types: [
    types.GET_PARTNER_TEMPLATES,
    types.GET_PARTNER_TEMPLATES_DONE,
    types.PARTNER_ERROR
  ],
  payload: {
    templates: PartnerApi.getTemplates(limit, offset, filter),
    pageSize: limit
  }
})

export const getRetentionTemplates = () => ({
  types: [
    types.GET_RETENTION_TEMPLATES,
    types.GET_RETENTION_TEMPLATES_DONE,
    types.PARTNER_ERROR
  ],
  payload: {
    templates: PartnerApi.getRetentionTemplates()
  }
})

export const getMessageTypes = (sortBy, sortOrder, limit, offset) => ({
  types: [types.GET_MESSAGE_TYPES, types.GET_MESSAGE_TYPES_DONE, types.PARTNER_ERROR],
  payload: {
    messageTypes: PartnerApi.getMessageTypes(sortBy, sortOrder, limit, offset),
    pageSize: limit
  }
})

export const getUserMessageTypeOptions = (templateKey) => ({
  types: [
    types.GET_USER_MESSAGE_TYPE_OPTIONS,
    types.GET_USER_MESSAGE_TYPE_OPTIONS_DONE,
    types.PARTNER_ERROR
  ],
  payload: {
    messageTypeOptions: PartnerApi.getUserMessageTypeOptions(templateKey)
  }
})

export const messageTypePartnerIdChange = (partnerId, templateKey) => ({
  type: types.MESSAGE_TYPE_PARTNER_ID_CHANGE,
  payload: { partnerId, templateKey }
})

export const getBets = (partnerId, sortBy, sortOrder, limit, offset, filter) => ({
  types: [types.GET_BETS, types.GET_BETS_DONE, types.PARTNER_ERROR],
  payload: {
    response: PartnerApi.getSafeData(
      'bet', partnerId, sortBy, sortOrder, limit, offset, filter
    ),
    pageSize: limit
  }
})

export const getPays = (partnerId, sortBy, sortOrder, limit, offset, filter) => ({
  types: [types.GET_PAYS, types.GET_PAYS_DONE, types.PARTNER_ERROR],
  payload: {
    response: PartnerApi.getSafeData(
      'pay', partnerId, sortBy, sortOrder, limit, offset, filter
    ),
    pageSize: limit
  }
})

export const getEods = (partnerId, sortBy, sortOrder, limit, offset, filter) => ({
  types: [types.GET_EODS, types.GET_EODS_DONE, types.PARTNER_ERROR],
  payload: {
    response: PartnerApi.getSafeData(
      'eod', partnerId, sortBy, sortOrder, limit, offset, filter
    ),
    pageSize: limit
  }
})

export const getTokens = (partnerId, sortBy, sortOrder, limit, offset, filter) => ({
  types: [types.GET_TOKENS, types.GET_TOKENS_DONE, types.PARTNER_ERROR],
  payload: {
    response: PartnerApi.getSafeData(
      'token', partnerId, sortBy, sortOrder, limit, offset, filter
    ),
    pageSize: limit
  }
})

export const getWarnings = (partnerId, sortBy, sortOrder, limit, offset, filter) => ({
  types: [types.GET_WARNING, types.GET_WARNING_DONE, types.PARTNER_ERROR],
  payload: {
    response: PartnerApi.getSafeData(
      'warning', partnerId, sortBy, sortOrder, limit, offset, filter
    ),
    pageSize: limit
  }
})

export const getOasisCredentials = (partnerId, sortBy, sortOrder, limit, offset) => ({
  types: [types.GET_OASIS_CREDENTIALS, types.GET_OASIS_CREDENTIALS_DONE, types.PARTNER_ERROR],
  payload: {
    response: PartnerApi.getOasisCredentials(
      partnerId, sortBy, sortOrder, limit, offset
    ),
    pageSize: limit
  }
})

export const getFreeLimit = partnerId => ({
  types: [types.GET_FREE_LIMIT, types.GET_FREE_LIMIT_DONE, types.PARTNER_ERROR],
  payload: {
    response: PartnerApi.getFreeLimit(partnerId)
  }
})

export const clearFreeLimit = () => ({
  type: types.CLEAR_FREE_LIMIT
})
