import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import ResetPasswordForm from 'components/ResetPasswordForm'
import * as userActions from 'redux/actions/user'

export const ForgotPassword = ({ resetPassword, errorResponse, resetPasswordSuccess }) => {
  return (
    <div className='avs-page avs-page-user'>
      <ResetPasswordForm
        onSubmit={resetPassword}
        errorResponse={errorResponse}
        resetPasswordSuccess={resetPasswordSuccess}
      />
    </div>
  )
}

ForgotPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  errorResponse: PropTypes.array,
  resetPasswordSuccess: PropTypes.bool
}

export default withRouter(
  connect(
    state => ({
      errorResponse: state.userState.errorResponse,
      resetPasswordSuccess: state.userState.resetPasswordSuccess
    }),
    dispatch => bindActionCreators(userActions, dispatch)
  )(ForgotPassword)
)
