import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Spinner from 'components/Spinner'
import InsicPrecheckRegisterForm from 'components/registration/insicPrecheck/InsicPrecheckRegisterForm'
import t from 'services/t'
import { getNationality } from 'nationalities-list'

import history from 'services/history'
import { debounce, prepareUserData, get, formatDate } from 'helpers'
import * as userActions from 'redux/actions/user'

const { loginCallback, customData, userPrefillValues, lang } = window.__AVS_CONFIG__

export const InsicPrecheckForm = props => {
  const {
    getLoqate,
    getProvince,
    isBicVisible,
    addressValue,
    zipValue,
    user,
    province,
    registerInsicPrecheck,
    parsedToken,
    loqateItems,
    resetLoqate,
    cities,
    clearInvalidEmails,
    emailValue,
    errors,
    invalidEmails,
    addInvalidEmail,
    initialValues,
    userPass
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const onGetLoqate = useCallback(debounce(getLoqate, 500), [])
  const onZipChanged = useCallback(debounce(getProvince, 700, true), [])

  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    if (parsedToken && user) {
      history.push('/dashboard')
    }
    return () => {
      mounted.current = false
    }
  }, [])

  useEffect(() => {
    if (addressValue) onGetLoqate(addressValue)
  }, [addressValue])

  useEffect(() => {
    if (zipValue && zipValue.trim().length) {
      onZipChanged(zipValue)
    }
  }, [zipValue, getProvince])

  const register = async userData => {
    const data = prepareUserData(userData)
    data.customUserData = customData
    data.profile.province = province
    mounted.current && setIsLoading(true)
    await registerInsicPrecheck(data)
    return await login(userData)
  }

  const login = async ({ email, password }) => {
    mounted.current && setIsLoading(true)

    let result = await props.login({ email, password })

    if (get(result, 'payload.response.confirmation')) {
      result = await props.login({ email, password, confirmation: true })
    }
    if (loginCallback && window[loginCallback]) {
      window[loginCallback](email, password)
    }
    if (result.error) {
      mounted.current && setIsLoading(false)
    } else {
      history.push('/dashboard')
    }
  }

  const userInitialValues = () => {
    user && delete user.smsAuth

    return (
      user && {
        ...user,
        password: userPass,
        profile: {
          ...user.profile,
          birthday: formatDate(new Date(user.profile.birthday))
        }
      }
    )
  }

  userPrefillValues && Object.assign(initialValues, userPrefillValues)
  const mergedInitialValues = user
    ? userInitialValues()
    : {
        ...initialValues,
        email: initialValues.email ? initialValues.email : emailValue,
        password: userPass,
        profile: {
          ...(initialValues && initialValues.profile),
          greeting: t('label.greeting.male'),
          nationality: getNationality(89, lang)
        }
      }

  return (
    <div className='avs-material avs-form-with-spinner'>
      <div className='avs-empty-header'></div>

      <div className='avs-form-group'>
        {errors.map((e, i) => (
          <p key={i} className='avs-form-error-message'>
            {e.message}
          </p>
        ))}
      </div>
      <InsicPrecheckRegisterForm
        initialValues={mergedInitialValues}
        loqateItems={loqateItems}
        cities={cities}
        province={province}
        getLoqate={getLoqate}
        resetLoqate={resetLoqate}
        onSubmit={register}
        invalidEmails={invalidEmails}
        addInvalidEmail={addInvalidEmail}
        isBicVisible={isBicVisible}
        clearInvalidEmails={clearInvalidEmails}
      />
      {isLoading && <Spinner />}
    </div>
  )
}

InsicPrecheckForm.propTypes = {
  user: PropTypes.object,
  userUpdate: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  ibanValue: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  registerInsicPrecheck: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  checkEmail: PropTypes.func.isRequired,
  clearInvalidEmails: PropTypes.func.isRequired,
  getLoqate: PropTypes.func.isRequired,
  getProvince: PropTypes.func.isRequired,
  resetLoqate: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  loqateItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      description: PropTypes.string
    })
  ),
  isEmailExists: PropTypes.bool,
  addressValue: PropTypes.string,
  emailValue: PropTypes.string,
  greeting: PropTypes.string,
  zipValue: PropTypes.string,
  userPass: PropTypes.string,
  province: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.object),
  cities: PropTypes.arrayOf(PropTypes.object),
  invalidEmails: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      error: PropTypes.string
    })
  ),
  parsedToken: PropTypes.shape({
    userId: PropTypes.string.isRequired
  }),
  addInvalidEmail: PropTypes.func.isRequired,
  confirmation: PropTypes.bool,
  isBicVisible: PropTypes.bool
}

const selector = formValueSelector('registration')

export default connect(
  state => ({
    user: state.userState.user,
    province: state.userState.province,
    parsedToken: state.userState.parsedToken,
    zipValue: selector(state, 'profile.zip'),
    ibanValue: selector(state, 'profile.iban'),
    addressValue: selector(state, 'profile.address'),
    emailValue: selector(state, 'email'),
    userPass: selector(state, 'password'),
    greeting: selector(state, 'profile.greeting'),
    loqateItems: state.userState.loqateItems,
    isEmailExists: state.userState.isEmailExists,
    invalidEmails: state.userState.invalidEmails,
    confirmation: state.userState.confirmation
  }),
  dispatch => bindActionCreators(userActions, dispatch)
)(InsicPrecheckForm)
