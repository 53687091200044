import React from 'react'
import * as PropTypes from 'prop-types'
import { Box, Card, CardContent, Typography, withStyles } from '@material-ui/core'
import t from '../services/t'
import SecondaryStepper from './SecondaryStepper'
import {
  getActiveService,
  getActiveStep,
  getNumberOfSteps,
  getProcessDurationLabelId,
  getProcessExplanationLabelId,
  getProcessImageLabelId,
  getServiceStatusLabelId,
  isProcessInactive,
  isProcessPrimary
} from './processHelper'
import { sanitize } from 'helpers'

const styles = {
  root: {
    width: '100%',
    height: '100%',
    cursor: 'pointer'
  },
  primary: {
    backgroundColor: '#3f51b5',
    color: 'white'
  },
  inactive: {
    backgroundColor: '#f6f6f6',
    color: 'grey',
    cursor: 'auto'
  }
}

const VerificationOverviewTile = props => {
  const { classes, processLabelId, process } = props
  let service = getActiveService(process)
  if (service === undefined && isProcessInactive(process)) {
    service = process.services[0]
  }
  if (service === undefined) {
    return (
      <div>
        <div>{t('label.user.dashboard.process.activeServiceNotFound')}</div>
      </div>
    )
  }
  
  let className = classes.root
  if (isProcessPrimary(process)) {
    className += ' avs-process-primary ' + classes.primary
  } else if (isProcessInactive(process)) {
    className += ' avs-process-inactive ' + classes.inactive
  } else {
    className += ' avs-process-active'
  }

  const htmlId = 'avs_' + service.name.replace(/\./, '_')
  const textClassName = isProcessPrimary(process) ? { root: 'text-primary' } : { root: 'text-default' }

  return (
    <Card id={htmlId} variant='outlined' className={className}>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
        <CardContent style={{ height: '100%' }}>
          <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
            <Box width='100%' display={'flex'} flexDirection={'row'} alignItems='flex-start'>
              <Box width={10 / 12}>
                <Typography variant='h5' classes={textClassName}>
                  <Box fontWeight='fontWeightBold'>{t(processLabelId)}</Box>
                </Typography>
              </Box>
              <Box
                width={2 / 12}
                display={'flex'}
                flexDirection={'row'}
                justifyContent='center'
                dangerouslySetInnerHTML={{
                  __html: sanitize(t(getProcessDurationLabelId(processLabelId)))
                }}
              ></Box>
            </Box>
            <div>
              <Box
                width='100%'
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                my={2}
                alignItems='center'
              >
                <Box width={10 / 12}>
                  <Typography variant='subtitle2' classes={textClassName}>
                    {t(getProcessExplanationLabelId(processLabelId))}
                  </Typography>
                </Box>
                <Box
                  width={2 / 12}
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent='center'
                  dangerouslySetInnerHTML={{
                    __html: sanitize(t(getProcessImageLabelId(processLabelId)))
                  }}
                ></Box>
              </Box>
              <Typography variant='subtitle1' classes={textClassName}>
                {t(getServiceStatusLabelId(service))}
              </Typography>
            </div>
          </Box>
        </CardContent>
        <SecondaryStepper
          process={process}
          activeStep={getActiveStep(service)}
          numberOfSteps={getNumberOfSteps(service)}
        />
      </Box>
    </Card>
  )
}

VerificationOverviewTile.propTypes = {
  processLabelId: PropTypes.string,
  process: PropTypes.object,
  classes: PropTypes.object
}

export default withStyles(styles)(VerificationOverviewTile)
