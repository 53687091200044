import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import LoginForm from 'components/LoginForm'
import * as userActions from 'redux/actions/user'
import history from 'services/history'
import { get } from 'helpers'

export const Login = ({
  clearUserError,
  token,
  enroll_portal_url,
  errorResponse,
  activation_barcode,
  confirmation,
  authRequiredFeilds,
  authMessage,
  login
}) => {
  const onSubmit = async ({ email, password, code }) => {
    const { loginCallback } = window.__AVS_CONFIG__
    const loginAction = await login({ email, password, code })
    // duo confirmation reqeust
    if (get(loginAction, 'payload.response.confirmation')) {
      await login({ email, password, confirmation: true })
    }
    if (loginCallback && window[loginCallback]) {
      window[loginCallback](email, password)
    }
  }

  useEffect(() => {
    clearUserError()
  }, [clearUserError])

  useEffect(() => {
    if (token) history.push('/dashboard')
    if (enroll_portal_url) window.open(enroll_portal_url, '_blank')
  }, [token, enroll_portal_url])

  return (
    <div className='avs-page avs-page-user avs-clean-form avs-form-with-spinner'>
      <LoginForm
        onSubmit={onSubmit}
        errors={errorResponse}
        activation_barcode={activation_barcode}
        confirmation={confirmation}
        authRequiredFeilds={authRequiredFeilds}
        authMessage={authMessage}
      />
    </div>
  )
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  errorResponse: PropTypes.array,
  token: PropTypes.string,
  activation_barcode: PropTypes.string,
  enroll_portal_url: PropTypes.string,
  confirmation: PropTypes.bool,
  clearUserError: PropTypes.func.isRequired,
  authRequiredFeilds: PropTypes.array,
  authMessage: PropTypes.string
}

export default withRouter(
  connect(
    state => ({
      errorResponse: state.userState.errorResponse,
      token: state.userState.token,
      activation_barcode: state.userState.activation_barcode,
      enroll_portal_url: state.userState.enroll_portal_url,
      confirmation: state.userState.confirmation,
      authRequiredFeilds: state.userState.authRequiredFeilds,
      authMessage: state.userState.authMessage
    }),
    dispatch => bindActionCreators(userActions, dispatch)
  )(Login)
)
