export const GET_PARTNERS = 'GET_PARTNERS'
export const GET_PARTNERS_DONE = 'GET_PARTNERS_DONE'
export const PARTNER_ERROR = 'PARTNER_ERROR'
export const CLEAR_USERS = 'CLEAR_USERS'
export const CLEAR_USERS_DONE = 'CLEAR_USERS_DONE'
export const GET_PARTNER = 'GET_PARTNER'
export const GET_PARTNER_DONE = 'GET_PARTNER_DONE'
export const CLEAR_PARTNER = 'CLEAR_PARTNER'
export const GET_SERVICE_PARAMS = 'GET_SERVICE_PARAMS'
export const GET_SERVICE_PARAMS_DONE = 'GET_SERVICE_PARAMS_DONE'
export const GET_SERVICE_PARAMS_SCHEMA = 'GET_SERVICE_PARAMS_SCHEMA'
export const GET_SERVICE_PARAMS_SCHEMA_DONE = 'GET_SERVICE_PARAMS_SCHEMA_DONE'
export const GET_PROVINCE_PARAM = 'GET_PROVINCE_PARAM'
export const GET_PROVINCE_PARAM_DONE = 'GET_PROVINCE_PARAM_DONE'
export const START_SERVICE_EDITTING = 'START_SERVICE_EDITTING'
export const CANCEL_SERVICE_EDITTING = 'CANCEL_SERVICE_EDITTING'
export const UPDATE_SERVICE_PARAMS = 'UPDATE_SERVICE_PARAMS'
export const UPDATE_SERVICE_PARAMS_DONE = 'UPDATE_SERVICE_PARAMS_DONE'
export const GET_PARTNER_TEMPLATES = 'GET_PARTNER_TEMPLATES'
export const GET_PARTNER_TEMPLATES_DONE = 'GET_PARTNER_TEMPLATES_DONE'
export const GET_RETENTION_TEMPLATES = 'GET_RETENTION_TEMPLATES'
export const GET_RETENTION_TEMPLATES_DONE = 'GET_RETENTION_TEMPLATES_DONE'
export const GET_MESSAGE_TYPES = 'GET_MESSAGE_TYPES'
export const GET_MESSAGE_TYPES_DONE = 'GET_MESSAGE_TYPES_DONE'
export const GET_USER_MESSAGE_TYPE_OPTIONS = 'GET_USER_MESSAGE_TYPE_OPTIONS'
export const GET_USER_MESSAGE_TYPE_OPTIONS_DONE = 'GET_USER_MESSAGE_TYPE_OPTIONS_DONE'
export const MESSAGE_TYPE_PARTNER_ID_CHANGE = 'MESSAGE_TYPE_PARTNER_ID_CHANGE'
export const GET_BETS = 'GET_BETS'
export const GET_BETS_DONE = 'GET_BETS_DONE'
export const GET_PAYS = 'GET_PAYS'
export const GET_PAYS_DONE = 'GET_PAYS_DONE'
export const GET_EODS = 'GET_EODS'
export const GET_EODS_DONE = 'GET_EODS_DONE'
export const GET_TOKENS = 'GET_TOKENS'
export const GET_TOKENS_DONE = 'GET_TOKENS_DONE'
export const GET_WARNING = 'GET_WARNING'
export const GET_WARNING_DONE = 'GET_WARNING_DONE'
export const GET_OASIS_CREDENTIALS = 'GET_OASIS_CREDENTIALS'
export const GET_OASIS_CREDENTIALS_DONE = 'GET_OASIS_CREDENTIALS_DONE'
export const GET_FREE_LIMIT = 'GET_FREE_LIMIT'
export const GET_FREE_LIMIT_DONE = 'GET_FREE_LIMIT_DONE'
export const CLEAR_FREE_LIMIT = 'CLEAR_FREE_LIMIT'
