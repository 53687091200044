import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import PrimaryStepper from './PrimaryStepper'
import { Box, Typography } from '@material-ui/core'
import PageTitle from './PageTitle'
import t from '../services/t'
import AvsButton from '../components/AvsButton'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as UserActions from '../redux/actions/user'
import * as ServiceActions from '../redux/actions/service'
import { getMap } from '../redux/actions/template'
import PropTypes from 'prop-types'
import { getProcessSuccessImageId, getProcessSuccessLabelId } from './processHelper'
import { isUserVerified, sanitize } from '../helpers'

export class VerificationSuccess extends Component {
  pull = () => {
    const { auth, getServicesProcess, parsedToken } = this.props

    auth()
    if (parsedToken) {
      getServicesProcess(parsedToken.userId)
    }
  }

  componentWillMount() {
    this.pull()
    this.interval = setInterval(this.pull, 5000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  onClickDone() {
    window.open(t('label.user.dashboard.process.resultLink'), '_blank').focus()
  }

  findSuccessProcessLabelId() {
    const { processes } = this.props
    if (!processes) return null

    const processesList = Object.keys(processes).map(processLabelId => {
      return { ...processes[processLabelId], processLabelId }
    })
    const process = processesList.find(it => {
      //find process with all services in status SUCCESS
      return it.services.every(service => service.status === 3)
    })

    return process ? process.processLabelId : null
  }

  render() {
    const { user, processes } = this.props
    if (!user || !processes) return null

    if (!isUserVerified(user)) return <Redirect to={'/verificationOverview'} />

    const processLabelId = this.findSuccessProcessLabelId()
    const successLabelId = getProcessSuccessLabelId(processLabelId)
    const successImageId = getProcessSuccessImageId(processLabelId)

    return (
      <div className='avs-page'>
        <PrimaryStepper activeStep={3} />
        <PageTitle>{t('label.user.dashboard.process.resultHeader')}</PageTitle>
        <Box
          display='flex'
          justifyContent='center'
          paddingY={8}
          dangerouslySetInnerHTML={{
            __html: successImageId ? sanitize(t(successImageId)) : ''
          }}
        ></Box>

        <Typography>{successLabelId ? t(successLabelId) : ''} </Typography>

        <Box mt={4} width='100%' display='flex' justifyContent='center'>
          <Box width='40%'>
            <AvsButton label={t('label.user.dashboard.process.result')} onClick={() => this.onClickDone()} />
          </Box>
        </Box>
      </div>
    )
  }
}

VerificationSuccess.propTypes = {
  auth: PropTypes.func.isRequired,
  user: PropTypes.shape({
    status: PropTypes.number,
    email: PropTypes.string
  }),
  parsedToken: PropTypes.shape({
    userId: PropTypes.string.isRequired
  }),
  getServicesProcess: PropTypes.func.isRequired,
  processes: PropTypes.object
}

export default withRouter(
  connect(
    state => ({
      user: state.userState.user,
      parsedToken: state.userState.parsedToken,
      processes: state.userState.processes
    }),
    dispatch => bindActionCreators({ ...UserActions, ...ServiceActions, getMap }, dispatch)
  )(VerificationSuccess)
)
