import HttpService from './HttpService'

const { lang = 'de' } = window.__AVS_CONFIG__

class TemplateApi extends HttpService {
  /**
   * Load template map for language from config
   */
  getMap() {
    return this.get('/template-map' + this.params({
      lang,
      reduce: true // reduce templates for user
    }))
  }
}

export default new TemplateApi()
