import React from 'react'

const Spinner = () => (
  <div className='avs-spinner-container'>
    <svg className='avs-spinner' width='65px' height='65px' viewBox='0 0 66 66' xmlns='http://www.w3.org/2000/svg'>
      <circle className='avs-path' fill='none' strokeWidth='6' strokeLinecap='round' cx='33' cy='33' r='30'></circle>
    </svg>
  </div>
)

export default Spinner
