import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { formValueSelector } from 'redux-form'

import * as userActions from 'redux/actions/user'
import Spinner from 'components/Spinner'
import SimpleForm from 'components/registration/SimpleForm'
import AnonymousForm from 'components/registration/anonymous/AnonymousForm'
import SimpleWithPhoneForm from 'components/registration/SimpleWithPhoneForm'
import InsicForm from 'components/registration/insic/InsicForm'
import ShopwareForm from 'components/registration/ShopwareForm'
import ExtendedForm from 'components/registration/ExtendedForm'
import ConstructorForm from 'components/registration/ConstructorForm'
import GpmodForm from 'components/registration/gpmod/GpmodForm'
import InsicExtendedForm from 'components/registration/insicExtended/InsicExtendedForm'
import CompanyForm from 'components/registration/CompanyForm'
import InsicPrecheckForm from 'components/registration/insicPrecheck/InsicPrecheckForm'

import history from 'services/history'
import { debounce, prepareUserData, get } from 'helpers'

const { registrationType, loginCallback, lang = 'de' } = window.__AVS_CONFIG__

const initialValues = {
  email: '',
  password: '',
  lang,
  profile: {
    agreement1: null,
    agreement2: null,
    agreement3: null,
    agreement4: null,
    greeting: '',
    firstName: '',
    lastName: '',
    birthday: '',
    address: '',
    zip: '',
    city: '',
    street: '',
    houseNumber: '',
    phone: '',
    bic: '',
    iban: ''
  }
}

const RegistrationForm = (() => {
  switch (registrationType) {
    case 'shopware':
      return ShopwareForm

    case 'extended':
      return ExtendedForm

    case 'insic':
      return InsicForm

    case 'constructor':
      return ConstructorForm

    case 'gpmod':
      return GpmodForm

    case 'phone':
      return SimpleWithPhoneForm

    case 'insicExtended':
      return InsicExtendedForm

    case 'company':
      return CompanyForm

    case 'insicPrecheck':
      return InsicPrecheckForm

    case 'anonymous':
      return AnonymousForm

    case 'simple':
    default:
      return SimpleForm
  }
})()

export const Registration = props => {
  const { ibanValue, zipValue, token, clearUserError, getCities, addCity, cities, errorResponse } = props
  const [isLoading, setIsLoading] = useState(false)
  const [registrationMethod, setRegistrationMethod] = useState('registerSimple')
  const onZipChanged = useCallback(debounce(getCities, 500), [])

  const isBicVisible = () => {
    const iban = ibanValue || ''
    return /^\d/.test(iban)
  }

  const register = async userData => {
    const data = prepareUserData(userData)
    setIsLoading(true)
    const result = await props[registrationMethod](data)
    if (!result.error) return await login(userData)
    setIsLoading(false)
  }

  const login = async ({ email, password }) => {
    setIsLoading(true)
    let result = await props.login({ email, password })
    // duo confirmation request
    if (get(result, 'payload.response.confirmation')) {
      result = await props.login({ email, password, confirmation: true })
    }
    if (loginCallback && window[loginCallback]) {
      window[loginCallback](email, password)
    }
    if (result.error) setIsLoading(false)
  }

  useEffect(() => {
    clearUserError()
  }, [])

  useEffect(() => {
    if (zipValue && zipValue.trim().length) {
      onZipChanged(zipValue)
    }
  }, [zipValue])

  useEffect(() => {
    if (token && registrationType !== 'insicPrecheck') {
      history.push('/dashboard')
    }
  }, [token])

  return (
    <div className='avs-page avs-page-user avs-clean-form'>
      <RegistrationForm
        onSubmit={register}
        onLogin={login}
        initialValues={initialValues}
        isBicVisible={isBicVisible()}
        cities={cities}
        addCity={() => addCity(zipValue)}
        errors={errorResponse}
        setRegistrationMethod={setRegistrationMethod}
      />

      {isLoading && <Spinner />}
    </div>
  )
}

Registration.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.object),
  ibanValue: PropTypes.string,
  zipValue: PropTypes.string,
  getCities: PropTypes.func.isRequired,
  registerSimple: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  addCity: PropTypes.func.isRequired,
  token: PropTypes.string,
  errorResponse: PropTypes.arrayOf(PropTypes.object),
  clearUserError: PropTypes.func.isRequired
}

const selector = formValueSelector('registration')

export default withRouter(
  connect(
    state => ({
      cities: state.userState.cities,
      token: state.userState.token,
      ibanValue: selector(state, 'profile.iban'),
      zipValue: selector(state, 'profile.zip'),
      errorResponse: state.userState.errorResponse
    }),
    dispatch => bindActionCreators(userActions, dispatch)
  )(Registration)
)
