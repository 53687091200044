import React, { Component } from 'react'
import AnimateHeight from 'react-animate-height'
import PropTypes from 'prop-types'

import UserCustomForm from 'components/UserCustomForm'
import ConfirmForm from 'components/ConfirmForm'
import Spinner from 'components/Spinner'
import ServiceSetStatusForm from 'components/ServiceSetStatusForm'
import VideoContainer from 'components/VideoContainer'
import FaceRecognitionModal from 'components/FaceRecognitionModal'
import CreditCardForm from 'components/CreditCardForm'
import DiiaServiceForm from 'components/DiiaServiceForm'

import t from 'services/t'
import { classNames, get, set, formatDate, prepareUserData, sanitize } from 'helpers'
import { isAllowed } from 'services/permission'
import HttpService from 'services/HttpService'
import auth from 'services/auth'

const { apiUrl, registrationType } = window.__AVS_CONFIG__

class Service extends Component {
  _isMounted = false
  interval = null
  payloadData = null

  state = {
    isLoading: false,
    isOpened: this.isInitiallyOpened,
    isLessMessages: true,
    isPayloadValid: true,
    isFaceRecognitionStarted: false,
    isFaceRecognitionClosed: false
  }

  statusIcons =
    registrationType === 'insicPrecheck'
      ? {
          1: 'play_arrow',
          2: 'update',
          3: 'check',
          4: 'close',
          5: 'do_not_disturb',
          10: 'person'
        }
      : {
          1: 'play_arrow',
          2: 'refresh',
          3: 'check',
          4: 'close',
          5: 'warning',
          10: 'person'
        }

  msgTypes = {
    1: 'avs-info',
    2: 'avs-warning',
    3: 'avs-error',
    4: 'avs-alert'
  }

  componentDidMount() {
    this._isMounted = true
    this.UniqueUserCustomForm = UserCustomForm(this.cssServiceName)

    if (this.props.service.name !== 'profile') {
      this.loadMessages()

      this.interval = setInterval(() => {
        const { isOpened } = this.state

        if (isOpened) this.loadMessages()
      }, 5000)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    clearInterval(this.interval)
  }

  /**
   * User for loading spinner
   * @param {boolean} value
   */
  set isLoading(value) {
    if (this._isMounted) this.setState({ isLoading: value })
  }

  get cssServiceName() {
    return this.props.service.name.replace(/\./g, '-')
  }

  get isCheckButtonVisible() {
    const { service } = this.props

    return (service.inputMode.default || service.maxAttemptsReached) && service.state !== 'passed'
  }

  get isCodeConfirmationFormVisible() {
    return this.props.service.inputMode.code
  }

  get isClearPassportVisible() {
    const { service, user } = this.props

    return (
      ['identt', 'passportCheck', 'face.recognition'].includes(service.name) &&
      !service.maxAttemptsReached &&
      (service.status === 4 || service.status === 5) &&
      (user.profile.passportFrontId || user.profile.passportBackId)
    )
  }

  get isClearImageVisible() {
    const { service, user } = this.props

    return (
      ['credit-card-check'].includes(service.name) &&
      !service.maxAttemptsReached &&
      service.status !== 1 &&
      user.profile.imageId
    )
  }

  get isUserCustomFormVisible() {
    const { service } = this.props

    return service.inputMode.user && !service.maxAttemptsReached && service.state !== 'passed'
  }

  get isInitiallyOpened() {
    const { service } = this.props
    const services = ['finapi-service', 'external-service']

    if (service.name === 'profile') {
      return false
    }

    if (services.includes(service.name) && service.status === 2) {
      return true
    }

    if (service.opened && (service.attemptsLeft > 0 || service.isMaxAttemptsInfinite)) {
      if (service.status === 1 || service.status === 2) {
        return true
      }
    }

    return service.state === 'active' && (service.status === 2 || service.status === 4) && service.isUserInputExpected
  }

  get isMessagesVisible() {
    return this.props.service.name !== 'profile'
  }

  get isVideoContainerVisible() {
    return this.props.service.name === 'identt.video'
  }

  get isFaceRecognitionStarted() {
    return this.state.isFaceRecognitionStarted
  }

  get isAttemptsVisible() {
    const { service } = this.props

    return service.state !== 'passed' && !service.isMaxAttemptsInfinite
  }

  get isSkipVisible() {
    const { service } = this.props

    return service.skipped && service.state !== 'passed'
  }

  get isRessetAttemptsVisible() {
    return (
      isAllowed('engineService:resetAttemptsCount', this.props.currentUser) && this.props.service.name !== 'profile'
    )
  }

  get isSetStatusVisible() {
    return isAllowed('engineService:setStatus', this.props.currentUser) && this.props.service.name !== 'profile'
  }

  get isCreditCardCheckVisible() {
    return this.props.service.name === 'credit-card-check'
  }

  get isDiiaServiceVisible() {
    return this.props.service.name === 'diia-service'
  }

  get isCreditCardFieldsVisible() {
    const { service } = this.props
    let isCardNumberVisible = false
    let isCardExpirationVisible = false
    if (service.inputMode.user) {
      if (service.data && service.data.requiredFields && service.data.requiredFields.length) {
        isCardNumberVisible = service.data.requiredFields.includes('cardNumber')
        isCardExpirationVisible = service.data.requiredFields.includes('cardExpiration')
      }
    }
    return { isCardNumberVisible, isCardExpirationVisible }
  }

  get isCheckDisabled() {
    const { isLoading, isPayloadValid } = this.state
    const { service } = this.props

    return (
      isLoading ||
      service.maxAttemptsReached ||
      this.code ||
      !isPayloadValid ||
      (service.name === 'finapi-service' && service.status === 2)
    )
  }

  get initialValues() {
    const { user } = this.props

    return {
      ...user,
      profile: {
        ...user.profile,
        birthday: formatDate(new Date(user.profile.birthday))
      }
    }
  }

  displayFaceRecognitionModal = () => {
    this.setState({ isFaceRecognitionStarted: true })
  }

  hideFaceRecognitionModal = () => {
    this.setState({ isFaceRecognitionStarted: false })
  }

  setPayloadData = data => (this.payloadData = data)

  saveFaceRecResult = () => {
    const container = document.querySelector('.avs-modal video')
    const canvas = document.createElement('canvas')
    const textList = document.getElementsByClassName('avs-modal-check')
    const list = [].slice.call(textList)
    const text = list
      .map(el => {
        return el.innerText
      })
      .join()
    const ctx = canvas.getContext('2d')
    canvas.width = container.videoWidth
    canvas.height = container.videoHeight
    ctx.drawImage(container, 0, 0, canvas.width, canvas.height)
    ctx.font = '12px Arial'
    ctx.fillStyle = 'rgb(60,60,60)'
    ctx.fillText(text, 10, 20)
    const dataURI = canvas.toDataURL()
    return dataURI
  }

  setIsPayloadValid = isValid => this.setState({ isPayloadValid: isValid })

  tokenify(url) {
    const host = apiUrl.match(/\/\/[^/]+\/?/)

    return url.indexOf(host) > -1 ? url + `?token=${auth.token}` : url
  }

  userUpdate = async values => {
    try {
      this.isLoading = true

      const { user, userUpdate, service } = this.props

      const data = { id: user.id }
      const passportData = { id: user.id }

      this.setPayloadData(values.payloadData || this.payloadData)
      let isNotImgFields = false
      const withoutAutostart = service.name !== 'profile'
      service.data.requiredFields.forEach(field => {
        const value = get(values, field)
        if (value) {
          if (field === 'profile.passportFront' || field === 'profile.passportBack' || field === 'profile.image') {
            set(passportData, field, value)
          } else {
            set(data, field, value)
            isNotImgFields = true
          }
        }
      })

      const passport = {
        front: get(passportData, 'profile.passportFront'),
        back: get(passportData, 'profile.passportBack'),
        image: get(passportData, 'profile.image')
      }

      if (passport.front || passport.back || passport.image) {
        const form = new FormData()

        if (passport.front) form.append('profile[passportFront]', passport.front)
        if (passport.back) form.append('profile[passportBack]', passport.back)
        if (passport.image) form.append('profile[image]', passport.image)

        await userUpdate(user.id, form, withoutAutostart)
        if (isNotImgFields) {
          await userUpdate(user.id, prepareUserData(data), withoutAutostart)
        }
      } else {
        await userUpdate(user.id, prepareUserData(data), withoutAutostart)
      }

      await this.check()
    } catch (err) {
      this.isLoading = false
      throw err
    }
  }

  toggleOpened = () => {
    this.setState({ isOpened: !this.state.isOpened })
  }

  clearPassport = async () => {
    try {
      const { user, clearPassport } = this.props
      this.isLoading = true

      await clearPassport(user.id)
      await this.check()
    } catch (err) {
      this.isLoading = false
      throw err
    }
  }

  clearImage = async () => {
    try {
      const { user, clearImage } = this.props
      this.isLoading = true

      await clearImage(user.id)
      await this.check()
    } catch (err) {
      this.isLoading = false
      throw err
    }
  }

  check = async (avoidStarFaceRecognition = false) => {
    try {
      this.isLoading = true
      const { user, service, check = _ => _, pull = _ => _ } = this.props
      const data = this.payloadData || {}

      const result = await check(user.id, service.id, data)
      if (
        avoidStarFaceRecognition !== true &&
        service.name === 'face.recognition' &&
        get(result, 'payload.response.isReadyToStart')
      ) {
        this.displayFaceRecognitionModal()
      }

      this.isLoading = false
      this.payloadData = null

      await pull()
    } catch (err) {
      this.isLoading = false
      throw err
    }
  }

  skip = async () => {
    try {
      this.isLoading = true

      const { user, service, skip, pull } = this.props

      await skip(user.id, service.id)

      this.isLoading = false
      this.setState({ isOpened: false })

      await pull()
    } catch (err) {
      this.isLoading = false
      throw err
    }
  }

  confirm = async ({ code }) => {
    try {
      const { user, service, confirm, pull } = this.props

      this.isLoading = true

      await confirm(user.id, service.id, code)

      this.isLoading = false

      await pull()
    } catch (err) {
      this.isLoading = false
      throw err
    }
  }

  loadMessages = () => {
    const { user, service, getMessages } = this.props

    getMessages(user.id, service.name, this.state.isLessMessages ? 2 : 10)
  }

  showMoreMessages = () => {
    this.setState({ isLessMessages: false }, this.loadMessages)
  }

  showLessMessages = () => {
    this.setState({ isLessMessages: true }, this.loadMessages)
  }

  resetAttemptsCount = async () => {
    try {
      const {
        user: { id: userId },
        service: { name: serviceName },
        resetAttemptsCount,
        pull
      } = this.props

      this.isLoading = true
      await resetAttemptsCount(userId, serviceName)
      this.isLoading = false
      await pull()
    } catch (err) {
      this.isLoading = false
      throw err
    }
  }

  setServiceStatus = async status => {
    try {
      const {
        user: { id: userId },
        service: { name: serviceName },
        setServiceStatus,
        pull
      } = this.props

      this.isLoading = true
      await setServiceStatus(userId, serviceName, status)
      this.isLoading = false
      await pull()
    } catch (err) {
      this.isLoading = false
      throw err
    }
  }

  setFaceRecognitionClosed = () => {
    this.setState({ isFaceRecognitionClosed: true })
  }

  render() {
    if (!this._isMounted) return null
    const { hidden = [] } = window.__AVS_CONFIG__
    const { service, user, messages = [] } = this.props
    const { isLoading, isOpened, isLessMessages } = this.state
    const messagesToDisplay = isLessMessages && messages.length ? messages.slice(0, 1) : messages
    const label = t(`label.engine.service.${service.name}`)
    const passportFrontUrl =
      user.profile.passportFrontId && HttpService.getExternalUrl(`/user/${user.id}/passport/front`)
    const passportBackUrl = user.profile.passportBackId && HttpService.getExternalUrl(`/user/${user.id}/passport/back`)
    const serviceExplanation =
      service.name !== 'profile' &&
      t(`label.userDashboard.service.explanation.${service.name}`, {
        service,
        user
      })

    return (
      <li
        className={classNames({
          'avs-service': true,
          'avs-can-be-opened': true,
          'avs-opened': isOpened,
          'avs-service-hidden': service.hidden || hidden.includes(service.name),
          [this.cssServiceName]: true
        })}
      >
        <header className='avs-service-header' onClick={this.toggleOpened}>
          <span className='avs-icon-container'>
            <i
              className={classNames({
                'avs-material-icons': true,
                'avs-md-30': true,
                'avs-md-light': true,
                'avs-service-status-icon': true,
                [this.statusIcons[service.status]]: true
              })}
            >
              {this.statusIcons[service.status]}
            </i>
          </span>
          <div className='avs-service-name' title={label}>
            <span className='avs-service-name-container'>{label}</span>
          </div>
          <span className='avs-icon-container'>
            <i className='avs-material-icons avs-md-30 avs-md-light avs-service-expand-icon'>keyboard_arrow_down</i>
          </span>
        </header>

        <AnimateHeight duration={400} height={isOpened ? 'auto' : 0}>
          <div className='avs-service-content'>
            <div className='avs-container'>
              {this.isMessagesVisible && (
                <ul className='avs-list-of-messages'>
                  <li
                    className='avs-message'
                    dangerouslySetInnerHTML={{
                      __html: sanitize(serviceExplanation)
                    }}
                  ></li>

                  {this.isAttemptsVisible && (
                    <li className='avs-message'>
                      {t('label.userDashboard.attemptsLeft', { count: service.attemptsLeft })}
                    </li>
                  )}

                  {messagesToDisplay.map((msg, i) => (
                    <li
                      key={i}
                      dangerouslySetInnerHTML={{
                        __html: sanitize(msg.text.length > 500 ? msg.text.slice(0, 500) + '...' : msg.text)
                      }}
                      className={classNames({
                        'avs-message': true,
                        [this.msgTypes[msg.type]]: true
                      })}
                    />
                  ))}
                  {isLessMessages && messages.length > 1 && (
                    <li>
                      <button type='button' className='avs-link' onClick={this.showMoreMessages}>
                        {t('label.userDashboard.moreMessages')}
                      </button>
                    </li>
                  )}
                  {!isLessMessages && messages.length > 1 && (
                    <li>
                      <button type='button' className='avs-link' onClick={this.showLessMessages}>
                        {t('label.userDashboard.lessMessages')}
                      </button>
                    </li>
                  )}
                </ul>
              )}
            </div>

            {this.isCodeConfirmationFormVisible && <ConfirmForm onSubmit={this.confirm} service={service} />}

            {this.isCreditCardCheckVisible && (
              <CreditCardForm
                serviceStatus={service.status}
                setPayloadData={this.setPayloadData}
                isCreditCardFieldsVisible={this.isCreditCardFieldsVisible}
              />
            )}

            {this.isDiiaServiceVisible && (
              <DiiaServiceForm
                setPayloadData={this.setPayloadData}
                payloadData={this.payloadData}
                diiaIdLink={service.data.redirectUrl?.diiaIdLink}
                sharingLink={service.data.redirectUrl?.sharingLink}
              />
            )}

            {this.isUserCustomFormVisible && (
              <this.UniqueUserCustomForm
                initialValues={this.initialValues}
                user={user}
                service={service}
                fields={service.data.requiredFields}
                submitText={t(`service.check.${service.name}.action.check`)}
                onSubmit={this.userUpdate}
              />
            )}

            {this.isVideoContainerVisible && (
              <VideoContainer
                isOpened={isOpened}
                setPayloadData={this.setPayloadData}
                payloadData={this.payloadData}
                setIsPayloadValid={this.setIsPayloadValid}
                isRecordingAvailable={this.isCheckButtonVisible}
              />
            )}

            {this.isFaceRecognitionStarted && (
              <FaceRecognitionModal
                hideModal={this.hideFaceRecognitionModal}
                passportFrontUrl={passportFrontUrl}
                passportBackUrl={passportBackUrl}
                setPayloadData={this.setPayloadData}
                saveFaceRecResult={this.saveFaceRecResult}
                check={this.check}
                params={service.data.params}
                setFaceRecognitionClosed={this.setFaceRecognitionClosed}
                serviceState={service.state}
              />
            )}

            {this.isCheckButtonVisible && (
              <div className='avs-form-group avs-button-container'>
                <button
                  className={classNames({
                    'avs-btn': true,
                    'avs-btn-default': true,
                    'avs-btn-check': true,
                    'avs-large': true,
                    'avs-yes-com': service.name === 'yes-identity'
                  })}
                  onClick={this.check}
                  disabled={this.isCheckDisabled}
                >
                  {t(`service.check.${service.name}.action.check`)}
                </button>
              </div>
            )}

            {this.isClearPassportVisible && (
              <div className='avs-form-group avs-button-container'>
                <button className='avs-btn avs-btn-default avs-large' onClick={this.clearPassport}>
                  {t('label.userDashboard.clearPassport')}
                </button>
              </div>
            )}

            {this.isClearImageVisible && (
              <div className='avs-form-group avs-button-container'>
                <button className='avs-btn avs-btn-default avs-large' onClick={this.clearImage}>
                  {t('label.userDashboard.clearImage')}
                </button>
              </div>
            )}

            {this.isSkipVisible && (
              <div className='avs-form-group avs-button-container'>
                <button className='avs-btn avs-btn-default avs-large' onClick={this.skip}>
                  {t('label.userDashboard.skip')}
                </button>
              </div>
            )}

            {this.isRessetAttemptsVisible && (
              <div className='form-group button-container'>
                <button
                  className='avs-btn avs-btn-default avs-large'
                  onClick={this.resetAttemptsCount}
                  disabled={service.name === 'ticketing'}
                >
                  Reset attempts
                </button>
              </div>
            )}

            {this.isSetStatusVisible && <ServiceSetStatusForm onSubmit={this.setServiceStatus} />}

            {isLoading && !this.isDiiaServiceVisible && <Spinner />}
          </div>
        </AnimateHeight>
      </li>
    )
  }
}

Service.propTypes = {
  service: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired
    })
  ),
  getMessages: PropTypes.func,
  userUpdate: PropTypes.func.isRequired,
  check: PropTypes.func,
  skip: PropTypes.func,
  pull: PropTypes.func,
  setServiceStatus: PropTypes.func,
  resetAttemptsCount: PropTypes.func,
  confirm: PropTypes.func,
  clearPassport: PropTypes.func,
  clearImage: PropTypes.func
}

export default Service
