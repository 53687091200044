const permissions = {
  admin: [
    'engineService:resetAttemptsCount',
    'engineService:setStatus',
    'engineService:check.any',
    'engineService:confirm.any',
    'engineService:messages',
    'userMessage:get.any',
    'userMessage:post',
    'partner:get',
    'template:get',
    'user:setStatus',
    'user:fullyDelete',
    'user:editRole',
    'user:editGroup',
    'sql:get',
    'report:get',
    'freeLimit:reset'
  ],
  partnerAdmin: [
    'engineService:resetAttemptsCount',
    'engineService:setStatus',
    'engineService:check.any',
    'engineService:confirm.any',
    'engineService:messages',
    'userMessage:get.any',
    'userMessage:post',
    'partner:addUser',
    'report:get',
    'safe:get',
    'userDoubles:get',
    'oasisCredentials:get'
  ],
  user: [],
  company: []
}
const adminRoles = [
  'admin',
  'partnerAdmin'
]

export const isAdmin = user => !!user && adminRoles.indexOf(user.role) > -1

export const isAllowed = (permission, user) => (
  !!user && permissions[user.role].indexOf(permission) > -1
)
