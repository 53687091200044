import React from 'react'
import MobileStepper from '@material-ui/core/MobileStepper'
import * as PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import 'styles/select.css'
import { isProcessActive, isProcessInactive, isProcessPrimary } from './processHelper'

const styles = {
  root: {
    backgroundColor: 'transparent'
  },
  // "hack" for filling only finished steps: fill all steps, unfill active step and all steps after active step
  // (used because there is no css previous sibling selector)
  dotPrimary: {
    backgroundColor: 'white'
  },
  dotActivePrimary: {
    backgroundColor: 'gray',
    '& ~ *': {
      backgroundColor: 'gray'
    }
  },
  dot: {
    backgroundColor: '#3f51b5'
  },
  dotActive: {
    backgroundColor: 'rgba(0, 0, 0, 0.26)',
    '& ~ *': {
      backgroundColor: 'rgba(0, 0, 0, 0.26)'
    }
  }
}

const SecondaryStepper = props => {
  const { classes, activeStep, numberOfSteps, backButton = <div />, process } = props

  let adaptedClasses = {
    root: classes.root,
    dot: classes.dot + ' avs-secondary-stepper-done',
    dotActive: classes.dotActive + ' avs-secondary-stepper-current-step' 
  }
  if (process) {
    if (isProcessPrimary(process)) {
      adaptedClasses.dot = classes.dotPrimary + ' avs-secondary-stepper-done-primary'
      adaptedClasses.dotActive = classes.dotActivePrimary + ' avs-secondary-stepper-current-step-primary'
    } else if (isProcessActive(process)) {
      adaptedClasses.dot = classes.dot + ' avs-secondary-stepper-done-active'
      adaptedClasses.dotActive = classes.dotActive + ' avs-secondary-stepper-current-step-active'
    } else if (isProcessInactive) {
      adaptedClasses.dot = classes.dot + ' avs-secondary-stepper-done-inactive'
      adaptedClasses.dotActive = classes.dotActive + ' avs-secondary-stepper-current-step-inactive'
    }
  }

  return (
    <MobileStepper
      variant='dots'
      steps={numberOfSteps}
      position='static'
      activeStep={activeStep}
      classes={adaptedClasses}
      nextButton={<div />}
      backButton={backButton}
    />
  )
}

SecondaryStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
  backButton: PropTypes.object,
  primary: PropTypes.bool,
  classes: PropTypes.object,
  process: PropTypes.object
}

export default withStyles(styles)(SecondaryStepper)
