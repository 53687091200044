import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'

import * as userActions from 'redux/actions/user'
import t from 'services/t'
import Input from 'components/Input'
import Select from 'components/Select'
import { asyncUserValidate, maxLength255, date, required, email } from 'services/validation'
import HttpService from 'services/HttpService'
import { debounce } from 'helpers'

export const UserCustomForm = ({
  user,
  submitText,
  handleSubmit,
  invalid,
  submitting,
  cities,
  zipValue,
  ibanValue,
  service,
  getCities,
  fields
}) => {
  const isFieldVisible = field => {
    return fields.indexOf(field) !== -1
  }

  const isBicVisible = () => {
    const iban = ibanValue || ''
    return /^\d/.test(iban)
  }

  const isPassportVisible = () => {
    return service.name === 'profile'
  }

  const getPassportFrontLink = () => {
    if (!user.profile || !user.profile.passportFrontId) return null
    return HttpService.getExternalUrl(`/user/${user.id}/passport/front`)
  }

  const getPassportBackLink = () => {
    if (!user.profile || !user.profile.passportBackId) return null
    return HttpService.getExternalUrl(`/user/${user.id}/passport/back`)
  }

  const getImageLink = () => {
    if (!user.profile || !user.profile.imageId) return null
    return HttpService.getExternalUrl(`/user/${user.id}/passport/image`)
  }

  const onZipChanged = useCallback(debounce(getCities, 500), [])

  useEffect(() => {
    if (zipValue && zipValue.trim().length) {
      onZipChanged(zipValue)
    }
  }, [zipValue, getCities])

  return (
    <div className='avs-clean-form avs-user-form-custom'>
      <form onSubmit={handleSubmit} noValidate>
        {isFieldVisible('email') && (
          <Field
            type='email'
            name='email'
            label={t('label.user.attribute.email')}
            validate={[email, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.greeting') && (
          <Field
            type='select'
            name='profile.greeting'
            label={t('label.userProfile.attribute.greeting')}
            validate={[required]}
            options={[t('label.greeting.male'), t('label.greeting.female')]}
            component={Select}
          />
        )}

        {isFieldVisible('profile.firstName') && (
          <Field
            type='text'
            name='profile.firstName'
            label={t('label.userProfile.attribute.firstName')}
            validate={[maxLength255, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.lastName') && (
          <Field
            type='text'
            name='profile.lastName'
            label={t('label.userProfile.attribute.lastName')}
            validate={[maxLength255, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.maidenName') && (
          <Field
            type='text'
            name='profile.maidenName'
            label={t('label.userProfile.attribute.maidenName')}
            validate={[maxLength255]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.placeOfBirth') && (
          <Field
            type='text'
            name='profile.placeOfBirth'
            label={t('label.userProfile.attribute.placeOfBirth')}
            validate={[maxLength255, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.birthday') && (
          <Field
            type='date'
            name='profile.birthday'
            label={t('label.userProfile.attribute.birthday')}
            validate={[date, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.zip') && (
          <Field
            type='text'
            name='profile.zip'
            label={t('label.userProfile.attribute.zip')}
            validate={[maxLength255, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.city') && (
          <Field
            type='text'
            name='profile.city'
            label={t('label.userProfile.attribute.city')}
            autocomplete={cities}
            validate={[maxLength255, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.street') && (
          <Field
            type='text'
            name='profile.street'
            label={t('label.userProfile.attribute.street')}
            validate={[maxLength255, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.houseNumber') && (
          <Field
            type='text'
            name='profile.houseNumber'
            label={t('label.userProfile.attribute.houseNumber')}
            validate={[maxLength255, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.phone') && (
          <Field
            type='text'
            name='profile.phone'
            label={t('label.userProfile.attribute.phone')}
            validate={[maxLength255, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.iban') && (
          <Field
            type='text'
            name='profile.iban'
            label={t('label.userProfile.attribute.iban')}
            validate={[maxLength255, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.bic') && isBicVisible() && (
          <Field
            type='text'
            name='profile.bic'
            label={t('label.userProfile.attribute.bic')}
            validate={[maxLength255, required]}
            component={Input}
          />
        )}

        {isFieldVisible('profile.passportFront') && (
          <Field
            type='file'
            name='profile.passportFront'
            label={t('label.userProfile.attribute.passportFront')}
            accept='image/*,video/*,application/pdf'
            component={Input}
          />
        )}

        {isFieldVisible('profile.passportBack') && (
          <Field
            type='file'
            name='profile.passportBack'
            label={t('label.userProfile.attribute.passportBack')}
            accept='image/*,video/*,application/pdf'
            component={Input}
          />
        )}

        {isFieldVisible('profile.image') && (
          <Field
            type='file'
            name='profile.image'
            label={t('label.userProfile.attribute.image')}
            accept='image/*,application/pdf,application/x-zip-compressed'
            component={Input}
          />
        )}

        {isFieldVisible('PersonCPRNumber') && (
          <Field name='payloadData.PersonCPRNumber' label='PersonCPRNumber' component={Input} />
        )}

        {isPassportVisible() && (
          <div className='avs-passport-container'>
            {getPassportFrontLink() && (
              <div>
                <a href={getPassportFrontLink()} className='avs-link' target='_blank' rel='noopener noreferrer'>
                  {t('label.userProfile.attribute.passportFront')}
                </a>
              </div>
            )}
            {getPassportBackLink() && (
              <div>
                <a href={getPassportBackLink()} className='avs-link' target='_blank' rel='noopener noreferrer'>
                  {t('label.userProfile.attribute.passportBack')}
                </a>
              </div>
            )}
            {getImageLink() && (
              <div>
                <a href={getImageLink()} className='avs-link' target='_blank' rel='noopener noreferrer'>
                  {t('label.userProfile.attribute.image')}
                </a>
              </div>
            )}
          </div>
        )}

        <div className='avs-form-group avs-button-container'>
          <button className='avs-btn avs-btn-default avs-large' disabled={invalid || submitting}>
            {submitText}
          </button>
        </div>
      </form>
    </div>
  )
}

UserCustomForm.propTypes = {
  submitText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getCities: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialValues: PropTypes.object.isRequired,
  ibanValue: PropTypes.string,
  zipValue: PropTypes.string,
  cities: PropTypes.array,
  service: PropTypes.shape({
    name: PropTypes.string
  }),
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    profile: PropTypes.object.isRequired
  }),
  initialize: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool
}

export default serviceName => {
  const formName = `userCustomForm_${serviceName}`
  const selector = formValueSelector(formName)
  const form = reduxForm({
    form: formName,
    destroyOnUnmount: false,
    enableReinitialize: true,
    asyncValidate: asyncUserValidate,
    asyncChangeFields: ['profile.passportFront', 'profile.passportBack', 'profile.image'],
    asyncBlurFields: [
      'email',
      'password',
      'profile.greeting',
      'profile.firstName',
      'profile.lastName',
      'profile.maidenName',
      'profile.birthday',
      'profile.zip',
      'profile.city',
      'profile.street',
      'profile.houseNumber',
      'profile.phone',
      'profile.bic',
      'profile.iban',
      'profile.passportFront',
      'profile.passportBack',
      'profile.image'
    ]
  })(UserCustomForm)

  return connect(
    state => ({
      cities: state.userState.cities,
      ibanValue: selector(state, 'profile.iban'),
      zipValue: selector(state, 'profile.zip')
    }),
    dispatch => bindActionCreators(userActions, dispatch)
  )(form)
}
