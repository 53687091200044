import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, reduxForm } from 'redux-form'

import t from 'services/t'
import {
  asyncEmailAndUserValidate,
  required,
  email,
  password,
  maxLength255,
  date,
  validateAgreement
} from 'services/validation'
import { classNames } from 'helpers'
import UserApi from 'services/UserApi'

import Input from 'components/Input'
import Checkbox from 'components/Checkbox'

const { registrationType } = window.__AVS_CONFIG__

const InsicRegisterForm = props => {
  const {
    isBicVisible,
    change,
    touch,
    getLoqate,
    resetLoqate,
    handleSubmit,
    loqateItems,
    cities,
    goBack,
    invalid,
    submitting,
    clearOauth2Data,
    invalidEmails
  } = props
  const [isAddressHidden, setIsAddressHidden] = useState(true)
  const [isLocalitySelected, setIsLocalitySelected] = useState(false)

  const onAddressClick = (item, input) => {
    if (item.type === 'Address') {
      UserApi.retrieveLoqate(item.id).then(data => {
        const [address] = data.Items

        change('profile.zip', address.PostalCode)
        change('profile.city', address.City)
        change('profile.street', address.Street)
        change('profile.houseNumber', address.BuildingNumber)
        touch('profile.zip', 'profile.city', 'profile.street', 'profile.houseNumber')

        input.onChange('')
        resetLoqate()
        setIsAddressHidden(false)
        setIsLocalitySelected(false)
      })
    } else {
      setIsLocalitySelected(true)
      getLoqate(item.text, item.id)
    }
  }

  useEffect(() => {
    return () => {
      clearOauth2Data()
    }
  }, [])

  const validateEmail = email => {
    const invalidEmail = invalidEmails.find(item => item.email === email)
    return invalidEmail && invalidEmail.error
  }

  const warnEmail = email => {
    const invalidEmail = invalidEmails.find(item => item.email === email)
    return invalidEmail && invalidEmail.warning
  }

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Field
        type='email'
        name='email'
        label={t('label.user.attribute.email') + '*'}
        validate={[email, required, validateEmail]}
        warn={[warnEmail]}
        autofocus={true}
        component={Input}
      />

      <Field
        type='password'
        name='password'
        label={t('label.user.attribute.password') + '*'}
        validate={[password, required]}
        showPassword={true}
        component={Input}
        autofocus={true}
      />

      <Field
        type='checkbox'
        name='profile.agreement1'
        label={t('label.userProfile.attribute.agreement1') + '*'}
        validate={[validateAgreement]}
        component={Checkbox}
      />

      {t('label.userProfile.attribute.agreement2') && (
        <Field
          type='checkbox'
          name='profile.agreement2'
          label={t('label.userProfile.attribute.agreement2')}
          component={Checkbox}
        />
      )}

      {t('label.userProfile.attribute.agreement3') && (
        <Field
          type='checkbox'
          name='profile.agreement3'
          label={t('label.userProfile.attribute.agreement3')}
          component={Checkbox}
        />
      )}

      {t('label.userProfile.attribute.agreement4') && (
        <Field
          type='checkbox'
          name='profile.agreement4'
          label={t('label.userProfile.attribute.agreement4')}
          component={Checkbox}
        />
      )}

      <Field
        type='text'
        name='profile.firstName'
        label={t('label.userProfile.attribute.firstName') + '*'}
        validate={[maxLength255, required]}
        component={Input}
      />

      <Field
        type='text'
        name='profile.lastName'
        label={t('label.userProfile.attribute.lastName') + '*'}
        validate={[maxLength255, required]}
        component={Input}
      />

      <Field
        type='date'
        name='profile.birthday'
        label={t('label.userProfile.attribute.birthday') + '*'}
        validate={[date, required]}
        component={Input}
      />

      {registrationType === 'insicExtended' && (
        <Field
          type='text'
          name='profile.placeOfBirth'
          label={t('label.userProfile.attribute.placeOfBirth') + '*'}
          validate={[maxLength255, required]}
          component={Input}
        />
      )}

      {registrationType === 'insicExtended' && (
        <Field
          type='text'
          name='profile.iban'
          label={t('label.userProfile.attribute.iban') + '*'}
          validate={[maxLength255, required]}
          component={Input}
        />
      )}

      {registrationType === 'insicExtended' && isBicVisible && (
        <Field
          type='text'
          name='profile.bic'
          label={t('label.userProfile.attribute.bic') + '*'}
          validate={[maxLength255, required]}
          component={Input}
        />
      )}

      <Field
        type='text'
        name='profile.address'
        label={t('label.userProfile.attribute.address')}
        autocomplete={loqateItems}
        autocompleteCallback={onAddressClick}
        isAutocompleteOpen={isLocalitySelected}
        component={Input}
      />

      <div className={classNames({ 'avs-hidden': isAddressHidden })}>
        <Field
          type='text'
          name='profile.zip'
          label={t('label.userProfile.attribute.zip') + '*'}
          validate={[maxLength255, required]}
          component={Input}
        />

        <Field
          type='text'
          name='profile.city'
          label={t('label.userProfile.attribute.city') + '*'}
          autocomplete={cities}
          validate={[maxLength255, required]}
          component={Input}
        />

        <Field
          type='text'
          name='profile.street'
          label={t('label.userProfile.attribute.street') + '*'}
          validate={[maxLength255, required]}
          component={Input}
        />

        <Field
          type='text'
          name='profile.houseNumber'
          label={t('label.userProfile.attribute.houseNumber') + '*'}
          validate={[maxLength255, required]}
          component={Input}
        />
      </div>

      <div className='avs-form-group'>
        <button className='avs-btn avs-btn-primary avs-large' disabled={invalid || submitting}>
          {t('label.registrationForm.button.register')}
        </button>
      </div>

      <div className='avs-container'>
        <div className='avs-form-group avs-text-center'>
          <p className='avs-text'>
            <button type='button' className='avs-link' onClick={goBack}>
              {t('label.backButton')}
            </button>
          </p>
        </div>
      </div>
    </form>
  )
}

InsicRegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  getLoqate: PropTypes.func.isRequired,
  resetLoqate: PropTypes.func.isRequired,
  clearOauth2Data: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  cities: PropTypes.arrayOf(PropTypes.object),
  loqateItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      description: PropTypes.string,
      type: PropTypes.string
    })
  ),
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  invalidEmails: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      error: PropTypes.string
    })
  ),
  isBicVisible: PropTypes.func.isRequired
}

export default reduxForm({
  form: 'registration',
  destroyOnUnmount: true,
  asyncValidate: asyncEmailAndUserValidate,
  asyncBlurFields: [
    'email',
    'password',
    'profile.firstName',
    'profile.lastName',
    'profile.birthday',
    'profile.zip',
    'profile.city',
    'profile.street',
    'profile.houseNumber'
  ],
  asyncChangeFields: [
    'profile.agreement1',
    'profile.agreement2',
    'profile.agreement3',
    'profile.agreement4',
    'profile.city'
  ]
})(InsicRegisterForm)
