import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Spinner from 'components/Spinner'
import AnonymousRegisterForm from 'components/registration/anonymous/AnonymousRegisterForm'
import * as userActions from 'redux/actions/user'
import history from 'services/history'
import { v4 as uuidv4 } from 'uuid'

const { customData } = window.__AVS_CONFIG__

export const AnonymousForm = props => {
  const { errors, registerIncremental } = props
  const [isLoading, setIsLoading] = useState(false)

  const generatePassword = length => {
    const Allowed = {
      Uppers: 'QWERTYUIOPASDFGHJKLZXCVBNM',
      Lowers: 'qwertyuiopasdfghjklzxcvbnm',
      Numbers: '1234567890',
      Symbols: '!@#$%^&*'
    }
    const getRandomCharFromString = str => str.charAt(Math.floor(Math.random() * str.length))
    let password = ''
    password += getRandomCharFromString(Allowed.Uppers)
    password += getRandomCharFromString(Allowed.Lowers)
    password += getRandomCharFromString(Allowed.Numbers)
    password += getRandomCharFromString(Allowed.Symbols)
    for (let i = password.length; i < length; i++)
      password += getRandomCharFromString(Object.values(Allowed).join(''))
    return password
  }

  const register = async () => {
    setIsLoading(true)

    const userData = {
      email: `${uuidv4()}@insic.de`,
      password: generatePassword(8)
    }
    userData.customUserData = customData
    const result = await registerIncremental(userData)
    if (result?.payload?.user) {
      return await login(userData)
    }
    setIsLoading(false)
  }

  const login = async userData => {
    const { email, password } = userData
    setIsLoading(true)

    const result = await props.login({ email, password })

    if (result.error) {
      setIsLoading(false)
    } else {
      history.push('/dashboard')
    }
  }

  return (
    <div className='avs-material avs-form-with-spinner'>
      <div className='avs-empty-header'></div>
      <div className='avs-form-group'>
        {errors.map((e, i) => (
          <p key={i} className='avs-form-error-message'>
            {e.message}
          </p>
        ))}
      </div>
      <AnonymousRegisterForm onSubmit={register} />
      {isLoading && <Spinner />}
    </div>
  )
}

AnonymousForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  registerIncremental: PropTypes.func.isRequired,
  isBicVisible: PropTypes.bool,
  errors: PropTypes.arrayOf(PropTypes.object),
  getServicesUniqueGrouped: PropTypes.func.isRequired,
  parsedToken: PropTypes.shape({
    userId: PropTypes.string.isRequired
  })
}

export default connect(
  state => ({
    user: state.userState.user,
    parsedToken: state.userState.parsedToken
  }),
  dispatch => bindActionCreators(userActions, dispatch)
)(AnonymousForm)
