import 'whatwg-fetch'

export const loadCss = () => {
  if (process.env.NODE_ENV === 'development') return
  const { resourceUrl } = window.__AVS_CONFIG__
  const head = document.getElementsByTagName('head')[0]
  const link = document.createElement('link')

  link.rel = 'stylesheet'
  link.href = `${resourceUrl}/static/css/main.min.css`

  head.appendChild(link)
}

export const loadPartnerStyle = style => {
  const head = document.getElementsByTagName('head')[0]
  const tag  = document.createElement('style')

  tag.setAttribute('id', 'avs-style')
  tag.setAttribute('type', 'text/css')
  tag.innerHTML = style
  head.appendChild(tag)
}

export const loadStepperStyle = style => {
  const head = document.getElementsByTagName('head')[0]
  const tag  = document.createElement('style')

  tag.setAttribute('id', 'stepper-style')
  tag.setAttribute('type', 'text/css')
  tag.innerHTML = style
  head.appendChild(tag)
}

export default (partnerStyle, stepperStyle) => {
  loadCss()
  loadPartnerStyle(partnerStyle)
  loadStepperStyle(stepperStyle)
}
