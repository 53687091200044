import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import 'styles/select.css'
import { classNames } from 'helpers'

const { registrationType } = window.__AVS_CONFIG__

const Select = props => {
  const {
    handleUpdate,
    input,
    options,
    useNative,
    label,
    meta: { touched, error },
    className
  } = props
  const fieldset = useRef()
  const [isOpened, setIsOpened] = useState(false)
  const [activeItem, setActiveItem] = useState(null)

  useEffect(() => {
    if (handleUpdate) {
      handleUpdate()
    }
  }, [handleUpdate])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickOutside = e => {
    const { current: field } = fieldset

    if (field && !field.contains(e.target)) {
      setIsOpened(false)
    }
  }

  const toggleOpened = () => {
    setIsOpened(!isOpened)
  }

  const onKeyDown = e => {
    const keys = {
      tab: 9,
      enter: 13,
      esc: 27,
      space: 32,
      up: 38,
      down: 40
    }

    if (isOpened) {
      if ((e.keyCode === keys.tab && !e.shiftKey) || e.keyCode === keys.down) {
        moveDown(e)
      }
      if ((e.keyCode === keys.tab && e.shiftKey) || e.keyCode === keys.up) {
        moveUp(e)
      }
      if (e.keyCode === keys.enter || e.keyCode === keys.space) {
        confirm(e)
      }
      if (e.keyCode === keys.esc) {
        cancel(e)
      }
    } else if (e.keyCode === keys.enter || e.keyCode === keys.space) {
      expand(e)
    }
  }

  const expand = e => {
    e.preventDefault()
    toggleOpened()
  }

  const moveUp = e => {
    e.preventDefault()
    const newActiveItem = activeItem ? activeItem - 1 : options.length - 1
    setActiveItem(newActiveItem)
  }

  const moveDown = e => {
    const {
      options: { length }
    } = props

    e.preventDefault()
    const newActiveItem = activeItem === null || activeItem === length - 1 ? 0 : activeItem + 1
    setActiveItem(newActiveItem)
  }

  const cancel = e => {
    e.preventDefault()
    setIsOpened(false)
  }

  const confirm = e => {
    if (activeItem !== null) {
      e.preventDefault()
      set(options[activeItem])
    } else {
      cancel(e)
    }
  }

  const set = option => {
    input.onChange(typeof option === 'object' ? option.value : option)
    toggleOpened()
  }

  const getTextByValue = (value, options, label) => {
    if (!options) {
      return value
    }
    const currentOption = options.find(option => option.value === value || option === value)
    return currentOption ? currentOption.label || currentOption : label
  }

  const text = getTextByValue(input.value, options, label)

  return (
    <>
      <fieldset ref={fieldset}>
        <div className='avs-select avs-large' tabIndex='0' onKeyDown={onKeyDown}>
          <div className='avs-custom-select' onClick={toggleOpened}>
            <span
              className={classNames({
                'avs-selected-item': true,
                'avs-opened': isOpened,
                'avs-placeholder': !input.value && input.value !== false
              })}
            >
              <label name={input.name} className={className}>
                {label}
              </label>
              {text || label}
              {registrationType === 'insicPrecheck' && (
                <i className='avs-material-icons avs-input-icon'>{isOpened ? 'expand_less' : 'expand_more'}</i>
              )}
            </span>
            <hr />
          </div>

          {!useNative && (
            <div>
              {isOpened && (
                <ul className='avs-select-list'>
                  {options.map((option, i) => (
                    <li
                      key={i}
                      className={classNames({
                        'avs-select-item': true,
                        'avs-select-item-active': activeItem === i
                      })}
                      onClick={() => set(option)}
                    >
                      {option.label || option.toString()}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {useNative && (
            <select {...input}>
              {options.map(option => (
                <option key={option.value || option} value={option.value || option}>
                  {option.label || option}
                </option>
              ))}
            </select>
          )}
        </div>
      </fieldset>
      <div className='avs-errors'>{touched && error && <p className='avs-form-error-message'>{error}</p>}</div>
    </>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
        label: PropTypes.string
      })
    ])
  ).isRequired,
  useNative: PropTypes.bool,
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string
  }),
  className: PropTypes.string,
  handleUpdate: PropTypes.func
}

export default Select
